import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const departments = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),

  status: sample(['Active', 'Inactive']),


  departmentname: sample([
    'Operators',
    'Managers',
    'Operators',
    'Managers',

  ]),
  departmentdiscription: sample([
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
   
  ]),



}));

export default departments;
