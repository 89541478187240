import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import {
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  Grid,
  TableHead,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  Chip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import HomeIcon from '@mui/icons-material/Home';
import './Clientvehicles.css';
import { LoadingButton } from '@mui/lab';
import USERLIST from '../_mock/user';
import { useGetMineQuery, useGetDailyVehiclesReportQuery, useGetPdfReportMutation } from '../services/api';
import Iconify from '../components/iconify';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PdfReport() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const [minename, setMinename] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const { data: mine } = useGetMineQuery();
  const [getPdfReport] = useGetPdfReportMutation();
  const mineName = useSelector((state) => state.filter).mine;
  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    refetch: refetchVehicles,
  } = useGetDailyVehiclesReportQuery({ mine: mineName }, { refetchOnFocus: true });
  const { data: mines, isLoading: mineDataLoading, isSuccess: mineDataSuccess } = useGetMineQuery();
  const minePoints = mines?.data?.filter((obj) => obj.mineName === mineName)[0];
  console.log(mines?.data, 'mines');
  const filteredVehicles = vehicles?.data?.filter((vehicle) => {
    if (!vehicle.createdAt) {
      return false;
    }
    const createdAtDate = new Date(vehicle.createdAt);
    return (!from || createdAtDate >= new Date(from)) && (!to || createdAtDate <= new Date(to));
  });

  const tableHeaders = [
    'From Date Time',
    'To Date Time',
    'Time in',
    'Mine Name',
    'Vehicle Number',
    'Vehicle Type',
    'Vehicle Model',
  ];

  const convertToCsv = (data) => {
    const csv = Papa.unparse(data, {
      header: true,
      skipEmptyLines: true,
    });
    return csv;
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [headerColors, setHeaderColors] = useState([]);
  useEffect(() => {
    if (headerColors.length === 0) {
      const mainHeaderColors = tableHeaders.map(() => getRandomColor());
      setHeaderColors(mainHeaderColors);
    }
  }, [headerColors, tableHeaders]);

  const [loading, setLoading] = useState(false);

  const handleGeneratePdfReport = async () => {
    setLoading(true);
    try {
      const response = await getPdfReport({
        mineName: minename,
        selectedFields,
        from,
        to,
      }).unwrap();
  
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${minename ? `${minename}_` : ''}report.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      setLoading(false);
    } catch (error) {
      console.error('Error generating PDF report:', error);
      setLoading(false);
    }
  };
  
  

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/allVehicleReport?mineName=${minename}`, {
        credentials: 'include',
      });
      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader?.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.toString());
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
  };

  const handleDailyDownload = async (start, end, setLoading) => {
    setLoading(true);
    try {
      const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/allVehicleDailyReport?mineName=${minename}`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.toString());
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
  };

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const processDate = (date) => {
    const oldFormat = new Date(date);
    return oldFormat;
  };

  const handleDatedDownload = async (start, end, setLoading) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://backend.dgmining.in/api/v1/vehicles/allVehicleReport?startTime=${processDate(
          start
        )}&endTime=${processDate(end)}&mineName=${minename}`,
        { credentials: 'include' }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.toString());
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
  };

  const firstMinePoints = mines?.data[0]?.points || [];

  return (
    <>
      <Helmet>
        <title>Client Vehicles </title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Client Vehicles
        </h2>
      </div>

      <Container>
        <Box className="bg">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Client Vehicles
          </Typography>
        </Box>
        <br />
        <FormControl fullWidth style={{ marginBottom: '2%' }}>
          <InputLabel id="demo-simple-select-label">Select Mine</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={minename}
            label="Select Mine"
            onChange={(e) => setMinename(e.target.value)}
          >
            <MenuItem value="">All Mines</MenuItem>
            {mine?.data.map((_mine, index) => (
              <MenuItem key={index} value={_mine.mineName}>
                {_mine.mineName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: '2%' }}>
          <InputLabel id="demo-multiple-fields-label">Select Fields</InputLabel>
          <Select
            labelId="demo-multiple-fields-label"
            id="demo-multiple-fields"
            multiple
            value={selectedFields}
            onChange={(e) => setSelectedFields(e.target.value)}
            input={<OutlinedInput id="select-multiple-chip" label="Select Fields" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {firstMinePoints.map((point) =>
              point.fields
                .filter((field) => field.name !== 'Vehicle Data')
                .map((field, index) => (
                  <MenuItem key={index} value={field.name}>
                    {field.name} - <span style={{ fontWeight: 'bolder' }}>{point.title}</span>
                  </MenuItem>
                ))
            )}
          </Select>
        </FormControl>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          <input
            type="date"
            id="meeting-time"
            name="from"
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
          <input
            type="date"
            id="meeting-time"
            name="to"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />

          <LoadingButton
            onClick={handleGeneratePdfReport}
            loading={loading}
            variant="contained"
            startIcon={<Iconify icon="carbon:report" />}
          >
            Generate PDF Report
          </LoadingButton>

          <LoadingButton
            onClick={() => handleDailyDownload(start, end, setLoading)}
            loading={loading}
            variant="contained"
            startIcon={<Iconify icon="carbon:report" />}
          >
            Download 24H Report
          </LoadingButton>
        </Stack>
        {/* <HtmlTableVehicles /> */}
      </Container>
    </>
  );
}
