import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import PeopleIcon from '@mui/icons-material/People';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BlockIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography, Box, TextField, Stack,FormControl, InputLabel,Select,MenuItem } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// components
const Volumeform = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  return (
    <>
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <h2>Volume</h2>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
             

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                




<FormControl>
                  <InputLabel>Type of Vehicle
</InputLabel>
                <Select
    id="input-with-sx" 
    label="State"
    variant="outlined"
    style={{ width: '35ch' }}
  >
    <MenuItem value="option1">truck</MenuItem>
    <MenuItem value="option2">Dunper</MenuItem>
    <MenuItem value="option3">more</MenuItem>
  </Select>
  </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Maximum Volume "
                  variant="outlined"
                  style={{ width: '40ch' }}
                /> 
              </Box>

           
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Royalty Volume  "
                  variant="outlined"
                  style={{ width: '40ch' }}
                /> 
              </Box>
            
            
              
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
             
            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Minimum Volume  "
                  variant="outlined"
                  style={{ width: '40ch' }}
                /> 
              </Box>
           



              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Daban Volume " variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Highest Volume "
                  variant="outlined"
                  style={{ width: '40ch' }}
                /> 
              </Box> 
{/* 
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label=" Status  " variant="outlined" style={{ width: '40ch' }} />
              </Box> */}
             

          
            </Stack>
          </Grid>
        </Grid>
        <TextField id="input-with-sx" label=" Status  " variant="outlined" style={{ width: '96ch' }} />

        <Button variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
      </Typography>
    </>
  );
};

export default Volumeform;
