import React, { useEffect, useState } from 'react';

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
  Autocomplete,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveIcon from '@mui/icons-material/Save';
import Iconify from '../components/iconify';
import Error from '../components/error/Error';
import Success from '../components/success/Success';
import { useSavePointMutation } from '../services/api';

export default function Place() {
  const initState = {
    title: '',
    fields: [],
  };

  const [points, setPoints] = useState([initState]);
  const [isVehicleData, setIsVehicleData] = useState(false);

  const handleAddPoint = () => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints, initState];
      return allPoints;
    });
  };

  const handleDeletePoint = (pointIndex) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints.splice(pointIndex, 1);
      return allPoints;
    });
  };

  const handlePoint = (pointIndex, e) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex] = { ...allPoints[pointIndex], title: e.target.value };
      return allPoints;
    });
  };

  const handleRemark = (pointIndex, e) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex] = { ...allPoints[pointIndex], remark: e.target.value };
      return allPoints;
    });
  }
  const handleIsRequired = (pointIndex, e) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex] = { ...allPoints[pointIndex], isRequired: e.target.value };
      // Add additional logic based on the selection
      if (e.target.value === 'true') {
        // If selected "Yes", set additional property for discount point
        allPoints[pointIndex].isDiscountPointNaka = true; // Initialize as false by default
      } else {
        // If selected "No", remove additional property
        delete allPoints[pointIndex].isDiscountPointNaka;
      }
      return allPoints;
    });
  };

  const handleIsNaka = (pointIndex, e) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex] = { ...allPoints[pointIndex], isNaka: e.target.value };
      return allPoints;
    });
  };

  const handleAddField = (pointIndex) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex].fields = [...allPoints[pointIndex].fields, { name: '', type: '' }];
      return allPoints;
    });
  };

  const handleDeleteField = (pointIndex, fieldIndex) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex].fields.splice(fieldIndex, 1);
      return allPoints;
    });
  };

  const handleField = (pointIndex, fieldIndex, e, field) => {
    if (e.target.value === 'Vehicle Data') {
      setIsVehicleData({ show: 1, index: fieldIndex });
      setPoints((prevPoints) => {
        const allPoints = [...prevPoints];
        allPoints[pointIndex].fields[fieldIndex] = {
          ...allPoints[pointIndex].fields[fieldIndex],
          [field]: field === 'printable' || field === 'required' ? e.target.checked : e.target.value,
        };
        if (Object.prototype.hasOwnProperty.call(allPoints[pointIndex].fields[fieldIndex], 'options')) {
          delete allPoints[pointIndex].fields[fieldIndex].options;
        }
        return allPoints;
      });
      return;
    }
    if (e.target.value === 'CheckBox' || e.target.value === 'Dropdown') {
      setPoints((prevPoints) => {
        const allPoints = [...prevPoints];
        allPoints[pointIndex].fields[fieldIndex] = {
          ...allPoints[pointIndex].fields[fieldIndex],
          options: [''],
          [field]: field === 'printable' || field === 'required' ? e.target.checked : e.target.value,
        };
        return allPoints;
      });
    } else {
      setPoints((prevPoints) => {
        const allPoints = [...prevPoints];
        allPoints[pointIndex].fields[fieldIndex] = {
          ...allPoints[pointIndex].fields[fieldIndex],
          [field]: field === 'printable' || field === 'required' ? e.target.checked : e.target.value,
        };
        if (Object.prototype.hasOwnProperty.call(allPoints[pointIndex].fields[fieldIndex], 'options')) {
          delete allPoints[pointIndex].fields[fieldIndex].options;
        }
        return allPoints;
      });
    }
  };

  const handleAddOption = (pointIndex, fieldIndex) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex].fields[fieldIndex] = {
        ...allPoints[pointIndex].fields[fieldIndex],
        options: [...allPoints[pointIndex].fields[fieldIndex].options, ''],
      };
      return allPoints;
    });
  };

  const handleDeleteOption = (pointIndex, fieldIndex, optionIndex) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex].fields[fieldIndex].options.splice(optionIndex, 1);
      return allPoints;
    });
  };

  const handleOption = (pointIndex, fieldIndex, optionIndex, e) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      allPoints[pointIndex].fields[fieldIndex].options[optionIndex] = e.target.value;
      return allPoints;
    });
  };

  const [savePoints, { error, isSuccess, isLoading }] = useSavePointMutation();
  const navigate = useNavigate();

  const handleSavePoints = async () => {
    await savePoints(points);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/Points', { replace: true });
      }, 800);
    }
  }, [isSuccess]);
  const handleIsDiscountPoint = (pointIndex, e) => {
    setPoints((prevPoints) => {
      const allPoints = [...prevPoints];
      console.log(e.target.checked);
      allPoints[pointIndex] = { 
        ...allPoints[pointIndex], 
        isDiscountNakaPoint: e.target.checked  // Set to e.target.checked instead of false
      };
      return allPoints;
    });
  };
  // console.log(points);

  return (
    <>
      <div>
        {error && <Error error={error} />}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={'Saved successfully'} />}
        {points.map((point, pointIndex) => (
          <div key={pointIndex}>
            <FormGroup>
              {point.isNaka === 'true' && (
                <FormControlLabel
                  control={<Checkbox onChange={(e) => handleIsDiscountPoint(pointIndex, e)} />}
                  label="Is this a Discount Naka Point?"
                />
              )}
            </FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  value={point.title}
                  onChange={(e) => handlePoint(pointIndex, e)}
                  fullWidth
                  label={`${pointIndex + 1}. Point Name`}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={4} marginTop={-2.5}>
                <FormControl style={{ width: '100%', marginTop: '20px' }}>
                  <InputLabel>{'Is this a Naka Point?'}</InputLabel>
                  <Select
                    value={point?.isRequired}
                    onChange={(e) => [handleIsNaka(pointIndex, e), handleIsRequired(pointIndex, e)]}
                    id="input-with-sx"
                    label="Is Naka Point?"
                    variant="outlined"
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                  <TextField
                    value={point?.remark}
                    fullWidth
                    onChange={(e) => handleRemark(pointIndex, e)}
                    label={"Remark"}
                    type="text"
                  />
                </Grid>
              <Button
                type="button"
                style={{ marginTop: '25px', marginBottom: '20px', marginLeft: '2ch' }}
                variant="contained"
                onClick={() => handleDeletePoint(pointIndex)}
              >
                <DeleteIcon />
              </Button>
            </Grid>
            {point.fields.map((field, fieldIndex) => (
              <Grid key={fieldIndex} container spacing={2} marginTop={2}>
                <Grid item xs={4}>
                  <TextField
                    value={field.name}
                    fullWidth
                    onChange={(e) => handleField(pointIndex, fieldIndex, e, 'name')}
                    label={`${fieldIndex + 1}. Field Name`}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={4} marginTop={-2.5}>
                  <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <InputLabel>{'add type'}</InputLabel>
                    <Select
                      value={field.type}
                      onChange={(e) => handleField(pointIndex, fieldIndex, e, 'type')}
                      id="input-with-sx"
                      label="Add Type"
                      variant="outlined"
                    >
                      <MenuItem value="Vehicle Data">Vehicle Data</MenuItem>
                      <MenuItem value="Number">Number</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="InputBox">InputBox</MenuItem>
                      <MenuItem value="cft">CFT Calculator</MenuItem>
                      <MenuItem value="Other Vehicle Entry">Other Vehicle Entry(Naka Point)</MenuItem>
                      <MenuItem value="Naka Point Dropdown">Naka Point(dropdown)</MenuItem>
                      <MenuItem value="CheckBox">CheckBox</MenuItem>
                      <MenuItem value="Dropdown">Dropdown</MenuItem>
                      <MenuItem value="Discounted Vehicle Dropdown">Discounted Vehicle Dropdown</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <Button
                    type="button"
                    color="success"
                    style={{ marginTop: '25px', marginLeft: '2ch' }}
                    variant="contained"
                    onClick={() => handleDeleteField(pointIndex, fieldIndex)}
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
                <Grid container spacing={2} mt={2} ml={'2ch'}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => handleField(pointIndex, fieldIndex, e, 'required')} />}
                      label="Required"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => handleField(pointIndex, fieldIndex, e, 'printable')} />}
                      label="Printable"
                    />
                  </Grid>
                </Grid>

                {field.options &&
                  field.options.map((_option, optionIndex) => (
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={_option}
                        onChange={(e) => handleOption(pointIndex, fieldIndex, optionIndex, e)}
                        label={`${optionIndex + 1}. Option Name`}
                        type="text"
                      />
                      <Button
                        type="button"
                        color="warning"
                        style={{ marginTop: '25px', marginBottom: '20px', marginLeft: '2ch' }}
                        variant="contained"
                        onClick={() => handleDeleteOption(pointIndex, fieldIndex, optionIndex)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  ))}
                {field.options && (
                  <Button
                    color="warning"
                    style={{ marginTop: '25px', marginBottom: '15vh', marginLeft: '2ch' }}
                    onClick={() => handleAddOption(pointIndex, fieldIndex)}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Add Option
                  </Button>
                )}
              </Grid>
            ))}

            <Button
              color="success"
              onClick={() => handleAddField(pointIndex)}
              variant="contained"
              style={{ marginTop: '3ch', marginBottom: '2ch' }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Field
            </Button>
            {/* <Button ></Button> */}
          </div>
        ))}
        <Button
          onClick={handleAddPoint}
          variant="contained"
          style={{ marginTop: '3ch' }}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add New Point
        </Button>
      </div>
      {points.length >= 1 && points[0].title.length >= 1 && (
        <Button onClick={handleSavePoints} variant="contained" style={{ marginTop: '3ch' }} endIcon={<SaveIcon />}>
          Save
        </Button>
      )}
    </>
  );
}
