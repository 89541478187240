import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import SaveIcon from '@mui/icons-material/Save';
import { Outlet, Link } from 'react-router-dom';
import './Form1.css';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  FormControl,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

// components
import { styled } from '@mui/material/styles';

import './Forms.css';
import InputLabel from '@mui/material/InputLabel';
// import { useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Modal from '@mui/material/Modal';
// import * as React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Select from '@mui/material/Select';
import Iconify from '../components/iconify';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
import TablePage from './TablePage';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'name', label: 'Vendor Name', alignRight: false },

  { id: 'company', label: 'Mobile', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
];

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };

  // const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>Dumpyard</title>
      </Helmet>

      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Dumpyard
        </h2>
      </div>
      <Container>
        <Box className="bg">
          <Helmet>
            <title> Dumpyard </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dumpyard
            {/* <div  style={{fontSize:'10px'}}> <HomeIcon color="primary" /> Dashboard/vendorPage</div> */}
          </Typography>

          {/* <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
            <Grid xs={12} xsOffset={0} md={0} mdOffset={4}>
              <Item style={{ textAlign: 'left', border: '0px solid  grey', color: 'black' }}>Dumpyard Summary</Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={4} mdOffset={0}>
              <Item className="vendor1">
                <Box>0</Box>
                <br />
                <Box>Total Dumpyard</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={4} mdOffset="auto">
              <Item className="vendor2">
                <Box>3</Box>
                <br />

                <Box>Active Dumpyard</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={4} mdOffset={0}>
              <Item className="vendor3">
                <Box>3</Box>
                <br />
                <Box>Inactive Dumpyard</Box>
              </Item>
            </Grid>
           
          </Grid> */}
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            {/* <Link to="/dashboard/mines" style={{ color: 'white', textDecoration: 'none' }}> */}
            Add Dumpyard
            {/* </Link> */}
          </Button>
          {/* <Button ></Button> */}
        </Stack>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            {/* <Item> */}
            <div className="boxx">
              <div className="box-top boxx-main-top" style={{ padding: '2vmax 1vmax' }}>
                <h2 style={{ fontWeight: '400' }}>MAIN STOCK</h2>
                <Iconify icon={'cil:building'} style={{ width: '35px', height: '28px' }} />
              </div>
              <div className="box-bottom box-bottom-bottom" style={{ padding: '0 1vmax 2.5vmax 1vmax' }}>
                <div>
                  <button>STOCK CHECK</button>
                  <Iconify icon={'game-icons:mine-truck'} style={{ width: '28px', height: '28px', color: '#62D493' }} />
                </div>
              </div>
            </div>
            {/* </Item> */}
          </Grid>
          <Grid item xs={3}>
            {/* <Item> */}
            <div className="boxx">
              <div className="box-top">
                <h3>Dumpyard/Site 1 </h3>
                <Iconify icon={'cil:building'} style={{ width: '35px', height: '28px' }} />
              </div>
              <div className="box-bottom">
                <div>
                  <button>STOCK CHECK</button>
                  <Iconify icon={'game-icons:mine-truck'} style={{ width: '28px', height: '28px' }} />
                </div>
              </div>
            </div>
            {/* </Item> */}
          </Grid>
          <Grid item xs={3}>
            {/* <Item> */}
            <div className="boxx">
              <div className="box-top">
                <h3>Dumpyard/Site 2</h3>
                <Iconify icon={'cil:building'} style={{ width: '35px', height: '28px' }} />
              </div>
              <div className="box-bottom">
                <div>
                  <button>STOCK CHECK</button>
                  <Iconify icon={'game-icons:mine-truck'} style={{ width: '28px', height: '28px' }} />
                </div>
              </div>
            </div>
            {/* </Item> */}
          </Grid>
          <Grid item xs={3}>
            {/* <Item> */}
            <div className="boxx">
              <div className="box-top">
                <h3>Dumpyard/Site 3</h3>
                <Iconify icon={'cil:building'} style={{ width: '35px', height: '28px' }} />
              </div>
              <div className="box-bottom">
                <div>
                  <button>STOCK CHECK</button>
                  <Iconify icon={'game-icons:mine-truck'} style={{ width: '28px', height: '28px' }} />
                </div>
              </div>
            </div>
            {/* </Item> */}
          </Grid>
        </Grid>
        <TablePage />
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Dump Openings Stock
          </Typography>

          <Typography
            id="keep-mounted-modal-description"
            sx={{ mt: 2 }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label=" Site Name"
                      variant="outlined"
                      style={{ width: '40ch' }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label="Manager Name"
                      variant="outlined"
                      style={{ width: '40ch' }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    {/* <TextField id="input-with-sx"  required label="State"  variant="outlined" style={{width:'40ch'}}  /> */}
                    <FormControl fullWidth>
                      <InputLabel id="age-label">State</InputLabel>
                      <Select
                        labelId="age-label"
                        id="age-select"
                        // value={Equipment}
                        label="State"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Madhya Pradesh</MenuItem>
                        <MenuItem value={20}>Uttar Pradesh</MenuItem>
                        <MenuItem value={30}>Rajasthan</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField id="input-with-sx" label="Area" variant="outlined" style={{ width: '40ch' }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField id="input-with-sx" label="address" variant="outlined" style={{ width: '40ch' }} />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    {/* <TextField readonly label="  Site Images"  variant="outlined" style={{ width: '10ch' }} /> */}
                    {/* <InputLabel id="demo-simple-select-label"  variant="outlined"  style={{width:'10ch'}} >Site Images</InputLabel> */}

                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                      Upload Image
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField id="input-with-sx" label="Mobile" variant="outlined" style={{ width: '40ch' }} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <FormControl fullWidth>
                      <InputLabel id="age-label">City</InputLabel>
                      <Select
                        labelId="age-label"
                        id="age-select"
                        // value={Equipment}
                        label="City"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Bhopal</MenuItem>
                        <MenuItem value={20}>Indore</MenuItem>
                        <MenuItem value={30}>Jabalpur</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField
                      id="input-with-sx"
                      type="number"
                      label="Pin Code"
                      variant="outlined"
                      style={{ width: '40ch' }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField id="input-with-sx" label="Locations" variant="outlined" style={{ width: '40ch' }} />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Button variant="contained" endIcon={<SaveIcon />}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
