import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://backend.dgmining.in/api/v1/' }),
  tagTypes: [
    'State',
    'District',
    'City',
    'Area',
    'Mine',
    'MineType',
    'Department',
    'Designation',
    'Vehicle',
    'EmployeeRole',
    'Point',
    'Employee',
    'Admin',
    'SandType',
    'VehicleType',
    'DiscountNakaPoint',
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (creds) => ({
        url: `/auth/login`,
        method: 'post',
        body: creds,
        credentials: 'include',
      }),
      invalidatesTags: [
        'State',
        'District',
        'City',
        'Area',
        'Mine',
        'MineType',
        'Department',
        'Vehicle',
        'Designation',
        'EmployeeRole',
        'Point',
        'Employee',
        'Admin',
        'SandType',
      ],
    }),
    logout: builder.query({
      query: () => ({
        url: `/auth/logout`,
        method: 'post',
        credentials: 'include',
      }),
    }),
    saveUser: builder.mutation({
      query: (data) => ({
        url: `/auth/registeradmin`,
        method: 'post',
        body: data,
      }),
    }),
    saveState: builder.mutation({
      query: (data) => ({
        url: `/admin/addstate`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['State'],
    }),
    getStates: builder.query({
      query: () => ({
        url: `/admin/allstates`,
        credentials: 'include',
      }),
      providesTags: ['State'],
    }),
    saveDistrict: builder.mutation({
      query: (data) => ({
        url: `/admin/adddistrict`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['District'],
    }),
    getDistricts: builder.query({
      query: () => ({
        url: `/admin/alldistricts`,
        credentials: 'include',
      }),
      providesTags: ['District'],
    }),
    saveCity: builder.mutation({
      query: (data) => ({
        url: `/admin/addcity`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['City'],
    }),
    getCities: builder.query({
      query: () => ({
        url: `/admin/allcities`,
        credentials: 'include',
      }),
      providesTags: ['City'],
    }),
    saveArea: builder.mutation({
      query: (data) => ({
        url: `/admin/addarea`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['Area'],
    }),
    getAreas: builder.query({
      query: () => ({
        url: `/admin/allareas`,
        credentials: 'include',
      }),
      providesTags: ['Area'],
    }),
    saveMineType: builder.mutation({
      query: (data) => ({
        url: `/mine/addminetype`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['MineType'],
    }),
    getMineType: builder.query({
      query: () => ({
        url: `/mine/allminetypes`,
        credentials: 'include',
      }),
      providesTags: ['MineType'],
    }),
    saveMine: builder.mutation({
      query: (data) => ({
        url: `/mine/addmine`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['Mine'],
    }),
    getMine: builder.query({
      query: () => ({
        url: `/mine/allmines`,
        credentials: 'include',
      }),
      providesTags: ['Mine'],
    }),
    saveDepartment: builder.mutation({
      query: (data) => ({
        url: `/admin/adddepartment`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['Department'],
    }),
    getDepartment: builder.query({
      query: () => ({
        url: `/admin/alldepartments`,
        credentials: 'include',
      }),
      providesTags: ['Department'],
    }),
    saveDesignation: builder.mutation({
      query: (data) => ({
        url: `/admin/adddesignation`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['Designation'],
    }),
    getDesignation: builder.query({
      query: () => ({
        url: `/admin/alldesignations`,
        credentials: 'include',
      }),
      providesTags: ['Designation'],
    }),
    saveEmployeeRole: builder.mutation({
      query: (data) => ({
        url: `/admin/addemployeerole`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['EmployeeRole'],
    }),
    getEmployeeRole: builder.query({
      query: () => ({
        url: `/admin/allemployeeroles`,
        credentials: 'include',
      }),
      providesTags: ['EmployeeRole'],
    }),
    savePoint: builder.mutation({
      query: (data) => ({
        url: `/admin/addpoint`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
    }),
    getPoints: builder.query({
      query: () => ({
        url: `/admin/allpoints`,
        credentials: 'include',
      }),
      providesTags: ['Point'],
    }),
    saveEmployeeData: builder.mutation({
      query: (data) => ({
        url: `/admin/registeremployee`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['Employee'],
    }),
    getEmployeeData: builder.query({
      query: () => ({
        url: `/admin/getEmployees`,
        credentials: 'include',
      }),
      providesTags: ['Employee'],
    }),
    updateEmployeeData: builder.mutation({
      query: ({ id, dataToUpdate }) => ({
        url: `/admin/updateEmployee?id=${id}`,
        method: 'put',
        body: dataToUpdate,
        credentials: 'include',
      }),
      invalidatesTags: ['Employee'],
    }),
    updateMineData: builder.mutation({
      query: ({ mineId, dataToUpdate }) => ({
        url: `/mine/updatemine?id=${mineId}`,
        method: 'put',
        body: dataToUpdate,
        credentials: 'include',
        }),
        invalidatesTags: ['Mine'],
    }),
    getAdmins: builder.query({
      query: () => ({
        url: `/admin/alladmins`,
        credentials: 'include',
      }),
      providesTags: ['Admin'],
    }),
    saveSandType: builder.mutation({
      query: (data) => ({
        url: `/mine/addsandtype`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['SandType'],
    }),
    getSandType: builder.query({
      query: () => ({
        url: '/mine/allsandtypes',
        credentials: 'include',
      }),
      providesTags: ['SandType'],
    }),
    SaveRates: builder.mutation({
      query: (data) => ({
        url: `/admin/addrate`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['Rates'],
    }),
    getRoleList: builder.query({
      query: () => ({
        url: `/admin/rolelist`,
        credentials: 'include',
      }),
    }),
    getRates: builder.query({
      query: () => ({
        url: `/admin/getRates`,
        credentials: 'include',
      }),
      providesTags: ['Rates'],
    }),
    saveVehicle: builder.mutation({
      query: (data) => ({
        url: `/mine/addvehicle`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
    }),
    getVehicles: builder.query({
      query: (data) => ({
        url: `/mine/allvehicles?page=${data.page}&limit=${data.limit}`,
        credentials: 'include',
      }),
    }),
    getVehiclesReport: builder.query({
      query: ({ mine }) => ({
        url: `/mine/allvehiclesReport?mine=${mine}`,
        credentials: 'include',
      }),
    }),
    getDailyVehiclesReport: builder.query({
      query: ({ mine }) => ({
        url: `/mine/vehiclesToday?mine=${mine}`,
        credentials: 'include',
      }),
    }),
    updateVehicleData: builder.mutation({
      query: ({ id, dataToUpdate }) => ({
        url: `/mine/updatevehicle?id=${id}`,
        method: 'put',
        body: dataToUpdate,
        credentials: 'include',
      }),
    }),
    getVehicle: builder.query({
      query: ({ id }) => ({
        url: `/mine/vehicle?id=${id}`,
        credentials: 'include',
      }),
    }),

    saveVehicleTypes: builder.mutation({
      query: (data) => ({
        url: `/admin/addvehicletype`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['VehicleType'],
    }),
    getVehicleTypes: builder.query({
      query: () => ({
        url: `/admin/allvehicletypes`,
        credentials: 'include',
      }),
      providesTags: ['VehicleType'],
    }),
    saveAttendance: builder.mutation({
      query: (data) => ({
        url: `/admin/addAttendance`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
      providesTags: ['Attendance'],
    }),
    saveOtherVehicleData: builder.mutation({
      query: (data) => ({
        url: `/mine/addothervehicle`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
    }),
    getOtherVehicleAllReport: builder.query({
      query: () => ({
        url: `/mine/allOtherVehiclesReport`,
        credentials: 'include',
      }),
    }),
    getOtherVehiclesDailyReport: builder.query({
      query: () => ({
        url: `/mine/OtherVehiclesToday`,
        credentials: 'include',
      }),
    }),
    savevehicleMasterData: builder.mutation({
      query: (data) => ({
        url: `/vehicles/addvehicleMasterData`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
    }),
    getVehiclesMasterData: builder.query({
      query: ({ page, limit }) => ({
        url: `/vehicles/allvehicleMasterData?page=${page}&limit=${limit}`,
        credentials: 'include',
      }),
    }),    
    saveDiscountedVehicle: builder.mutation({
      query: (requestData) => ({
        url: `/vehicles/discountedVehicle`,
        method: 'post',
        body: requestData,
        credentials: 'include',
      }),
    }),
    getDiscountedVehicleReport: builder.query({
      query: () => ({
        url: `/vehicles/discountedVehicleReport`,
        credentials: 'include',
      }),
    }),
    getDiscountedVehicleDaily: builder.query({
      query: ({ mineName }) => ({
        url: `/vehicles/discountedVehicleDaily?mineName=${mineName}`,
        credentials: 'include',
      }),
    }),
    addDiscountNakaPoint: builder.mutation({
      query: (data) => ({
        url: `/vehicles/addDiscountPoint`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
    }),
    getDiscountNakaPoint: builder.query({
      query: () => ({
        url: `/vehicles/allDiscountPoint`,
        credentials: 'include',
      }),
    }),
    deleteVehicleType: builder.mutation({
      query: (id) => ({
        url: `/admin/deletevehicletype/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['VehicleType'],
    }),
    editVehicleType: builder.mutation({
      query: ({ id, vehicleType, description }) => ({
        url: `/admin/updatevehicletype/${id}`,
        method: 'put',
        body: { vehicleType, description }, // Format the data correctly
        credentials: 'include',
      }),
      invalidatesTags: ['VehicleType'],
    }),
    deleteDepartment: builder.mutation({
      query: (id) => ({
        url: `/admin/deletedepartment/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['Department'],
    }),
    getOtherDistrictVehicleDaily: builder.query({
      query: ({ mineName }) => ({
        url: `/vehicles/otherDistrictVehicleDaily?mineName=${mineName}`,
        credentials: 'include',
      }),
    }),
    deleteState: builder.mutation({
      query: (id) => ({
        url: `/admin/deletestate/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['State'],
    }),
    deleteDistrict: builder.mutation({
      query: (id) => ({
        url: `/admin/deletedistrict/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['District'],
    }),
    deleteCity: builder.mutation({
      query: (id) => ({
        url: `/admin/deletecity/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['City'],
    }),
    deleteArea: builder.mutation({
      query: (id) => ({
        url: `/admin/deletearea/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['Area'],
    }),
    deletePoint: builder.mutation({
      query: (id) => ({
        url: `/admin/deletePoint/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['Point'],
    }),
    deleteEmployeeRole: builder.mutation({
      query: (id) => ({
        url: `/admin/deleteEmployeeRole/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['EmployeeRole'],
    }),
    deleteDesignation: builder.mutation({
      query: (id) => ({
        url: `/admin/deleteDesignation/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['Designation'],
    }),
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `/admin/deleteEmployee/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['Employee'],
    }),
    deleteMine: builder.mutation({
      query: (id) => ({
        url: `/mine/deleteMine/${id}`,
        method: 'delete',
        credentials: 'include',
      }),
      invalidatesTags: ['Mine'],
    }),
    editDepartment: builder.mutation({
      query: ({ id, parentDepartment, departmentName, description }) => ({
        url: `/admin/updatedepartment/?id=${id}`,
        method: 'put',
        body: { parentDepartment, departmentName, description },
        credentials: 'include',
      }),
      invalidatesTags: ['Department'],
    }),
    editState: builder.mutation({
      query: ({ id, stateName }) => ({
        url: `/admin/updatestate/?id=${id}`,
        method: 'put',
        body: { id, stateName },
        credentials: 'include',
      }),
      invalidatesTags: ['State'],
    }),
    editDistrict: builder.mutation({
      query: ({ id, districtName }) => ({
        url: `/admin/updatedistrict/?id=${id}`,
        method: 'put',
        body: {id, districtName },
        credentials: 'include',
      }),
      invalidatesTags: ['District'],
    }),
    editCity: builder.mutation({
      query: ({ id, cityName }) => ({
        url: `/admin/updatecity/?id=${id}`,
        method: 'put',
        body: { id, cityName },
        credentials: 'include',
      }),
      invalidatesTags: ['City'],
    }),
    editArea: builder.mutation({
      query: ({ id, area, pincode}) => ({
        url: `/admin/updatearea/?id=${id}`,
        method: 'put',
        body: { id, area, pincode },
        credentials: 'include',
      }),
      invalidatesTags: ['Area'],
    }),
    editAdmin: builder.mutation({
      query: ({ id, name, password, email, phone, companyName, stateName, districtName, cityName, profileName, status }) => ({
        url: `/admin/updateAdminInfo/?id=${id}`,
        method: 'put',
        body: { id, name, password, email, phone, companyName, stateName, districtName, cityName, profileName, status },
        credentials: 'include',
      }),
      invalidatesTags: ['Admin'],
    }),
    editEmployeeRole: builder.mutation({
      query: ({ id, roleName, description, roleList }) => ({
        url: `/admin/updateEmployeeRole/?id=${id}`,
        method: 'put',
        body: { id, roleName, description, roleList },
        credentials: 'include',
      }),
      invalidatesTags: ['EmployeeRole'],
    }),
    editDesignation: builder.mutation({
      query: ({ id,departmentName, description, designationName, parent, managementLevel  }) => ({
        url: `/admin/updateDesignation/?id=${id}`,
        method: 'put',
        body: { id,departmentName, description, designationName, parent, managementLevel },
        credentials: 'include',
      }),
      invalidatesTags: ['Designation'],
    }),
    getMineById : builder.mutation({
      query: (id) => ({
        url: `/mine/mineById?id=${id}`,
        method: 'post',
        credentials: 'include',
      }),
    }),
    updateVehicleMasterData: builder.mutation({
      query: ({ id, dataToUpdate }) => ({
        url: `/vehicles/updateVehicleMasterData?id=${id}`,
        method: 'put',
        body: dataToUpdate,
        credentials: 'include',
      }),
    }),
    getVehicleMasterDataById: builder.query({
      query: ({ id }) => ({
        url: `/vehicles/vehicleMasterDataById?id=${id}`,
        credentials: 'include',
      }),
    }),
    getPdfReport: builder.mutation({
      query: (data) => ({
        url: `/pdf/allVehiclesPdfReport`,
        method: 'post',
        body: data,
        credentials: 'include',
      }),
    }),
    getEmployeeActivityReportPDF: builder.query({
      query: () => ({
        url: `/report/employeeActivityReport/pdf`,
        method: 'get',
        credentials: 'include',
      }),
    }),
    getEmployeeActivityReportExcel: builder.query({
      query: () => ({
        url: `/report/employeeActivityReport/excel`,
        method: 'get',
        credentials: 'include',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useSaveUserMutation,
  useSaveStateMutation,
  useGetStatesQuery,
  useSaveDistrictMutation,
  useGetDistrictsQuery,
  useSaveCityMutation,
  useGetCitiesQuery,
  useSaveAreaMutation,
  useGetAreasQuery,
  useSaveMineTypeMutation,
  useGetMineTypeQuery,
  useUpdateMineDataMutation,
  useSaveMineMutation,
  useGetMineQuery,
  useSaveDepartmentMutation,
  useGetDepartmentQuery,
  useSaveDesignationMutation,
  useGetDesignationQuery,
  useSaveEmployeeRoleMutation,
  useGetEmployeeRoleQuery,
  useSavePointMutation,
  useGetPointsQuery,
  useSaveEmployeeDataMutation,
  useGetEmployeeDataQuery,
  useUpdateEmployeeDataMutation,
  useGetAdminsQuery,
  useSaveSandTypeMutation,
  useGetSandTypeQuery,
  useSaveRatesMutation,
  useGetRoleListQuery,
  useGetRatesQuery,
  useGetVehiclesQuery,
  useSaveVehicleMutation,
  useUpdateVehicleDataMutation,
  useLazyGetVehicleQuery,
  useSaveVehicleTypesMutation,
  useGetVehicleTypesQuery,
  useSaveAttendanceMutation,
  useGetVehiclesReportQuery,
  useGetDailyVehiclesReportQuery,
  useSaveOtherVehicleDataMutation,
  useGetOtherVehicleAllReportQuery,
  useGetOtherVehiclesDailyReportQuery,
  useSavevehicleMasterDataMutation,
  useGetVehiclesMasterDataQuery,
  useSaveDiscountedVehicleMutation,
  useLazyGetDiscountedVehicleReportQuery,
  useGetDiscountedVehicleDailyQuery,
  useAddDiscountNakaPointMutation,
  useGetDiscountNakaPointQuery,
  useDeleteVehicleTypeMutation,
  useEditVehicleTypeMutation,
  useDeleteDepartmentMutation,
  useGetOtherDistrictVehicleDailyQuery,
  useDeleteStateMutation,
  useDeleteDistrictMutation,
  useDeleteCityMutation,
  useDeleteAreaMutation,
  useDeletePointMutation,
  useDeleteEmployeeRoleMutation,
  useDeleteDesignationMutation,
  useDeleteEmployeeMutation,
  useDeleteMineMutation,
  useEditDepartmentMutation,
  useEditStateMutation,
  useEditDistrictMutation,
  useEditCityMutation,
  useEditAreaMutation,
  useEditAdminMutation,
  useEditEmployeeRoleMutation,
  useEditDesignationMutation,
  useGetMineByIdMutation,
  useUpdateVehicleMasterDataMutation,
  useGetVehicleMasterDataByIdQuery,
  useGetPdfReportMutation,
  useGetEmployeeActivityReportPDFQuery,
  useGetEmployeeActivityReportExcelQuery,
} = apiService;
