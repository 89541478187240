import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Collapse, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import '../../pages/Forms.css';
import navConfig from '../../layouts/dashboard/nav/config';

const NavItem = ({ item }) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={item.path || '#'}
        onClick={item.submenus ? handleToggle : null}
        sx={{
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        {item.icon && <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>}
        <ListItemText disableTypography primary={item.title} />
        {item.submenus && (
          <IconButton edge="end" size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      </StyledNavItem>

      {item.submenus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.submenus.map((subItem, index) => (
              <NavItem key={index} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function NavSection({ data = [], ...other }) {
  const [menu, setMenu] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let updatedMenu;
    if (user?.data?.role === 'superAdmin') {
      updatedMenu = navConfig;
    } else if (user?.data?.role === 'admin') {
      updatedMenu = navConfig.filter(navItem => navItem.title !== 'Super Admin Masters');
    } else {
      updatedMenu = data;
    }
    setMenu(updatedMenu);
  }, []);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {menu.length > 0 && menu.map((item, index) => (
          <NavItem key={index} item={item} />
        ))}
      </List>
    </Box>
  );
}
