import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import './Forms.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@mui/material';
import { values } from 'lodash';

// components
const Ajay = () => {
  return (
    <Box m={20}>
      <img src="/assets/new img/Logo.png" alt="" />
    </Box>
  );
};

export default Ajay;
