import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TablePagination,
  Box,
  Grid,
  TextField,
  Button,
  Checkbox,
  TableContainer,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLISTDEPART from '../_mock/department';
import {
  useGetVehicleTypesQuery,
  useSaveVehicleTypesMutation,
  useDeleteVehicleTypeMutation,
  useEditVehicleTypeMutation,
} from '../services/api';
import Error from '../components/error/Error';
import Success from '../components/success/Success';

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));

const TABLE_HEADDEPRT = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Vehicle Type', label: 'Vehicle Type', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.vehicleType.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function VehicleType() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('vehicleType');
  const [filterName, setFilterName] = useState('');
  const [editOpen, setEditOpen] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [id, setId] = useState(null);
  const [editVehicleTypeOpen, setEditVehicleTypeOpen] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { data: vehicletypes } = useGetVehicleTypesQuery();
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [editedVehicleType, setEditedVehicleType] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [vehicletype, setVehicleType] = useState(null);
  const [desc, setDesc] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [saveVehicleType, { isSuccess, isLoading, error }] = useSaveVehicleTypesMutation();
  const [deleteVehicleType, { isSuccess: isDeleteSuccess, isError: isDeleteError, reset }] =
    useDeleteVehicleTypeMutation();
  const [editVehicleType] = useEditVehicleTypeMutation();

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditOpen(null);
  };

  const handleEditVehicleTypeClose = () => {
    setEditVehicleTypeOpen(null);
  };

  const handleEditVehicleTypeOpen = (id) => {
    const selectedType = vehicletypes?.data?.find((type) => type._id === id);
    setSelectedVehicleType(selectedType);
    setEditedVehicleType(selectedType.vehicleType || '');
    setEditedDescription(selectedType.description || '');
    setId(id);
    setEditVehicleTypeOpen(true);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vehicletypes?.data?.map((n) => n.vehicleType);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vehicletypes?.data?.length) : 0;

  const filteredUsers = applySortFilter(vehicletypes?.data || [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleVehicleTypeSave = async () => {
    await saveVehicleType({ vehicleType: vehicletype, description: desc });
    closeModal();
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteVehicleType(id).unwrap();
      handleClose();
    } catch (error) {
      console.log('Failed To Delete:', error);
    }
  };

  const handleEditVehicleType = async (id) => {
    await editVehicleType({ id, vehicleType: editedVehicleType, description: editedDescription });
    handleEditVehicleTypeClose();
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('Deleted successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // Clear success message after 5 seconds
    }

    if (isDeleteError) {
      setErrorMessage('Failed to delete. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000); // Clear error message after 5 seconds
    }

    // Reset mutation status to handle subsequent operations
    return () => reset();
  }, [isDeleteSuccess, isDeleteError, reset]);

  return (
    <>
      <Helmet>
        <title>Vehicle Type</title>
      </Helmet>
      {successMessage && <Success successMessage={successMessage} />}
      {errorMessage && <Error error={errorMessage} />}
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editVehicleTypeOpen}
        onClose={handleEditVehicleTypeClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleEditVehicleTypeClose} style={{ cursor: 'pointer' }} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit Vehicle Type
          </Typography>

          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={editedVehicleType}
                  onChange={(e) => setEditedVehicleType(e.target.value)}
                  label="Vehicle Type"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  label="Description"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button onClick={() => handleEditVehicleType(id)} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Masters
          </Link>{' '}
          / Vehicle Type
        </h2>
      </div>
      <Container>
        {error && <Error error={error} />}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={'Saved successfully'} />}
        <Box className="bg">
          <Helmet>
            <title> Vehicle Type </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            Vehicle Type
          </Typography>
        </Box>
        <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
          <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
            <Item className="vendor1">
              <Box>{vehicletypes?.data?.length}</Box>
              <br />
              <Box>Total Vehicle Types</Box>
            </Item>
          </Grid>
          <Grid xs={6} md={3} mdOffset="auto">
            <Item className="vendor2">
              <Box>{vehicletypes?.data?.length}</Box>
              <br />
              <Box>Active Vehicle Types</Box>
            </Item>
          </Grid>
          <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
            <Item className="vendor3">
              <Box>{vehicletypes?.data?.length}</Box>
              <br />
              <Box>Inactive Vehicle Types</Box>
            </Item>
          </Grid>
          <Grid xs={6} md={3} mdOffset={0}>
            <Item className="vendor4">
              <Box>3</Box>
              <br />
              <Box>Today Vehicle Types</Box>
            </Item>
          </Grid>
        </Grid>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          <Button onClick={openModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Vehicle Type
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEADDEPRT}
                  rowCount={vehicletypes?.data?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { _id, vehicleType, description } = row;
                    const selectedUser = selected.indexOf(vehicleType) !== -1;
                    const rowNum = page * rowsPerPage + index + 1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, vehicleType)} />
                        </TableCell>
                        <TableCell>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Button variant="contained" onClick={() => handleEditVehicleTypeOpen(_id)}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
                                Edit
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <MenuItem onClick={() => handleOpenMenu(_id)} sx={{ flexGrow: 1, color: 'error.main' }}>
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                                Delete
                              </MenuItem>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="left">{vehicleType}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={vehicletypes?.data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={closeModal} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Add Vehicle Type
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setVehicleType(e.target.value)}
                  label="Vehicle Type"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField onChange={(e) => setDesc(e.target.value)} label="Description" variant="outlined" fullWidth />
              </Grid>
            </Grid>
            <Button onClick={handleVehicleTypeSave} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
