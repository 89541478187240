import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import '../Form1.css';
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  Grid
} from '@mui/material';
// components
import HomeIcon from '@mui/icons-material/Home';
import {styled} from '@mui/system';
import '../Forms.css';
import Iconify from '../../components/iconify';
import TablePage from './Tablepagerole';
import Error from '../../components/error/Error';
import Success from '../../components/success/Success';
import { useUpdateEmployeeDataMutation } from '../../services/api';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));

export default function UserPage() {
  const [updateEmployeeData, {error, isLoading, isSuccess}] = useUpdateEmployeeDataMutation();
  return (
    <>
      <Helmet>
        <title>All Employees</title>
      </Helmet>
      <div style={{fontSize:'10px',display:"flex",alignItems:"center", gap:".5vmax",}}> 
        <HomeIcon color="primary" /> 
        <h2><Link to="/dashboard" style={{textDecoration:"none", color:"black"}}>Dashboard</Link> / HR Department / All Employees</h2>
      </div>

      <Container>
        {error && <Error error={error}/>}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={"Save successfully"}/>}
        <Box className="bg">
          <Typography variant="h4" sx={{ mb: 5 }}>
            All Employees
          </Typography>

          <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
            <Grid item xs={6} md={3}>
              <Item className="vendor1">
                <Box>0</Box>
                <br />
                <Box>Total Employees</Box>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item className="vendor2">
                <Box>3</Box>
                <br />
                <Box>Active Employees</Box>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item className="vendor3">
                <Box>3</Box>
                <br />
                <Box>Inactive Employees</Box>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item className="vendor4">
                <Box>3</Box>
                <br />
                <Box>Today Employees</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            <Link to="/dashboard/employeefrom" style={{ color: 'white', textDecoration: 'none' }}>
              Add Employees
            </Link>
          </Button>
        </Stack>
        <TablePage />
      </Container>
    </>
  );
}
