import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Icon } from '@iconify/react';
// components
import { styled } from '@mui/material/styles';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLISTDEPART from '../_mock/department';
// import VendorPage from '../VendorPage';
import Label from '../components/label';
import { useGetDiscountNakaPointQuery, useAddDiscountNakaPointMutation } from '../services/api';
import Error from '../components/error/Error';
import Success from '../components/success/Success';

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

const TABLE_HEADDEPRT = [
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Discount Naka Point', label: 'Discount Naka Point', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
];

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DiscountNakaPoint() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [editOpen, setEditOpen] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [id, setId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { data: discountNakas } = useGetDiscountNakaPointQuery();

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
    console.log(id);
  };
  const handleClose = () => {
    setEditOpen(null);
  };
  // modal state

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
    console.log('Form data submitted:', data);
  };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLISTDEPART.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLISTDEPART.length) : 0;

  const filteredUsers = applySortFilter(USERLISTDEPART, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [saveDiscountPoint, { isSuccess, isLoading, error }] = useAddDiscountNakaPointMutation();

  const [discountPoint, setDiscountPoint] = useState(null);
  const [remrk, setRemrk] = useState(null);

  const handleDiscountPointSave = async () => {
    await saveDiscountPoint({ discountNakaPointName: discountPoint, remark: remrk });
    closeModal();
  };

  return (
    <>
      <Helmet>
        <title>Discount Naka Point</title>
      </Helmet>
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Are you sure you want to delete the Entry?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="contained" style={{ backgroundColor: 'red' }}>
              CANCEL
            </Button>
            <Button variant="contained">Yes</Button>
          </div>
        </Box>
      </Modal>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Masters
          </Link>{' '}
          / Discount Naka Point
        </h2>
      </div>
      <Container>
        {error && <Error error={error} />}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={'Save successfully'} />}
        <Box className="bg">
          <Helmet>
            <title> Discount Naka Point </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            Discount Naka Point
          </Typography>
        </Box>
        <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
          <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
            <Item className="vendor1">
              <Box>{discountNakas?.data?.length}</Box>
              <br />
              <Box>Total Discount Naka Points</Box>
            </Item>
          </Grid>
          <Grid xs={6} md={3} mdOffset="auto">
            <Item className="vendor2">
              <Box>{discountNakas?.data?.length}</Box>
              <br />

              <Box>Active Discount Naka Points</Box>
            </Item>
          </Grid>
          <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
            <Item className="vendor3">
              <Box>{discountNakas?.data?.length}</Box>
              <br />
              <Box>Inactive Discount Naka Points</Box>
            </Item>
          </Grid>
          <Grid xs={6} md={3} mdOffset={0}>
            <Item className="vendor4">
              <Box>3</Box>
              <br />
              <Box>Today Discount Naka Points</Box>
            </Item>
          </Grid>
        </Grid>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={openModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            {/* <Link to="/dashboard/users" style={{ color: 'white', textDecoration: 'none' }}> */}
            Add Discount Naka Point
            {/* </Link> */}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={discountNakas?.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEADDEPRT}
                  rowCount={USERLISTDEPART.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {discountNakas?.data?.map((row, index) => {
                    const { id, name, date, status, mobile, avatarUrl, isVerified } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    const rowNum = page * rowsPerPage + index + 1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>
                        <TableCell>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              {/* <MenuItem sx={{ flexGrow: 1 }}> */}
                              <Button variant="contained">
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
                                Edit
                              </Button>
                              {/* </MenuItem> */}
                            </Grid>
                            <Grid item xs={6}>
                              <MenuItem
                                onClick={() => handleOpenMenu(row._id)}
                                sx={{ flexGrow: 1, color: 'error.main' }}
                              >
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                                Delete
                              </MenuItem>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell align="left">{row.discountNakaPointName}</TableCell>
                        <TableCell align="left">{row.remark}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={closeModal} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Add Discount Naka Point
          </Typography>

          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setDiscountPoint(e.target.value)}
                  label="Discount Point Name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField onChange={(e) => setRemrk(e.target.value)} label="Remark" variant="outlined" fullWidth />
              </Grid>
            </Grid>
            <Button onClick={handleDiscountPointSave} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
