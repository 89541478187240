import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  state: '',
  district: '',
  mine: ''
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.state = action.payload;
        },
        setDistrict: (state, action) => {
            state.district = action.payload;
        },
        setMine: (state, action) => {
            state.mine = action.payload;
        },
    }
});

export const { setState, setDistrict, setMine } = filterSlice.actions;
export default filterSlice.reducer;
