import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography, Box, Menu, MenuItem, Divider, IconButton, Tooltip, Button } from '@mui/material';
// components

import * as React from 'react';
import { useSelector } from 'react-redux';

// sections
import {
  AppWidgetSummary,
  MineSelect,
} from '../sections/@dashboard/app';
import { useGetEmployeeDataQuery, useGetMineQuery, useLazyGetVehicleQuery } from '../services/api';

export default function DashboardAppPage() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {data: employeeData } = useGetEmployeeDataQuery();
  const {data: mineData} = useGetMineQuery();
  const {data: vehicleData} = useLazyGetVehicleQuery();
  const employee = useSelector((state) => state.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clientVehiclesTrigger = () => {
    if (employee?.data?.role === "admin" || employee?.data?.role === "superAdmin"){
      return false;
    }
    if (employee?.data?.point?.length !== 0){
      return false;
    }
    return true;
  }

  return (
    <>
      <Helmet>
        <title> Mining Management System </title>
      </Helmet>
      <Button disabled={clientVehiclesTrigger()} variant='contained' style={{marginTop:'10vh'}} fullWidth href='/dashboard/vehicles'>
        Client Vehicles
      </Button>
      <Container maxWidth="xl">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* <Typography variant="h4" sx={{ mb: 5 }}> */}
          <h2>Dashboard</h2>
          {/* </Typography> */}

          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Language">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                icon={'game-icons:mine-truck'}
              />
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>Hindi</MenuItem>
            <MenuItem onClick={handleClose}>English</MenuItem>
            <Divider />
          </Menu>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={'Clients Vehicles'}
              total={
                <span>
                  <button className="mine-btn btn2">{vehicleData?.count}</button>&nbsp; Total
                </span>
              }
              icon={'game-icons:mine-truck'}
            />
            <br />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Mines "
              total={
                <span>
                  <button className="mine-btn btn2">{mineData?.count}</button>&nbsp; Total
                </span>
              }
              color="warning"
              icon={'game-icons:gold-mine'}
            />
            <br />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Employees"
              total={
                <span>
                  <button className="mine-btn btn2">{employeeData?.count}</button> &nbsp; Total
                </span>
              }
              color="error"
              icon={'clarity:employee-group-line'}
            />
          </Grid>
          <br />
        </Grid>
      </Container>
    </>
  );
}
