import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid,
} from '@mui/material';
// components
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import { useGetMineQuery } from '../services/api';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLISTQ from '../_mock/usertab';
import VendorPage from './VendorPage';
import Label from '../components/label';
import TablePage2 from './Tablepage2';


const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const {data:mines} = useGetMineQuery();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLISTQ.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLISTQ.length) : 0;

  const filteredUsers = applySortFilter(USERLISTQ, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>Mines</title>
      </Helmet>

      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Mines
        </h2>
      </div>

      <Container>
        <Box className="bg">
          <Helmet>
            <title> Mines </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Mines
            {/* <div  style={{fontSize:'10px'}}> <HomeIcon color="primary" /> Dashboard/vendorPage</div> */}
          </Typography>
          <Grid container spacing={0} sx={{ flexGrow: 1 }}>
            <Grid xs={6} xsOffset={0} md={4} mdOffset={0}>
              <Item className="vendor1">
                <Box>{mines?.data?.length}</Box>
                <br />
                <Box>Total Mines</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={4} mdOffset="auto">
              <Item className="vendor2">
                <Box>{mines?.data?.length}</Box>
                <br />

                <Box>Active Mines</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={4} mdOffset={0}>
              <Item className="vendor3">
                <Box>0</Box>
                <br />
                <Box>Inactive Mines</Box>
              </Item>
            </Grid>
          
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            <Link to="/dashboard/mines" style={{ color: 'white', textDecoration: 'none' }}>
              Add Mine
            </Link>
          </Button>
        </Stack>

        <TablePage2 />
      </Container>
    </>
  );
}
