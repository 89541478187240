import { Helmet } from 'react-helmet-async';
// @mui
import { useState, useEffect } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import PeopleIcon from '@mui/icons-material/People';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BlockIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Save';
import { Navigate, useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, TextField, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useGetMineQuery, useGetSandTypeQuery, useGetStatesQuery, useSaveRatesMutation } from '../services/api';
import Success from '../components/success/Success';
import Error from '../components/error/Error';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// components
const Rateform = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };


  const [departments, setDepartments] = useState([]);

  const handleChange3 = (event) => {
    setDepartments(event.target.value);
  };
  const navigate = useNavigate();
  const { data: states } = useGetStatesQuery();
  const { data: mines } = useGetMineQuery();
  const { data: sandTypes } = useGetSandTypeQuery();
  const [rateData, setRateData] = useState({});
  const [saveRates, {error, isLoading, isSuccess}] = useSaveRatesMutation();

//   const handleRateSave = async () => {
//     await saveRates({stateName: state, districtName: district, cityName: city, area, pincode});
//     closeModal();
//  }
  const handleChange = (e, field) => {
    setRateData({ ...rateData, [field]: e.target.value });
  }

  const handleSaveRate = async () => {
    saveRates(rateData);
  }
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/rate');
      }, 800);
    }
  }, [isSuccess])

  return (
    <>
     {isSuccess && <Success isSuccess={isSuccess} successMessage={"Saved successfully"}/>}
        {error && <Error error={error}/>}
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <h2>Rate</h2>


        <Grid container spacing={2}>

          <Grid item xs={12} sm={6}>

            <FormControl>
              <InputLabel>State</InputLabel>
              <Select
                id="input-with-sx"
                label="State"
                variant="outlined"
                style={{ width: '35ch' }}
                onChange={(e) => handleChange(e, 'stateName')}
              >
                {states?.data.map((state, index) => (
                  <MenuItem key={index} value={state.stateName}>{state.stateName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <InputLabel>Select Mine</InputLabel>
              <Select
                id="input-with-sx"
                label="Select Mine"
                variant="outlined"
                style={{ width: '35ch' }}
                onChange={(e) => handleChange(e, 'mineName')}
              >
                {mines?.data.filter(o => o.state === rateData?.stateName)?.map((mine, index) => (
                  <MenuItem key={index} value={mine.mineName}>{mine.mineName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>



            <TextField
              id="input-with-sx"
              required
              label="Rates "
              variant="outlined"
              style={{ width: '40ch' }}
              onChange={(e) => handleChange(e, 'rate')}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Sand Type</InputLabel>
              <Select
                style={{ width: '35ch' }}

                label=" Sand Type "
                variant="outlined"
                onChange={(e) => handleChange(e, 'sandType')}
              >
               {sandTypes?.data.map((sandType, index) => (
                  <MenuItem key={index} value={sandType.sandType}>{sandType.sandType}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '40ch' }}>
              <InputLabel>Status</InputLabel>
              <Select value={rateData?.status} id="input-with-sx" onChange={(e) => handleChange(e, 'status')} label="Status" variant="outlined" >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}






        </Grid>




        <Button variant="contained" onClick={handleSaveRate} endIcon={<SaveIcon />}>
          Save
        </Button>
      </Typography>
    </>
  );
};

export default Rateform;
