import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  TablePagination,
  Toolbar,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useGetEmployeeDataQuery, useSaveAttendanceMutation, useUpdateEmployeeDataMutation } from '../services/api';
import Success from '../components/success/Success';
import Error from '../components/error/Error';

const Attendence = () => {
  const navigate = useNavigate();
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const { data: employees } = useGetEmployeeDataQuery();
  const [saveAttendance, { error, isLoading, isSuccess }] = useSaveAttendanceMutation();
  const [updateEmployeeData, { error: updateError, isLoading: updateLoading, isSuccess: updateSuccess }] =
    useUpdateEmployeeDataMutation();
  const [remark, setRemark] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({
    photoLink: null,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  const handleFileUpload = (e, field) => {
    const file = e.target.files[0];
    setUploadedFiles({ ...uploadedFiles, [field]: file });
  };

  const handleSave = (employee) => {
    const formData = new FormData();
    formData.append('photoLink', uploadedFiles.photoLink);
    formData.append('employee', employee);
    formData.append('remark', remark);
    formData.append('status', 'Present');
    saveAttendance(formData);
    // Update employee data to set attendance to 'present'
    const id = employee;
    updateEmployeeData({ id, dataToUpdate: { attendance: 'present' } });
  };

  const handleChange = (e) => {
    setRemark(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/attendance');
      }, 800);
    }
  }, [isSuccess]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
  };

  const filteredEmployees = employees?.data?.filter((employee) =>
    employee.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredEmployees.length) : 0;

  const displayedEmployees = filteredEmployees
    ?.filter((employee) => employee.attendance !== 'present')
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" id="tableTitle" component="div">
          Attendance
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search by name"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginLeft: 'auto' }}
        />
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Employee Name</TableCell>
              <TableCell>Upload Photo</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedEmployees?.map((employee, index) => (
              <TableRow key={employee.id}>
                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                <TableCell>{employee?.name} ({employee?.phone})</TableCell>
                <TableCell>
                  <Button
                    onChange={(e) => handleFileUpload(e, 'photoLink')}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Photo
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </TableCell>
                <TableCell>
                  <TextField onChange={(e) => handleChange(e)} />
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleSave(employee?._id)} variant="contained" style={{ marginTop: '0' }}>
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredEmployees?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Attendence;
