import { Helmet } from 'react-helmet-async';
import { filter, set } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Icon } from '@iconify/react';

import SaveIcon from '@mui/icons-material/Save';
import './Forms.css';

import DeleteIcon from '@mui/icons-material/Delete';
// components
import { styled } from '@mui/material/styles';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import EMPLOYEROLE from '../_mock/employrole';
// import VendorPage from '../VendorPage';
import Label from '../components/label';
import Iconify from '../components/iconify';
import {
  useGetEmployeeDataQuery,
  useGetPointsQuery,
  useUpdateEmployeeDataMutation,
  useGetMineQuery,
} from '../services/api';
import Success from '../components/success/Success';
import Error from '../components/error/Error';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Point Name', label: 'Point Name', alignRight: false },
  { id: 'Employee Name', label: 'Employee Name', alignRight: false },
  //   { id: 'date', label: 'Date', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
];

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Role() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { data: points } = useGetPointsQuery();
  const [id, setId] = useState(null);
  const [updateEmployeeData, { data, error, isLoading, isSuccess }] = useUpdateEmployeeDataMutation();
  const [point, setPoint] = useState([]);
  const [mineName, setMineName] = useState(null);
  const { data: employees } = useGetEmployeeDataQuery();
  const { data: mines } = useGetMineQuery();
  const [selectedPoints, setSelectedPoints] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = EMPLOYEROLE.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleEmployeeDataUpdate = async () => {
    await updateEmployeeData({ id, dataToUpdate: { point, mineName } });
    setPoint([]);
    setId('');
    setMineName('');
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - EMPLOYEROLE.length) : 0;

  const filteredUsers = applySortFilter(EMPLOYEROLE, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  // form conts

  const [forms, setForms] = useState([{ id: 1, textFields: [{ field: '', name: '' }] }]);

  const addTextField = (formIndex) => {
    const updatedForms = [...forms];
    updatedForms[formIndex].textFields.push({ field: '', name: '' });
    setForms(updatedForms);
  };

  const removeTextField = (formIndex, fieldIndex) => {
    if (forms[formIndex].textFields.length > 1) {
      const updatedForms = [...forms];
      updatedForms[formIndex].textFields.splice(fieldIndex, 1);
      setForms(updatedForms);
    }
  };

  const handleFieldChange = (formIndex, fieldIndex, field, value) => {
    const updatedForms = [...forms];
    updatedForms[formIndex].textFields[fieldIndex][field] = value;
    setForms(updatedForms);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/role');
      }, 800);
    }
  }, [isSuccess]);
  console.log('points', mines.data);
  return (
    <>
      <Helmet>
        <title>Employee Management</title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / role
        </h2>
      </div>
      <Container>
        <Box className="bg">
          <Helmet>
            <title> Employee Role </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            Role {/* <div  style={{fontSize:'10px'}}> <HomeIcon color="primary" /> Dashboard/vendorPage</div> */}
          </Typography>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
        </Stack>

        <form>
          {forms.map((form, formIndex) => (
            <div key={formIndex}>
              <Grid container spacing={3}>
                {form.textFields.map((text, fieldIndex) => (
                  <Grid container spacing={6} key={fieldIndex}>
                    <Grid item xs={12} sm={4}>
                      <FormControl style={{ width: '100%', marginTop: '10px' }}>
                        <InputLabel id="demo-multiple-name-label">Select Employee</InputLabel>
                        <Select
                          onChange={(e) => setId(e.target.value)}
                          id="input-with-sx"
                          label="Point Name "
                          value={id}
                          variant="outlined"
                        >
                          {employees?.data.map((employee, index) => (
                            <MenuItem key={index} value={employee._id} style={{ justifyContent: 'space-between' }}>
                              {employee.name}<span style={{ fontWeight: 'bold' }}>{employee.phone}</span>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl style={{ width: '100%', marginTop: '10px' }}>
                        <InputLabel id="demo-multiple-name-label">Select Mine</InputLabel>
                        <Select
                          onChange={(e) => setMineName(e.target.value)}
                          id="input-with-sx"
                          label="Mine Name"
                          value={mineName}
                          variant="outlined"
                        >
                          {mines?.data.map((mine, index) => (
                            <MenuItem key={index} value={mine.mineName}>
                              {mine.mineName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl style={{ width: '100%', marginTop: '10px' }}>
                        <InputLabel id="demo-multiple-name-label">Select Point</InputLabel>
                        <Select
                          multiple
                          onChange={(e) => setPoint(e.target.value)}
                          value={point}
                          label="Point Name"
                          variant="outlined"
                        >
                          {mines?.data.map((mine, mineIndex) =>
                            mine.points.map((point, pointIndex) => (
                              <MenuItem
                                key={`${mineIndex}-${pointIndex}`}
                                value={point.title}
                                style={{ justifyContent: 'space-between' }}
                              >
                                {point.title} <span style={{ fontWeight: 'bold' }}>{mine.mineName}</span>
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      {form.textFields.length > 1 && (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                          <Typography variant="h4" gutterBottom />

                          <Button
                            type="button"
                            style={{ marginTop: '10px' }}
                            onClick={() => removeTextField(formIndex, fieldIndex)}
                            variant="contained"
                          >
                            <DeleteIcon />
                          </Button>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Button
                type="button"
                style={{ marginTop: '5vh' }}
                onClick={handleEmployeeDataUpdate}
                variant="contained"
                endIcon={<SaveIcon />}
              >
                Save
              </Button>
              {error && <Error error={error} />}
              {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}
            </div>
          ))}
        </form>
        <br />

        {/* <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={EMPLOYEROLE.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Scrollbar>
            <TableContainer sx={8}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={EMPLOYEROLE.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, Name, date, status, Employeename, avatarUrl, isVerified } = row;
                    const selectedUser = selected.indexOf(Name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, Name)} />
                        </TableCell>

                        <TableCell style={{ width: '10vw' }}>
                          <MenuItem>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                        </TableCell>

                        <TableCell>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {Name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{Employeename}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card> */}
      </Container>
    </>
  );
}
