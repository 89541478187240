import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, _action) => {
            state.data = _action.payload
        },
        clearUser: (state, _action) => {
            state.data = null
        }
    }
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
