import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import PeopleIcon from '@mui/icons-material/People';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BlockIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography, Box, TextField, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// components
const Users = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  return (
    <>
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <h2>Users</h2>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" required label="Full Name" variant="outlined" style={{ width: '40ch' }} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" required label="Email" variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Distriict  "
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Profile Name"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
             

<FormControl style={{ width: '40ch' }}>
              <InputLabel> Status    </InputLabel>
              <Select id="input-with-sx" label="Mine Type" variant="outlined">
                <MenuItem value="option1">Active</MenuItem>
                <MenuItem value="option2">Inactive</MenuItem>
              </Select>
            </FormControl>
              </Box>
             
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Mobile" variant="outlined" style={{ width: '40ch' }} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Company name " variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="City" variant="outlined" style={{ width: '40ch' }} />
              </Box>

              {/* <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '.5vmax' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '.5vmax' }}>
                  <Checkbox {...label} defaultChecked size="small" />
                  <TextField id="input-with-sx" label="Is Admin" variant="outlined" style={{ width: '30ch' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '.5vmax' }}>
                  <Checkbox {...label} defaultChecked size="small" />
                  <TextField id="input-with-sx" label="Is Super Admin" variant="outlined" style={{ width: '30ch' }} />
                </Box>
              </Box> */}

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Password " variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="IS ADMIN " variant="outlined" style={{ width: '40ch' }} />
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Button variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
      </Typography>
    </>
  );
};

export default Users;
