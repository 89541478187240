import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import PeopleIcon from '@mui/icons-material/People';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BlockIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography, Box, TextField, Stack,FormControl, InputLabel,Select,MenuItem } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// components
const Targetform = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  return (
    <>
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <h2>Target</h2>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
             

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                




<FormControl>
                  <InputLabel>State</InputLabel>
                <Select
    id="input-with-sx" 
    label="State"
    variant="outlined"
    style={{ width: '35ch' }}
  >
    <MenuItem value="option1">bihar</MenuItem>
    <MenuItem value="option2">Madhaya pradesh</MenuItem>
    <MenuItem value="option3">rajisthan</MenuItem>
  </Select>
  </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Daily Target "
                  variant="outlined"
                  style={{ width: '40ch' }}
                /> 
              </Box>

           
            
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
             

            <FormControl>
                  <InputLabel>Select Mine</InputLabel>
                <Select
    id="input-with-sx" 
    label="Select Mine"
    variant="outlined"
    style={{ width: '35ch' }}
  >
    <MenuItem value="option1">mine 1</MenuItem>
    <MenuItem value="option2">mine 2</MenuItem>
    <MenuItem value="option3">mine 3</MenuItem>
  </Select>
  </FormControl>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Status " variant="outlined" style={{ width: '40ch' }} />
              </Box>

            

          
            </Stack>
          </Grid>
        </Grid>

        <Button variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
      </Typography>
    </>
  );
};

export default Targetform;
