import * as React from 'react';
import { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Stack,
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Equipment1 = () => {
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField id="contractor-name" required label="Machine Name " variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Machine Type </InputLabel>
              <Select id="input-with-sx" label="State" variant="outlined">
                <MenuItem value="option1">Excavator </MenuItem>
                <MenuItem value="option2">Conveyor Belt</MenuItem>
                <MenuItem value="option3">Truck</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="contractor-name" required label=" Manufacturer " variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="equipment-code" label=" Model" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="loading-material" required label="Serial Number " variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              id="dateInput"
              variant="outlined"
              label="Purchase Date"
              value={selectedDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true, // This will make the label "shrink" when there's a value
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="loading-material"
              required
              label="Current Location (if mobile)  "
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              id="dateInput-code"
              variant="outlined"
              label="Maintenance History"
              value={selectedDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true, // This will make the label "shrink" when there's a value
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Operational Status </InputLabel>
              <Select id="input-with-sx" label="Operational Status " variant="outlined">
                <MenuItem value="option1">Operational </MenuItem>
                <MenuItem value="option2">Under Maintenance </MenuItem>
                <MenuItem value="option3">Out of Service</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <br />
        <Button variant="contained" type="submit" endIcon={<SaveIcon />}>
          Save
        </Button>
      </form>
    </div>
  );
};

export default Equipment1;
