import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField'; // Import TextField
import './Form1.css';
import './Forms.css';

const columns = [
  { id: 'index', label: 'S.No', maxWidth: 10 },
  { id: 'createdAt', label: 'Date Created', minWidth: 200 },
  { id: 'VehicleToken', label: 'Vehicle Token', minWidth: 150 },
  { id: 'vehicleNumber', label: 'Vehicle Number', minWidth: 150 },
  { id: 'vehicleType', label: 'Vehicle Type', minWidth: 150 },
  { id: 'vehicleModel', label: 'Vehicle Model', minWidth: 150 },
];

const LoadingRow = () => (
  <TableRow>
    {columns.map((column) => (
      <TableCell key={column.id}>
        <Skeleton animation="wave" />
      </TableCell>
    ))}
  </TableRow>
);

export default function VehicleTable({ rows, setVehicleIndex, handleVehicleTimelineOpen }) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  useEffect(() => {
    if (rows) {
      setLoading(false); // Data has been received, set loading to false
    }
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update search term when input changes
  };
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   console.log(date, dateString);
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = String(date.getFullYear());
  //   const hours = String(date.getUTCHours()).padStart(2, '0');
  //   const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  //   // const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  //   // const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

  //   return `${hours}:${minutes} : ${day}-${month}-${year}`;
  // };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 2 }}>
      {/* Add the TextField for searching */}
      <TextField
        label="Search by Vehicle Number / वाहन संख्या द्वारा खोजें"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ textTransform: 'uppercase' }}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <LoadingRow />
            ) : (
              rows
                ?.filter((row) => {
                  const vehicleNumber = row?.vehicleData?.vehicleNumber || '';
                  return vehicleNumber.toLowerCase().includes(searchTerm.toLowerCase());
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell style={{ minWidth: 10 }}>{index + 1}</TableCell>
                    <TableCell
                      style={{ minWidth: 200, cursor: 'pointer' }}
                      onClick={() => {
                        setVehicleIndex(row?._id);
                      }}
                    >
                      {`${row?.createdAt?.split('T')[0]} ${row?.createdAt?.split('T')[1].split('.')[0]}`}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: 150, cursor: 'pointer' }}
                      onClick={() => {
                        handleVehicleTimelineOpen();
                        setVehicleIndex(row?._id);
                      }}
                    >
                      {row?.token}
                    </TableCell>
                    <TableCell style={{ minWidth: 150 }}>{row?.vehicleData?.vehicleNumber}</TableCell>
                    <TableCell style={{ minWidth: 150 }}>{row?.vehicleData?.vehicleType}</TableCell>
                    <TableCell style={{ minWidth: 150 }}>{row?.vehicleData?.vehicleModel}</TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rows?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
