import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';


const CftCalculator = () => {

  const [feet, setFeet] = useState('');
  const [inches, setInches] = useState('');
  const [widthFeet, setWidthFeet] = useState('');
  const [widthInches, setWidthInches] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [totalVolume, setTotalVolume] = useState(null);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const [totalCost, setTotalCost] = useState(null);

  

  // new only feet start
  const [feetnew, setFeetnew] = useState('');
  const [inchesnew, setInchesnew] = useState('');
  const [widthFeetnew, setWidthFeetnew] = useState('');
  const [widthInchesnew, setWidthInchesnew] = useState('');
  const [heightFeetnew, setHeightFeetnew] = useState('');
  const [heightInchesnew, setHeightInchesnew] = useState('');
  const [totalVolumenew, setTotalVolumenew] = useState(null);
  const [totalCostnew, setTotalCostnew] = useState(null);
  // new  only feet end



// meter
  const [lengthMeters, setLengthMeters] = useState('');
  const [lengthCentimeters, setLengthCentimeters] = useState('');
  const [widthMeters, setWidthMeters] = useState('');
  const [widthCentimeters, setWidthCentimeters] = useState('');
  const [heightMeters, setHeightMeters] = useState('');
  const [heightCentimeters, setHeightCentimeters] = useState('');
  const [totalVolumemeter, setTotalVolumemeter] = useState(null);
// meter


  const [fieldType, setFieldType] = useState('Number');
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [newOption, setNewOption] = useState('');

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setFieldType(selectedType);

    // Reset checkbox options and new option when the type changes
    setCheckboxOptions([]);
    setNewOption('');
  };

  const handleCheckboxChange1 = (option) => {
    const updatedOptions = checkboxOptions.includes(option)
      ? checkboxOptions.filter((item) => item !== option)
      : [...checkboxOptions, option];

    setCheckboxOptions(updatedOptions);
  };

  const handleAddOption = () => {
    const trimmedOption = newOption.trim();
    if (trimmedOption && !checkboxOptions.includes(trimmedOption)) {
      setCheckboxOptions([...checkboxOptions, trimmedOption]);
      setNewOption('');
    }
  };


  // drop Down  start

  const [dropdownTypea, setDropdownType] = useState('Dropdown');
  const [dropdownOptionsa, setDropdownOptionsa] = useState([]);
  const [newOptiona, setNewOptiona] = useState('');

  const handleTypeChangea = (event) => {
    const selectedTypea = event.target.value;
    setDropdownType(selectedTypea);

    // Reset dropdown options and new option when the type changes
    setDropdownOptionsa([]);
    setNewOptiona('');
  };

  const handleDropdownChanges = (option) => {
    // Implement your logic for handling dropdown option selection
    console.log(`Selected option: ${option}`);
  };

  const handleAddOptions = () => {
    const trimmedOption = newOptiona.trim();
    if (trimmedOption && !dropdownOptionsa.includes(trimmedOption)) {
      setDropdownOptionsa([...dropdownOptionsa, trimmedOption]);
      setNewOptiona('');
    }
  };

// for meter
  const calculateTotalVolumemeter = () => {
    const lengthInMeters = parseFloat(lengthMeters) + parseFloat(lengthCentimeters) / 100;
    const widthInMeters = parseFloat(widthMeters) + parseFloat(widthCentimeters) / 100;
    const heightInMeters = parseFloat(heightMeters) + parseFloat(heightCentimeters) / 100;

    if (
      !Number.isNaN(lengthInMeters) &&
      !Number.isNaN(widthInMeters) &&
      !Number.isNaN(heightInMeters)
    ) {
      const calculatedTotalVolumemeter = lengthInMeters * widthInMeters * heightInMeters;
      setTotalVolumemeter(calculatedTotalVolumemeter.toFixed(2));
    } else {
        setTotalVolumemeter(null);
    }
  };


// feet

  const calculateTotalVolume = () => {
    const lengthInFeet = parseFloat(feet) + parseFloat(inches) / 12;
    const widthInFeet = parseFloat(widthFeet) + parseFloat(widthInches) / 12;
    const heightInFeet = parseFloat(heightFeet) + parseFloat(heightInches) / 12;

    if (!Number.isNaN(lengthInFeet) && !Number.isNaN(widthInFeet) && !Number.isNaN(heightInFeet)) {
      const calculatedTotalVolume = lengthInFeet * widthInFeet * heightInFeet;
      setTotalVolume(calculatedTotalVolume.toFixed(2));

      if (!Number.isNaN(pricePerUnit)) {
        const calculatedTotalCost = calculatedTotalVolume * parseFloat(pricePerUnit);
        setTotalCost(calculatedTotalCost.toFixed(2));
      } else {
        setTotalCost(null);
      }
    } else {
      setTotalVolume(null);
      setTotalCost(null);
    }
  };

// new feet cft calculator

const calculateTotalVolumenew = () => {
  const lengthInFeetnew = parseFloat(feetnew) + parseFloat(inchesnew) / 12;
  const widthInFeetnew = parseFloat(widthFeetnew) + parseFloat(widthInchesnew) / 12;
  const heightInFeetnew = parseFloat(heightFeetnew) + parseFloat(heightInchesnew) / 12;

  if (!Number.isNaN(lengthInFeetnew) && !Number.isNaN(widthInFeetnew) && !Number.isNaN(heightInFeetnew)) {
    const calculatedTotalVolumenew = lengthInFeetnew * widthInFeetnew * heightInFeetnew;
    setTotalVolumenew(calculatedTotalVolumenew.toFixed(2));

    if (!Number.isNaN(pricePerUnit)) {
      const calculatedTotalCostnew = calculatedTotalVolumenew * parseFloat(pricePerUnit);
      setTotalCostnew(calculatedTotalCostnew.toFixed(2));
    } else {
      setTotalCost(null);
    }
  } else {
    setTotalVolumenew(null);
    setTotalCostnew(null);
  }
};



  const [sandType, setSandType] = useState(null); // Use null for the controlled component
  const [VehicleTypes, setVehicleTypes] = useState(null); // Use null for the controlled component for tyers

  const sandTypes = [
    { label: 'Type 1', value: 'type1' },
    { label: 'Type 2', value: 'type2' },
    { label: 'Type 3', value: 'type3' },
    // Add more sand types as needed
  ];

  const Vehicletype = [
    { label: 'Type-4', value: '4-Tyre' },
    { label: 'Type-6', value: '6-Tyre' },
    { label: 'Type-8', value: '8-Tyre' },
    { label: 'Type-10', value: '10-Tyre' },
    { label: 'Type-12', value: '12-Tyre' },
    // Add more sand types as needed tyers
  ];

  const handleSandTypeChangetype = (event, newValue) => {
    setVehicleTypes(newValue);
  };




  const handleSandTypeChange = (event, newValue) => {
    setSandType(newValue);
  };

  const [subscribe, setSubscribe] = useState(false);

  const handleCheckboxChange = (event) => { 
    setSubscribe(event.target.checked);
  };

  const [subscriptionType, setSubscriptionType] = useState('free');

  const handleRadioChange = (event) => {
    setSubscriptionType(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    // Handle file upload logic
    if (selectedFile) {
      console.log('Uploading file:', selectedFile);
      // You can use APIs like FormData to send the file to the server
    } else {
      console.error('No file selected.');
    }
  };


 
  const handleCalculateButtonClick = () => {
    calculateTotalVolume();
    calculateTotalVolumemeter();
  };

  return (
    <div>

<div>
      <Typography variant="h4">Vehicle Data </Typography>



      <Grid container spacing={2}>
        <Grid item xs={10}>
        <TextField label="गाडी नम्बर" type="Text"  fullWidth />

        </Grid>

        <Grid item xs={5}>
        <FormControl fullWidth>
            <InputLabel id="Vichel-type" />
            <Autocomplete
              options={Vehicletype}
              getOptionLabel={(option) => option.label}
              value={VehicleTypes}
              onChange={handleSandTypeChangetype}
              renderInput={(params) => <TextField {...params} label="गाडी का प्रकार" variant="outlined"  fullWidth />}
            />
          </FormControl>
        </Grid>

        <Grid item xs={5}>
        <TextField label="गाडी मोडल" type="Text"  fullWidth />

        </Grid>
    
       
      </Grid>

      <h5>गाडी की लम्बाई मीटर में डालें</h5>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label="लम्बाई (मीटर)" type="number" fullWidth value={lengthMeters} onChange={(e) => setLengthMeters(e.target.value)} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="लम्बाई (से.मीटर)" type="number" fullWidth value={lengthCentimeters} onChange={(e) => setLengthCentimeters(e.target.value)} />
        </Grid>
      </Grid>
      <h5>गाडी की चोडाई मीटर में डालें</h5>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label="चोडाई (मीटर)" type="number" fullWidth  value={widthMeters} onChange={(e) => setWidthMeters(e.target.value)} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="चोडाई (से.मीटर)" type="number" fullWidth value={widthCentimeters} onChange={(e) => setWidthCentimeters(e.target.value)} />
        </Grid>
      </Grid>
      <h5>ग़ाडी की उचाई मीटर में डालें</h5>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label="उचाई (मीटर)" type="number" fullWidth value={heightMeters} onChange={(e) => setHeightMeters(e.target.value)} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="उचाई (से.मीटर)" type="number" fullWidth value={heightCentimeters} onChange={(e) => setHeightCentimeters(e.target.value)} />
        </Grid>
      </Grid>
      <br/>

<hr/>

      <h5>गाडी की लम्बाई फुट में डालें</h5>

<Grid container spacing={2}>

  <Grid item xs={4}>
    <TextField label="लम्बाई (फुट)" type="number" value={feet} onChange={(e) => setFeet(e.target.value)} fullWidth />
  </Grid>
  <Grid item xs={4}>
    <TextField label="लम्बाई (इंच)" type="number" value={inches} onChange={(e) => setInches(e.target.value)} fullWidth />
  </Grid>
</Grid>

<h5>गाडी की चोडाई फुट में डालें</h5>

<Grid container spacing={2}>
  <Grid item xs={4}>
    <TextField
      label="चोडाई (फुट)"
      type="number"
      value={widthFeet}
      onChange={(e) => setWidthFeet(e.target.value)}
    />
  </Grid>
  <Grid item xs={4}>
    <TextField
      label="चोडाई (इंच)"
      type="number"
      value={widthInches}
      onChange={(e) => setWidthInches(e.target.value)}
      fullWidth
    />
  </Grid>
</Grid>


<h5>ग़ाडी की उचाई फुट में डालें</h5>

<Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="उचाई (फुट)"
            type="number"
            value={heightFeet}
            onChange={(e) => setHeightFeet(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="उचाई (इंच)"
            type="number"
            value={heightInches}
            onChange={(e) => setHeightInches(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
<br/>
<br/>
      {/* <Grid container spacing={2}>
        <Grid item xs={2}>
        <Button variant="contained" onClick={calculateTotalVolume}  >
          Calculate Total feet
        </Button>
        </Grid>
        <Grid item xs={2}>
        <Button variant="contained" onClick={calculateTotalVolumemeter}>
        Calculate Total meter
      </Button>
        </Grid>
       
      </Grid> */}
        <Grid container spacing={2}>
      <Grid item xs={2}>
        <Button variant="contained" onClick={handleCalculateButtonClick}>
        कैलकुलेट करें
        </Button>
      </Grid>
    </Grid>

     
      <br />

      <Grid container spacing={2}>
        <Grid item xs={2}>
        {totalVolume !== null && (
        <div>
          <Typography variant="h6">ग़ाडी की मात्रा :</Typography>
          <Typography>{totalVolume} घन फुट मे</Typography>
        </div>
      )}
        </Grid>
        <Grid item xs={2}>
        {totalVolumemeter !== null && (
        <div>
          <Typography variant="h6">ग़ाडी की मात्रा</Typography>
          <Typography>{totalVolumemeter} घन मीटर</Typography>
        </div>
      )}
        </Grid>
       
      </Grid>
     
{/* meter buton clc */}
    
<br/>
<br/>
    
    </div>


      <div style={{width:'700px', border:'2px solid black' , padding:'5px'}}>
      <Typography variant="h4">CFT Calculator</Typography>
      <h5>Length</h5>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label="Length (feet)" type="number" value={feetnew} onChange={(e) => setFeetnew(e.target.value)} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Length (inches)" type="number" value={inchesnew} onChange={(e) => setInchesnew(e.target.value)} />
        </Grid>
      </Grid>
      <h5>Width</h5>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Width (feet)"
            type="number"
            value={widthFeetnew}
            onChange={(e) => setWidthFeetnew(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Width (inches)"
            type="number"
            value={widthInchesnew}
            onChange={(e) => setWidthInchesnew(e.target.value)}
          />
        </Grid>
      </Grid>
      <h5>Height</h5>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Height (feet)"
            type="number"
            value={heightFeetnew}
            onChange={(e) => setHeightFeetnew(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Height (inches)"
            type="number"
            value={heightInchesnew}
            onChange={(e) => setHeightInchesnew(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />

      <Grid container spacing={2}>
        {/* <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="sand-type-label" />
            <Autocomplete
              options={sandTypes}
              getOptionLabel={(option) => option.label}
              value={sandType}
              onChange={handleSandTypeChange}
              renderInput={(params) => <TextField {...params} label="Sand Type" variant="outlined" fullWidth />}
            />
          </FormControl>
        </Grid> */}
{/* 
        <Grid item xs={4}>
          <TextField
            label="Rate per Unit"
            type="number"
            value={pricePerUnit}
            onChange={(e) => setPricePerUnit(e.target.value)}
          />
        </Grid> */}
      </Grid>
      <div>
        <br />

        <Button variant="contained" onClick={calculateTotalVolumenew}>
          Calculate Total Volume
        </Button>
      </div>
      <br />
      {totalVolumenew !== null && (
        <div>
          <Typography variant="h6">Total Volume:</Typography>
          <Typography>{totalVolumenew} cubic units</Typography>
        </div>
      )}

      {/* {totalCost !== null && (
        <div>
          <Typography variant="h6">Total Cost:</Typography>
          <Typography>${totalCost}</Typography>
        </div>
      )} */}



</div>

      {/* TextField */}

      <h3>TextField</h3>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label=" TextField" type="text" />
        </Grid>
      </Grid>

      {/* Number */}

      <h3>Number</h3>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label=" Number" type="number" />
        </Grid>
      </Grid>

      {/* DropDown */}

      <h3>DropDown</h3>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="sand-type-label" />
            <Autocomplete
              options={sandTypes}
              getOptionLabel={(option) => option.label}
              value={sandType}
              onChange={handleSandTypeChange}
              renderInput={(params) => <TextField {...params} label="Sand Type" variant="outlined" fullWidth />}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Password */}

      <h3>Password</h3>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label=" Password" type="password" />
        </Grid>
      </Grid>

      {/* Email */}

      <h3>Email</h3>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label=" Email" type="email" />
        </Grid>
      </Grid>

      {/* Checkbox  */}

      <h3>Checkbox</h3>

      <Grid container spacing={2} mt={2}>
        <FormControlLabel
          control={<Checkbox checked={subscribe} onChange={handleCheckboxChange} />}
          label="Subscribe to newsletter"
        />
      </Grid>

      {/* Radio */}

      <h3>Radio</h3>

      <Grid container spacing={2} mt={2}>
        <RadioGroup value={subscriptionType} onChange={handleRadioChange}>
          <FormControlLabel value="free" control={<Radio />} label="Free Subscription" />
          <FormControlLabel value="premium" control={<Radio />} label="Premium Subscription" />
        </RadioGroup>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={4}>
          <Typography variant="h4">File Upload</Typography>
          <input type="file" onChange={handleFileChange} />

          {selectedFile && (
            <div>
              <Typography variant="h6">Selected File:</Typography>
              <Typography>{selectedFile.name}</Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <h3>CheckBox add option</h3>

      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
                  <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <InputLabel>{'add type'}</InputLabel>
                    <Select id="input-with-sx" label="Add Type" variant="outlined" onChange={handleTypeChange}>
                      <MenuItem value="Number">Number</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="InputBox">InputBox</MenuItem>
                      <MenuItem value="FileUpload">FileUpload</MenuItem>
                      <MenuItem value="Date">Date</MenuItem>
                      <MenuItem value="Time">Time</MenuItem>
                      <MenuItem value="CheckBox">CheckBox</MenuItem>
                      {/* Additional options */}
                    </Select>

                    {fieldType === 'CheckBox' && (
                      <FormControl component="fieldset">
                        <FormGroup>
                          {checkboxOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              control={
                                <Checkbox
                                  checked={checkboxOptions.includes(option)}
                                  onChange={() => handleCheckboxChange1(option)}
                                />
                              }
                              label={option}
                            />
                          ))}
                          {/* Add option input for adding new checkbox options */}
                          <TextField
                            type="text"
                            style={{ marginTop: '10px' }}
                            placeholder="New Option"
                            value={newOption}
                            onChange={(e) => setNewOption(e.target.value)}
                          />
                          {/* Add button to add new option */}
                          <Button onClick={handleAddOption} style={{ marginTop: '10px' }} variant="outlined">
                            Add Option
                          </Button>
                        </FormGroup>
                      </FormControl>
                    )}
                  </FormControl>
                </Grid>
      </Grid>



      <h3>DropDown add option</h3>

      <Grid container spacing={2}>
      <Grid style={{ width: '400px' }}>
        <FormControl style={{ width: '100%', marginTop: '20px' }}>
          <InputLabel>{dropdownTypea}</InputLabel>
          <Select id="input-with-sx" label="Add Type" variant="outlined" onChange={handleTypeChangea}>
            <MenuItem value="">Number</MenuItem>
            <MenuItem value="Email">Email</MenuItem>
            <MenuItem value="InputBox">InputBox</MenuItem>
            <MenuItem value="FileUpload">FileUpload</MenuItem>
            <MenuItem value="Date">Date</MenuItem>
            <MenuItem value="Time">Time</MenuItem>
            <MenuItem value="DropDown">DropDown</MenuItem>
            {/* Additional options */}
          </Select>

          {dropdownTypea === 'DropDown' && (
            <FormControl>
              <InputLabel>Choose Options</InputLabel>
              <Select multiple value={dropdownOptionsa} onChange={(e) => handleDropdownChanges(e.target.value)}>
                {dropdownOptionsa.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {/* Add option input for adding new dropdown options */}
              <TextField
                type="text"
                placeholder="New Option"
                value={newOptiona}
                onChange={(e) => setNewOptiona(e.target.value)}
              />
              {/* Add button to add new option */}
              <Button onClick={handleAddOptions} variant="outlined">
                Add Option
              </Button>
            </FormControl>
          )}
        </FormControl>
      </Grid>
      </Grid>


      
      


    </div>
  );
};

export default CftCalculator;
