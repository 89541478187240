import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function MyForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [ti, setTi] = useState('');
  const [tia, setTia] = useState('');
  const [tiaa, setTiaa] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCitya, setSelectedCitya] = useState('');

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setSelectedCity(newCity);
  };


  return (
    <div>
      <TextField
        label="Name"
        variant="outlined"
        value={name}
        onChange={handleNameChange}
      />

      {name && (
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
        />
      )}

      {email && (
        <TextField
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      )}


{title && (





<FormControl style={{ width: '40ch' }}>
        <InputLabel>Destination Type </InputLabel>
        <Select
          labelId="age-label"
          id="age-select"
          value={selectedCity}
        
onChange={(e) => setSelectedCity(e.target.value)}
          label="City"
        >
          <MenuItem value="Drumoyaryd">Dumpyard</MenuItem>
          <MenuItem value="client">Client Location 
</MenuItem>
        </Select>
      </FormControl>
  
  )}
     
     {selectedCity === 'Drumoyaryd' && (
        <FormControl style={{ width: '40ch' }}>
          <InputLabel>Select Dumpyard</InputLabel>
          <Select
            labelId="drumoyaryd-label"
            id="drumoyaryd-select"
            value={ti}
            onChange={(e) => setTi(e.target.value)}
            label="Drumoyaryd Dropdown"
          >
            <MenuItem value="Option 1">Option 1</MenuItem>
            <MenuItem value="Option 2">Option 2</MenuItem>
            <MenuItem value="Option 4">Option 4</MenuItem>
            <MenuItem value="Option 3">Option 4</MenuItem>
          </Select>
        </FormControl>
      )}

   
{selectedCity === 'Drumoyaryd' && (
        <TextField
          label="Select Route "
          variant="outlined"
          value={tia}
          onChange={(e) => setTia(e.target.value)}
        />
      )}




{selectedCity === 'client' && (
        <TextField
          label="Select Location"
          variant="outlined"
          value={tia}
          onChange={(e) => setTia(e.target.value)}
        />
      )}  

{selectedCity === 'client' && (
        <FormControl style={{ width: '40ch' }}>
          <InputLabel>Route</InputLabel>
          <Select
            labelId="-label"
            id="drumoyaryd-select"
            value={ti}
            onChange={(e) => setTi(e.target.value)}
            label="Client Dropdown"
          >
            <MenuItem value="Option 1">Option 1</MenuItem>
            <MenuItem value="Option 2">Option 2</MenuItem>
            <MenuItem value="Option 4">Option 4</MenuItem>
            <MenuItem value="Option 3">Option 4</MenuItem>
          </Select>
        </FormControl>
      )}





    
    </div>
  );





  
};

export default MyForm;
