import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import {
  Stack,
  Checkbox,
  Container,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import HomeIcon from '@mui/icons-material/Home';
import './Clientvehicles.css';
import { LoadingButton } from '@mui/lab';
import USERLIST from '../_mock/user';
import { useGetMineQuery, useGetDailyVehiclesReportQuery } from '../services/api';
import Iconify from '../components/iconify';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const [minename, setMinename] = useState('');
  const isNotFound = !filteredUsers.length && !!filterName;
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const { data: mine } = useGetMineQuery();
  const mineName = useSelector((state) => state.filter).mine;
  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    refetch: refetchVehicles,
  } = useGetDailyVehiclesReportQuery({ mine: mineName }, { refetchOnFocus: true });
  console.log(vehicles, 'vehicles');
  const { data: mines, isLoading: mineDataLoading, isSuccess: mineDataSuccess } = useGetMineQuery();
  const minePoints = mines?.data?.filter((obj) => obj.mineName === mineName)[0];
  const filteredVehicles = vehicles?.data?.filter((vehicle) => {
    if (!vehicle.createdAt) {
      // Handle the case where createdAt is undefined or falsy
      return false;
    }

    const createdAtDate = new Date(vehicle.createdAt);

    // Check if the createdAtDate is within the specified date range
    return (!from || createdAtDate >= new Date(from)) && (!to || createdAtDate <= new Date(to));
  });

  const tableHeaders = [
    'From Date Time',
    'To Date Time',
    'Time in',
    'Mine Name',
    'Vehicle Number',
    'Vehicle Type',
    'Vehicle Model',
  ];

  const convertToCsv = (data) => {
    const csv = Papa.unparse(data, {
      header: true,
      skipEmptyLines: true,
    });
    return csv;
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // State to store random colors for main headers
  const [headerColors, setHeaderColors] = useState([]);
  useEffect(() => {
    // Generate random colors for main headers only if headerColors is empty
    if (headerColors.length === 0) {
      const mainHeaderColors = tableHeaders.map(() => getRandomColor());
      setHeaderColors(mainHeaderColors);
    }
  }, [headerColors, tableHeaders]);
  const HtmlTableVehicles = () => {
    const headers = minePoints?.points?.map((point) => point);
    const dataContent = [
      'token',
      'vehicleNumber',
      'vehicleType',
      'vehicleModel',
      'lengthInMeter',
      'lengthInCentimeter',
      'breadthInMeter',
      'breadthInCentimeter',
      'heightInMeter',
      'heightInCentimeter',
      'lengthInFoot',
      'lengthInInch',
      'breadthInFoot',
      'breadthInInch',
      'heightInFoot',
      'heightInInch',
      'volumeInCubicMeter',
      'volumeInCubicFoot',
    ];
    const tableHeaders = [
      'Token Number',
      'Vehicle Number',
      'Vehicle Type',
      'Vehicle Model',
      'Length In Meter',
      'Length In Centimeter',
      'Breadth In Meter',
      'Breadth In Centimeter',
      'Height In Meter',
      'Height In Centimeter',
      'Length in Foot',
      'Length in Inch',
      'Breadth in Foot',
      'Breadth in Inch',
      'Height in Foot',
      'Height in Inch',
      'Volume in Cubic Meter',
      'Volume in Cubic Centimeter',
    ];
    function convertTableToCSV() {
      // Extracting headers from thead
      // eslint-disable-next-line
      const headers = Array.from(document.querySelectorAll('thead th')).map((th) => th.innerText + '');

      // Extracting data from tbody
      const rows = Array.from(document.querySelectorAll('tbody tr')).map((tr) => {
        return Array.from(tr.querySelectorAll('td')).map((td) => td.innerText);
      });

      // Combining headers and rows
      const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv' });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'table_data.csv';

      // Append the link to the document and trigger a click event to start the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);
    }

    const exportToExcel = async () => {
      const htmlTable = document.getElementById('myTable');
      const workbook = new ExcelJS.Workbook();
      workbook.calcProperties.fullCalcOnLoad = true;
      const sheet = workbook.addWorksheet('Sheet1', {
        views: [{ showGridLines: true }],
        pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7, orientation: 'landscape' },
      });
      const headerRows = [];
      const vehicleDataRow = ['', 'Vehicle Data'];
      vehicleDataRow.push(...Array.from({ length: dataContent.length }, () => ' '));
      headers?.forEach((header, i) => {
        if (i < 3) return;
        vehicleDataRow.push(header.title);
        vehicleDataRow.push(
          ...Array.from({ length: i === 0 ? header.fields.length - 2 : header.fields.length - 1 }, () => ' ')
        ); // Add colspan spaces for dynamic headers
        // vehicleDataRow.push(header.title);
      });
      headerRows.push(vehicleDataRow);
      sheet.mergeCells('A1:C2');

      // Add image to cell A1
      const imageId = workbook.addImage({
        buffer: await fetch('/android.png').then((response) => response.arrayBuffer()),
        extension: 'png',
      });

      sheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 200, height: 100 },
      });

      sheet.mergeCells('B4:S4');
      sheet.getCell('B4').value = 'Vehicle Data';
      sheet.getCell('B4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('B4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('T4:W4');
      sheet.getCell('T4').value = 'Entry Point';
      sheet.getCell('T4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'F6927D' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('T4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('X4:AB4');
      sheet.getCell('X4').value = 'Token Point';
      sheet.getCell('X4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'F6CA7D' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('X4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AC4:AG4');
      sheet.getCell('AC4').value = 'Napati Point';
      sheet.getCell('AC4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'A1937B' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('AC4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AH4:AI4');
      sheet.getCell('AH4').value = 'Naka Point 1';
      sheet.getCell('AH4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: '45C4B9' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('AH4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AJ4:AK4');
      sheet.getCell('AJ4').value = 'Naka Point 2';
      sheet.getCell('AJ4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'ADD1CE' },
        bgColor: { argb: 'ADD1CE' },
      };
      sheet.getCell('AJ4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AL4:AM4');
      sheet.getCell('AL4').value = 'Naka Point 3';
      sheet.getCell('AL4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'AEBBDE' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('AL4').font = {
        bold: true,
        size: 16,
      };

      const tableData = Array.from(htmlTable.querySelectorAll('tbody tr')).map((row) =>
        Array.from(row.children).map((cell) => (cell.textContent === 'Check here to print' ? '' : cell.textContent))
      );
      tableData.forEach((row) => {
        sheet.addRow(row);
      });
      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), 'exported_data.xlsx');
    };

    return (
      <div>
        <div className="scrollable-table-container">
          <table id="myTable">
            <thead>
              <tr>
                <th>Select</th>
                <th colSpan={dataContent.length} style={{ backgroundColor: getRandomColor() }}>
                  Vehicle Data
                </th>
                {headers?.length > 0 &&
                  headers?.map((header, index) => (
                    <th key={index} colSpan={header?.fields?.length} style={{ backgroundColor: headerColors[index] }}>
                      {header?.title}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Click here to print</td>
                {tableHeaders?.map((table, tableIndex) => (
                  <td key={tableIndex}>{table}</td>
                ))}
                {headers?.map((point, pointIndex) =>
                  point?.fields?.map((field, fieldIndex) => (
                    <td key={fieldIndex}>{field?.name === 'vehicle data' ? null : field?.name}</td>
                  ))
                )}
              </tr>
              {vehicles?.data?.map((vehicle, index) => (
                <tr key={index}>
                  <td>
                    <Grid container>
                      <FormControlLabel control={<Checkbox />} />
                    </Grid>
                  </td>
                  {dataContent.map((header, headerIndex) => (
                    <td key={headerIndex}>{vehicle?.vehicleData?.[header]}</td>
                  ))}
                  {vehicle?.points?.map((point, pointIndex) =>
                    point?.fields?.map((field, fieldIndex) => <td key={fieldIndex}>{field?.value}</td>)
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/allVehicleReport?mineName=${minename}`, {
        credentials: 'include',
      });
      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader?.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename?.toString());
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
  };

  const handleDailyDownload = async (start, end, setLoading) => {
    setLoading(true);
    try {
      const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/allVehicleDailyReport?mineName=${minename}`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename?.toString());
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
  };

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const processDate = (date) => {
    const oldFormat = new Date(date);
    return oldFormat;
  };

  const handleDatedDownload = async (start, end, setLoading) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://backend.dgmining.in/api/v1/vehicles/allVehicleReport?startTime=${processDate(
          start
        )}&endTime=${processDate(end)}&mineName=${minename}`,
        { credentials: 'include' }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename?.toString());
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoading(false);
    }
};


  return (
    <>
      <Helmet>
        <title>Client Vehicles </title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Client Vehicles
        </h2>
      </div>

      <Container>
        <Box className="bg">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Client Vehicles
          </Typography>
        </Box>
        <br />
        <FormControl fullWidth style={{ marginBottom: '2%' }}>
          <InputLabel id="demo-simple-select-label">Select Mine</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={minename}
            label="Select Mine"
            onChange={(e) => setMinename(e.target.value)}
          >
            <MenuItem value="">All Mines</MenuItem>
            {mine?.data.map((_mine, index) => (
              <MenuItem value={_mine.mineName}>{_mine.mineName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          <input
            type="date"
            id="meeting-time"
            name="from"
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
          <input
            type="date"
            id="meeting-time"
            name="to"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />

          <LoadingButton
            onClick={() => {
              if (!start && !end) {
                handleDownload(setLoading);
              } else if (start && end) {
                handleDatedDownload(start, end, setLoading);
              }
            }}
            loading={loading}
            variant="contained"
            startIcon={<Iconify icon="carbon:report" />}
          >
            {!start && !end ? 'Download All Vehicles Report' : 'Download Report'}
          </LoadingButton>

          <LoadingButton
            onClick={() => handleDailyDownload(start, end, setLoading)}
            loading={loading}
            variant="contained"
            startIcon={<Iconify icon="carbon:report" />}
          >
            Download 24H Report
          </LoadingButton>
        </Stack>
        {/* <HtmlTableVehicles /> */}
      </Container>
    </>
  );
}
