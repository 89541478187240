import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { faker } from '@faker-js/faker';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import './Form1.css';

import {
  Grid,
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
} from '@mui/material';
import { AppOrderTimeline } from '../sections/@dashboard/app';
// @mui

// components
const Vendoradd = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={8}>
          <Typography variant="h4">Vendor</Typography>
          <Box m={2}>
            <Accordion expanded={expand === 'one'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'one')}>
              <AccordionSummary className="mine-acodi" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
                  Company/Firm Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Company/Firm Name"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Opening Balance"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Credit/Debit"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField id="input-with-sx" label="State" variant="outlined" style={{ width: '40ch' }} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField id="input-with-sx" label="Area" variant="outlined" style={{ width: '40ch' }} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Company/Firm Address"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Company/Firm Owner Name"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Company/Firm GST No."
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Company/Firm Status"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField id="input-with-sx" label="City" variant="outlined" style={{ width: '40ch' }} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField id="input-with-sx" label="Pin Code" variant="outlined" style={{ width: '40ch' }} />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expand === 'two'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'two')}>
              <AccordionSummary className="mine-acodi1" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                  Personal Details{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Vendor Name"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField id="input-with-sx" label="Mobile" variant="outlined" style={{ width: '40ch' }} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField id="input-with-sx" label="Gender" variant="outlined" style={{ width: '40ch' }} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Profile Image"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            type="email"
                            id="input-with-sx"
                            label="Email"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Designation"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            type="number"
                            label="Date of Birth"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}

            <Accordion expanded={expand === 'three'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'three')}>
              <AccordionSummary className="mine-acodi3" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
                  Bank Account Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Bank Name"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Account Number"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Branch Name & Address"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Account Holder Name"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="IFSC Code"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Button style={{ marginTop: '1vmax' }} variant="contained" endIcon={<AddIcon />}>
              Add
            </Button>

            {/* asdfghjl;keufgroiujhoy */}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ display: 'flex', flexDirection: 'column', gap: '1vmax' }}>
          <Box className="QR">
            <img src="/assets/new img/QR.png" alt="" />
          </Box>
          <Box>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Vendoradd;
