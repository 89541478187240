import { Helmet } from 'react-helmet-async';
// @mui
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Link, Container, Box, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';

// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import './Form1.css';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const user = useSelector(state => state.user);

  if(user.data) {
    return (<Navigate to={'/dashboard'} replace />)
  }

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm" className="main-cont">
          <StyledContent>
            <div className="main-conttt">
              <div style={{ textAlign: 'center' }}>
                {/* <Box className="logo-login">
                  <img src="/assets/new img/minning.jpg" alt="" />
                </Box> */}
                <h3 className="patidar">
                  {' '}
                  <span> "Digging Excellence,</span> <span>Delivering</span>{' '}
                  <span className="login-color">Resources."</span>
                </h3>
                {/* <Logo /> */}
                {/* <img   style={{width:'100px'}}  src="/assets/new img/minning.jpg" alt="" /> */}

                {/* <Typography variant="h4" gutterBottom>
                  Sign in to Minimal
                </Typography> */}

                {/* <Typography variant="body2" sx={{ mb: 5 }}>
                  Don’t have an account? {''}
                  <Link variant="subtitle2">Get started</Link>
                </Typography> */}
              </div>
              <LoginForm />
            </div>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
