import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import { useSaveStateMutation, useGetStatesQuery, useDeleteStateMutation, useEditStateMutation } from '../../services/api';
import Success from '../../components/success/Success';
import Error from '../../components/error/Error';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};
const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'name', label: 'State', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.stateName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const { data: states } = useGetStatesQuery();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('stateName');
  const [filterName, setFilterName] = useState('');
  const [editOpen, setEditOpen] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [id, setId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [deleteState, { isSuccess: isDeleteSuccess, isError: isDeleteError, reset }] = useDeleteStateMutation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editedState, setEditedState] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [saveState, { error, isLoading, isSuccess }] = useSaveStateMutation();
  const [EditState, { error: editError, isLoading: editLoading, isSuccess: editSuccess }] = useEditStateMutation();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEditOpen = (id) => {
    const selectedType = states?.data?.find((type) => type._id === id);
    setSelectedState(selectedType);
    setEditedState(selectedType.stateName || '');
    setId(id);
    setEditModalOpen(true);
  };
  
  const handleEditClose = () => {
    setEditModalOpen(false);
  };
  const handleFormSubmit = (data) => {
    setFormData(data);
  };
  const handleEditFormSubmit = async (id) => {
    await EditState({ id, stateName: editedState });
    handleEditClose();
  };

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
  };
  const handleClose = () => {
    setEditOpen(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteState(id).unwrap();
      handleClose();
    } catch (error) {
      console.log('Failed To Delete:', error);
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('State Deleted successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // Clear success message after 5 seconds
    }

    if (isDeleteError) {
      setErrorMessage('Failed to delete. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000); // Clear error message after 5 seconds
    }

    // Reset mutation status to handle subsequent operations
    return () => reset();
  }, [isDeleteSuccess, isDeleteError, reset]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = states?.data?.map((n) => n.stateName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const handleStateSave = async () => {
    await saveState({ stateName: state });
    closeModal();
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - states?.data?.length) : 0;

  const filteredUsers = applySortFilter(states?.data || [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const [state, setState] = useState('')
  return (
    <>
      <Container>
        {isSuccess && <Success isSuccess={isSuccess} successMessage={'State saved successfully'} />}
        {error && <Error error={error} />}
        {successMessage && <Success successMessage={successMessage} />}
        {errorMessage && <Error error={errorMessage} />}
        <Box className="bg">
          <Helmet>
            <title> State </title>
          </Helmet>
          <Modal
            open={editOpen}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style1}>
              <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
              <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
                Are you sure you want to delete this Entry?
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 30,
                }}
              >
                <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClose}>
                  CANCEL
                </Button>
                <Button variant="contained" onClick={handleDeleteConfirmation}>
                  Yes
                </Button>
              </div>
            </Box>
          </Modal>
          <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
            {' '}
            <HomeIcon color="primary" />{' '}
            <h2>
              <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
                Masters
              </Link>{' '}
              / Demographic / State
            </h2>
          </div>
          <Typography variant="h4" sx={{ mb: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            State
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={3}>
              <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#4286f472', color: 'black' }}>
                  <h3>State</h3>
                  <Iconify icon={'zondicons:location'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link
                      style={{ backgroundColor: '#4286f472', color: 'black', fontWeight: '800' }}
                      to="/dashboard/State"
                    >
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#ffbb0084', color: 'black' }}>
                  <h3>District</h3>
                  <Iconify icon={'zondicons:location'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link
                      style={{ backgroundColor: '#ffbb0084', color: 'black', fontWeight: '800' }}
                      to="/dashboard/District"
                    >
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#ea443571', color: 'black' }}>
                  <h3>City</h3>
                  <Iconify icon={'zondicons:location'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link
                      style={{ backgroundColor: '#ea443571', color: 'black', fontWeight: '800' }}
                      to="/dashboard/City"
                    >
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#34a853ae', color: 'black' }}>
                  <h3>Area</h3>
                  <Iconify icon={'zondicons:location'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link
                      style={{ backgroundColor: '#34a853ae', color: 'black', fontWeight: '800' }}
                      to="/dashboard/Area"
                    >
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{marginTop:"2vmax"}}>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>{states?.data?.length}</Box>
                <br />
                <Box>Total State</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>{states?.data?.length}</Box>
                <br />
                <Box>Active State</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>{states?.data?.length}</Box>
                <br />
                <Box>Inactive State</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>3</Box>
                <br />
                <Box>Today State</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={openModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add State
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={states?.data?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { _id, stateName } = row;
                    const selectedUser = selected.indexOf(stateName) !== -1;
                    const rowNum = page * rowsPerPage + index + 1;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, stateName)} />
                        </TableCell>

                        <TableCell>
                          <Button variant="contained" onClick={() => handleEditOpen(_id)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            style={{ backgroundColor: 'red' }}
                            onClick={() => handleOpenMenu(_id)}
                          >
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                            Delete
                          </Button>
                        </TableCell>

                        <TableCell align="left">{stateName}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={states?.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={closeModal} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            States
          </Typography>

          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid item>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label="  States "
                      variant="outlined"
                      style={{ width: '30ch' }}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Button onClick={handleStateSave} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={isEditModalOpen}
        onClose={handleEditClose}
        onSubmit={handleEditFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleEditClose} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit State
          </Typography>

          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid item>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label="  States"
                      value={editedState}
                      variant="outlined"
                      style={{ width: '30ch' }}
                      onChange={(e) => setEditedState(e.target.value)}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Button onClick={() => handleEditFormSubmit(id)} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
