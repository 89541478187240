// import axios from 'axios';
// import React, { useState } from 'react'

// const DiscountedVehiclesReport = () => {

//     const [loading, setLoading] = useState(false);

//     const handleDownload = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch('http://localhost:3001/api/v1/vehicles/discountedVehicleReport', { credentials: 'include' });
//             const filenameHeader = response.headers.get('content-disposition');
//             const filename = filenameHeader.split('filename=')[1].replace(/"/g,  '');
//             const blob = await response.blob();
//             const url = window.URL.createObjectURL(new Blob([blob]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', filename.toString());
//             document.body.appendChild(link);
//             link.click();
//             setLoading(false);
//         } catch (error) {
//             console.error('Error downloading file:', error);
//             setLoading(false);
//         }
//     };

//     return (
//         <button onClick={handleDownload}>download report</button>
//     )
// }

// export default DiscountedVehiclesReport

import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import {
    Table,
    Stack,
    Paper,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Box,
    Grid,
    TableHead,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

// components1
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import './Clientvehicles.css';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import VendorPage from './VendorPage';
import Label from '../components/label';
import TablePage from './TablePage';
import { useGetDiscountedVehicleDailyQuery, useGetMineQuery, useGetVehiclesReportQuery } from '../services/api';
import Iconify from '../components/iconify';
import Error from '../components/error/Error';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [minename, setMinename] = useState('');
    const { data: discountedVehicleList } = useGetDiscountedVehicleDailyQuery({mineName: minename});
    const mineName = useSelector((state) => state.filter).mine;
    const {
        data: vehicles,
        isLoading: vehiclesLoading,
        refetch: refetchVehicles,
    } = useGetVehiclesReportQuery({ mine: mineName }, { refetchOnFocus: true });
    console.log(vehicles, 'vehicles')
    const { data: mines, isLoading: mineDataLoading, isSuccess: mineDataSuccess } = useGetMineQuery();
    const minePoints = mines?.data?.filter((obj) => obj.mineName === mineName)[0];

    const tableHeaders = [
        'From Date Time',
        'To Date Time',
        'Time in',
        'Mine Name',
        'Vehicle Number',
        'Vehicle Type',
        'Vehicle Model',
    ];


    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i += 1) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // State to store random colors for main headers
    const [headerColors, setHeaderColors] = useState([]);
    useEffect(() => {
        // Generate random colors for main headers only if headerColors is empty
        if (headerColors.length === 0) {
            const mainHeaderColors = tableHeaders.map(() => getRandomColor());
            setHeaderColors(mainHeaderColors);
        }
    }, [headerColors, tableHeaders]);

    const HtmlTableVehicles = () => {
        const headers = minePoints?.points?.map((point) => point);

        const dataContent = [
            'token',
            'vehicleNumber',
            'vehicleType',
            'vehicleModel',
            'lengthInMeter',
            'lengthInCentimeter',
            'breadthInMeter',
            'breadthInCentimeter',
            'heightInMeter',
            'heightInCentimeter',
            'lengthInFoot',
            'lengthInInch',
            'breadthInFoot',
            'breadthInInch',
            'heightInFoot',
            'heightInInch',
            'volumeInCubicMeter',
            'volumeInCubicFoot',
            'nakaRequired',
            'serialNumber'
        ];

        const tableHeaders = [
            'Token Number',
            'Vehicle Number',
            'Vehicle Type',
            'Vehicle Model',
            'Length In Meter',
            'Length In Centimeter',
            'Breadth In Meter',
            'Breadth In Centimeter',
            'Height In Meter',
            'Height In Centimeter',
            'Length in Foot',
            'Length in Inch',
            'Breadth in Foot',
            'Breadth in Inch',
            'Height in Foot',
            'Height in Inch',
            'Volume in Cubic Meter',
            'Volume in Cubic Centimeter',
            'Naka Required',
            'Serial Number'
        ];

        return (
            <div>
                <div className="scrollable-table-container">
                    <table id="myTable">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th colSpan={dataContent.length} style={{ backgroundColor: getRandomColor() }}>
                                    Vehicle Data
                                </th>
                                {headers?.length > 0 &&
                                    headers?.map((header, index) => (
                                        <th key={index} colSpan={header?.fields?.length} style={{ backgroundColor: headerColors[index] }}>
                                            {header?.title}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Click here to print</td>
                                {tableHeaders?.map((table, tableIndex) => (
                                    <td key={tableIndex}>{table}</td>
                                ))}
                                {headers?.map((point, pointIndex) =>
                                    point?.fields?.map((field, fieldIndex) => (
                                        <td key={fieldIndex}>{field?.name === 'vehicle data' ? null : field?.name}</td>
                                    ))
                                )}
                            </tr>
                            {discountedVehicleList?.data?.map((vehicle, index) => (
                                <tr key={index}>
                                    <td>
                                        <Grid container>
                                            <FormControlLabel control={<Checkbox />} />
                                        </Grid>
                                    </td>
                                    {dataContent.map((header, headerIndex) => (
                                        <td key={headerIndex}>{vehicle?.[header]}</td>
                                    ))}
                                    {vehicle?.points?.map((point, pointIndex) =>
                                        point?.fields?.map((field, fieldIndex) => <td key={fieldIndex}>{field?.value}</td>)
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ isError: false, message: '' });
    const {data: mine} = useGetMineQuery();
   

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/discountedVehicleReport?mineName=${minename}`, { credentials: 'include' });
            const filenameHeader = response.headers.get('content-disposition');
            const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename.toString());
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            console.error('Error downloading file:', error);
            setLoading(false);
        }
    };

    const handleDailyDownload = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/discountedVehicleDailyReport?mineName=${minename}`, { credentials: 'include' });
            const filenameHeader = response.headers.get('content-disposition');
            const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename.toString());
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            console.error('Error downloading file:', error);
            setLoading(false);
        }
    };

    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    

    const processDate = (date) => {
        const oldFormat = new Date(date)
        return oldFormat;
    }

    const handleDatedDownload = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://backend.dgmining.in/api/v1/vehicles/discountedVehicleReport?startTime=${processDate(start)}&endTime=${processDate(end)}&mineName=${minename}`, { credentials: 'include' });
            const filenameHeader = response.headers.get('content-disposition');
            const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename.toString());
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            console.error('Error downloading file:', error);
            setLoading(false);
        }
    };


    return (
        <>
            <Helmet>
                <title>Client Vehicles </title>
            </Helmet>
            <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
                {' '}
                <HomeIcon color="primary" />{' '}
                <h2>
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
                        Dashboard
                    </Link>{' '}
                    / Client Vehicles
                </h2>
            </div>

            <Container>
                <Box className="bg">
                    <Typography variant="h4" sx={{ mb: 5 }}>
                        Discounted Vehicles Report
                    </Typography>
                </Box>
                <br />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Mine</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={minename}
                        label="Select Mine"
                        onChange={(e) => setMinename(e.target.value)}
                    >
                        {mine?.data.map((_mine, index) => (
                            <MenuItem value={_mine.mineName}>{_mine.mineName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={10}>
                    <Typography variant="h4" gutterBottom />
                    <input
                        type="date"
                        id="meeting-time"
                        name="from"
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                    />
                    <input type="date"
                        id="meeting-time"
                        name="to"
                        value={end}
                        onChange={(e) => setEnd(e.target.value)} />

                    <LoadingButton onClick={start === undefined && end === undefined ? handleDownload : start === undefined || end === undefined ? <></> : handleDatedDownload} loading={loading} variant='contained' startIcon={<Iconify icon="carbon:report" />}>
                        {start === undefined && end === undefined ? `Download All Discounted Vehicles Report` : `Download Report`}
                    </LoadingButton>

                    <LoadingButton onClick={handleDailyDownload} loading={loading} variant='contained' startIcon={<Iconify icon="carbon:report" />}>
                        Download 24h Report
                    </LoadingButton>
                </Stack>

                {/* <HtmlTableVehicles /> */}
            </Container>
        </>
    );
}
