



import React, { Component } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

class PhotoUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: null,
    };
  }

  handleCaptureClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const mediaStreamTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(mediaStreamTrack);
      const blob = await imageCapture.takePhoto();

      this.setState({
        photoUrl: URL.createObjectURL(blob),
      });

      stream.getVideoTracks()[0].stop(); // Stop the camera stream
    } catch (error) {
      console.error('Error capturing photo:', error);
    }
  };

  render() {
    return (
      <div>
        <button onClick={this.handleCaptureClick}  > {<CameraAltIcon />}</button>
        
        {this.state.photoUrl && (
          <img
            src={this.state.photoUrl}
            alt="Captured "
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
        )}
      </div>
    );
  }
}

export default PhotoUploadForm;
