import { Helmet } from 'react-helmet-async';
import { filter, set } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/system';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLISTDEPART from '../_mock/department';
// import VendorPage from '../VendorPage';
import { useSaveDepartmentMutation, useGetDepartmentQuery, useDeleteDepartmentMutation, useEditDepartmentMutation } from '../services/api';
import Error from '../components/error/Error';
import Success from '../components/success/Success';

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};
const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));
const TABLE_HEADDEPRT = [
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Parent Department Name ', label: 'Parent Department Name ', alignRight: false },
  { id: 'Department Name ', label: 'Department Name ', alignRight: false },

  { id: 'Description', label: 'Description', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { data: departments } = useGetDepartmentQuery();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
    console.log('Form data submitted:', data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLISTDEPART.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLISTDEPART.length) : 0;

  const filteredUsers = applySortFilter(USERLISTDEPART, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [saveDepartment, { isSuccess, isLoading, error }] = useSaveDepartmentMutation();
  const [editDepartment, { isSuccess: isEditSuccess, isLoading: isEditLoading, error: editError }] = useEditDepartmentMutation();
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const [id, setId] = useState(null);
  const [department, setDepartment] = useState(null);
  const [parentDepartmentAdd, setParentDepartmentAdd] = useState(null);
  const [desc, setDesc] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [editedDescription, setEditedDescription] = useState(null);
  const [editedParentDepartment, setEditedParentDepartment] = useState(null);
  const [editedDepartmentName, setEditedDepartmentName] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => {
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteDepartment(id).unwrap(); // Ensure the promise is fulfilled with unwrap
      setDeleteSuccessMessage('Department deleted successfully.');
      handleDeleteClose(); // Close the delete confirmation modal
      // Optionally: Refetch the departments list or remove the deleted department from the local state to update the UI
    } catch (error) {
      console.error('Failed to delete department:', error);
      // Handle deletion error (optional)
    }
  };

  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
    setDeleteSuccessMessage(''); // Clear previous success message when opening the modal
  };

  const handleDepartmentSave = async () => {
    await saveDepartment({ departmentName: department, description: desc, parentDepartment: parentDepartmentAdd });
    closeModal();
  };
  useEffect(() => {
    if (deleteSuccessMessage) {
      const timer = setTimeout(() => setDeleteSuccessMessage(''), 5000); // Hide message after 5 seconds
      return () => {
        clearTimeout(timer);
      };
    }
    // Explicitly return undefined to silence any linting warnings about expected return value
    return undefined;
  }, [deleteSuccessMessage]);
  
  const handleEditDepartmentDown = (id) => {
    const selectedDepartment = departments?.data.find((department) => department._id === id);
    setSelectedDepartment(selectedDepartment);
    setEditedDescription(selectedDepartment.description || '');
    setEditedParentDepartment(selectedDepartment.parentDepartment || '');
    setEditedDepartmentName(selectedDepartment.departmentName || '');
    setId(id);
    setEditModalOpen(true);
  }

  const handleEditDepartment = async (id) => { 
    await editDepartment({ id, departmentName: editedDepartmentName, parentDepartment: editedParentDepartment , description: editedDescription});
    console.log(id,"id")
    console.log(editedDepartmentName,"editedDepartmentName")
    console.log(editedParentDepartment,"editedParentDepartment")
    console.log(editedDescription,"editedDescription")
    closeEditModal();
  }

  return (
    <>
          <Modal
        open={isEditModalOpen}
        onClose={closeEditModal}
        onSubmit={handleFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={closeEditModal} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit Department
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Parent Department</InputLabel>
                  <Select
                    onChange={(e) => setEditedParentDepartment(e.target.value)}
                    id="input-with-sx"
                    value={editedParentDepartment}
                    label="Parent Department Name"
                    variant="outlined"
                  >
                    {departments?.data.map((department, index) => (
                      <MenuItem key={index} value={department.departmentName}>
                        {department.departmentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={editedDepartmentName}
                  onChange={(e) => setEditedDepartmentName(e.target.value)}
                  label="Department Name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField onChange={(e) => setEditedDescription(e.target.value)} label="Description" variant="outlined" fullWidth value={editedDescription} />
              </Grid>
            </Grid>
            <Button onClick={()=> handleEditDepartment(id)} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Helmet>
        <title>Department</title>
      </Helmet>
      {deleteSuccessMessage && <Success successMessage={deleteSuccessMessage} />}
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleDeleteClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleDeleteClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Masters
          </Link>{' '}
          / Department
        </h2>
      </div>
      <Container>
        {error && <Error error={error} />}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={'Department saved successfully'} />}
        <Box className="bg">
          <Helmet>
            <title> Department </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            Department
            {/* <div  style={{fontSize:'10px'}}> <HomeIcon color="primary" /> Dashboard/vendorPage</div> */}
          </Typography>

          {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={3}> */}
          {/* <Item> */}
          {/* <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#4286f472', color: 'black' }}>
                  <h3>Account Department</h3>
                  <Iconify icon={'material-symbols:work'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link style={{ backgroundColor: '#4286f472', color: 'black', fontWeight: '800' }} to="">
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div> */}
          {/* </Item> */}
          {/* </Grid>
            <Grid item xs={3}> */}
          {/* <Item> */}
          {/* <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#ffbb0084', color: 'black' }}>
                  <h3>Sales Department</h3>
                  <Iconify icon={'material-symbols:work'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link style={{ backgroundColor: '#ffbb0084', color: 'black', fontWeight: '800' }} to="">
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div> */}
          {/* </Item> */}
          {/* </Grid>
            <Grid item xs={3}> */}
          {/* <Item> */}
          {/* <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#ea443571', color: 'black' }}>
                  <h3>Marketing Department</h3>
                  <Iconify icon={'material-symbols:work'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link style={{ backgroundColor: '#ea443571', color: 'black', fontWeight: '800' }} to="">
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div> */}
          {/* </Item> */}
          {/* </Grid>
            <Grid item xs={3}> */}
          {/* <Item> */}
          {/* <div className="boxx">
                <div className="box-top" style={{ backgroundColor: '#34a853ae', color: 'black' }}>
                  <h3>HR Depatment</h3>
                  <Iconify icon={'material-symbols:work'} style={{ width: '35px', height: '28px' }} />
                </div>
                <div className="box-bottom">
                  <div>
                    <Link style={{ backgroundColor: '#34a853ae', color: 'black', fontWeight: '800' }} to="">
                      Click Here To View
                    </Link>
                    <Iconify icon={'tabler:live-view'} style={{ width: '28px', height: '28px' }} />
                  </div>
                </div>
              </div> */}
          {/* </Item> */}
          {/* </Grid>
          </Grid> */}

          <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
            {/* <Grid xs={12} xsOffset={0} md={0} mdOffset={4}>
              <Item style={{ textAlign: 'left', border: '0px solid  grey', color: 'black' }}>Department Summary</Item>
            </Grid> */}
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>0</Box>
                <br />
                <Box>Total Department</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>3</Box>
                <br />

                <Box>Active Department</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>3</Box>
                <br />
                <Box>Inactive Department</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>3</Box>
                <br />
                <Box>Today Department</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={openModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Department
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={departments?.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEADDEPRT}
                  rowCount={USERLISTDEPART.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {departments?.data?.map((row, index) => {
                    const { id, name } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    const rowNum = page * rowsPerPage + index + 1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>
                        <TableCell>
                          <Button variant="contained" onClick={()=> handleEditDepartmentDown(row._id)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </Button>
                          <Button sx={{ color: 'error.main' }} onClick={() => handleDeleteOpen(row._id)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </Button>
                        </TableCell>
                        <TableCell align="left">{row?.parentDepartment}</TableCell>
                        <TableCell align="left">{row?.departmentName}</TableCell>

                        <TableCell align="left">{row?.description}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={closeModal} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Department
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Parent Department</InputLabel>
                  <Select
                    onChange={(e) => setParentDepartmentAdd(e.target.value)}
                    id="input-with-sx"
                    label="Parent Department Name"
                    variant="outlined"
                  >
                    {departments?.data.map((department, index) => (
                      <MenuItem key={index} value={department.departmentName}>
                        {department.departmentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setDepartment(e.target.value)}
                  label="Department Name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField onChange={(e) => setDesc(e.target.value)} label="Description" variant="outlined" fullWidth />
              </Grid>
            </Grid>
            <Button onClick={handleDepartmentSave} variant="contained" style={{ margin: '20px' }}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
