import React from 'react';
import { Helmet } from 'react-helmet-async';
import SaveIcon from '@mui/icons-material/Save';
import { Link } from 'react-router-dom';
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  OutlinedInput,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import { styled, useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Iconify from '../../components/iconify';
import TablePage from '../TablePage';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function UserPage() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    right: '10%',
    top: '5%',
  };

  const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid ',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '0px',
    textAlign: 'center',
  }));

  const [open, setOpen] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <>
      <Helmet>
        <title>All Resumes</title>
      </Helmet>
      <div style={{fontSize:'10px',display:"flex",alignItems:"center", gap:".5vmax",}}> <HomeIcon color="primary" /> <h2><Link to="/dashboard" style={{textDecoration:"none", color:"black"}}>Dashboard</Link> / HR Department / All Resumes</h2></div>

      <Container>
        <Box className="bg">
          <Helmet>
            <title> All Resumes </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }}>
            All Resumes
            {/* <div  style={{fontSize:'10px'}}> <HomeIcon color="primary" /> Dashboard/vendorPage</div> */}
          </Typography>

          <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
            <Grid xs={12} xsOffset={0} md={0} mdOffset={4}>
              <Item style={{ textAlign: 'left', border: '0px solid  grey', color: 'black' }}>All Resumes Summary</Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>0</Box>
                <br />
                <Box>Total Resumes</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>3</Box>
                <br />

                <Box>Active Resumes</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>3</Box>
                <br />
                <Box>Inactive Resumes</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>3</Box>
                <br />
                <Box>Today Resumes</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            {/* <Link to="/dashboard/mines" style={{ color: 'white', textDecoration: 'none' }}> */}
            Add Resumes
            {/* </Link> */}
          </Button>
          {/* <Button ></Button> */}
        </Stack>

        <TablePage />
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Create Employee Role
          </Typography>

          <Typography
            id="keep-mounted-modal-description"
            sx={{ mt: 2 }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Stack direction={'column'} spacing={3}>
                 <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label=" Employee Role Name"
                       variant="outlined"
                      style={{ width: '40ch' }}
                    />
                  </Box>
                  {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <textarea
                      placeholder="Description"
                      name=""
                      id=""
                      cols="50"
                      rows="10"
                      style={{ outline: 'none', padding: '1vmax' }}
                    />
                  </Box> */}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-name-label">Select Employee Role</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Button variant="contained" endIcon={<SaveIcon />}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
