import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';


// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,FormControl,InputLabel,Select

} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
// components
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
// mock 
import Minetype12 from '../../../_mock/minetype';
import { useSaveMineTypeMutation } from '../../../services/api';
import Label from '../../../components/label';
import Success from '../../../components/success/Success';
import Error from '../../../components/error/Error';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Mine Name ', label: 'Mine Name ', alignRight: false },
  { id: 'Decription ', label: 'Decription ', alignRight: false },

  // { id: 'isVerified', label: 'Verified', alignRight: false },
];

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));


const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };





  // modal state

  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});

  // modal state

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
    console.log('Form data submitted:', data);
  };

  // modal state end

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Minetype12.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const [saveMineType, {error, isLoading, isSuccess}] = useSaveMineTypeMutation();
  const [mineDescription, setMineDescription] = useState(null);
  const [mineType, setMineType] = useState(null);

  const handleMineTypeSave = async () => {
    await saveMineType({mineName: mineType, description: mineDescription });
    closeModal();
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Minetype12.length) : 0;

  const filteredUsers = applySortFilter(Minetype12, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>MineType</title>
      </Helmet>

      <Container>
        {isSuccess && <Success isSuccess={isSuccess} successMessage={"Saved successfully"}/>}
        {error && <Error error={error}/>}
        <Box className="bg">
          <Helmet>
            <title> Mine Type </title>
          </Helmet>
          <div style={{fontSize:'10px',display:"flex",alignItems:"center", gap:".5vmax",}}> <HomeIcon color="primary" /> <h2><Link to="/dashboard/MineType" style={{textDecoration:"none", color:"black"}}>Masters</Link> / MineType</h2></div>
          <Typography variant="h4" sx={{ mb: 5 }} style={{display:"flex",alignItems:"center"}}>MineType
            {/* <div  style={{fontSize:'10px'}}> <HomeIcon color="primary" /> Dashboard/vendorPage</div> */}
          </Typography>



          {/* <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{marginTop:"2vmax"}}>
            <Grid xs={12} xsOffset={0} md={0} mdOffset={4}>
              <Item style={{ textAlign: 'left', border: '0px solid  grey', color: 'black' }}>MineType Summary</Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>0</Box>
                <br />
                <Box>Total MineType</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>3</Box>
                <br />

                <Box>Active MineType</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>3</Box>
                <br />
                <Box>Inactive MineType</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>3</Box>
                <br />
                <Box>Today MineType</Box>
              </Item>
            </Grid>
          </Grid> */}
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button  onClick={openModal} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            {/* <Link to="/dashboard/users" style={{ color: 'white', textDecoration:'none' }}> */}
              Add MineType
            {/* </Link> */}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={Minetype12.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, date, status, mobile, avatarUrl, isVerified,Minename,Description } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>
                        <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell>
                          <MenuItem>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>

                          <MenuItem sx={{ color: 'error.main' }}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </MenuItem>
                        </TableCell>


                        <TableCell align="left">{Minename}</TableCell>
                        <TableCell align="left">{Description}</TableCell>

                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Minetype12.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>


      <Modal
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={closeModal} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
          Mine Type 
          </Typography>

          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }} >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <TextField label="Mine Type" variant="outlined" fullWidth 
                value={mineType}
                required
                onChange={(e) => setMineType(e.target.value)}
                />

                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField 
                value={mineDescription}
                required
                onChange={(e) => setMineDescription(e.target.value)} label=" Decription   " variant="outlined" fullWidth />
                </Grid>    
              </Grid>
              <Button onClick={handleMineTypeSave} variant="contained" type='submit' style={{ margin: '20px' }}>
                Save
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}