import React from 'react';
import { Grid, TextField, Button,Typography } from '@mui/material';




const MyFormq = () => {
    return (
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="First Name" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Last Name1" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Last Name2" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Last Name3" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Last Name4" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Email" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type='submit' color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
     
      </form>
    );
  };
  
  export default MyFormq;
  