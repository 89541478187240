import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import './Form1.css';
import { faker } from '@faker-js/faker';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import './Forms.css';
import Iconify from '../components/iconify';
import { AppOrderTimeline } from '../sections/@dashboard/app';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function TimeLineModal({ open, handleClose, vehicle }) {
  const [vehicleTimeline, setVehicleTimeline] = useState([]);
  useEffect(() => {
    if (vehicle?.points.length > 0) {
      const vehicleTimeline = vehicle?.points?.map((point, index) => {
        const {
          _id,
          position,
          createdAt,
          title,
          updatedBy,
        } = point;
        return {
          id: _id,
          title: [title],
          type: `order${position + 1}`,
          time: createdAt,
          user: updatedBy?.name,
        };
      });
      setVehicleTimeline(vehicleTimeline.reverse());
    }
  }, [vehicle])

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style1}>
            <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
              TimeLine
            </Typography>
            <Typography
              id="keep-mounted-modal-description"
              sx={{ mt: 2 }}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
            >
              <Box>
                <AppOrderTimeline
                  title={`Order Timeline ${vehicle?.vehicleData?.vehicleNumber} (${vehicle?.vehicleData?.vehicleModel})`}
                  list={vehicleTimeline}
                />
              </Box>
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default TimeLineModal;
