import { Helmet } from 'react-helmet-async';
// @mui
import { useEffect, useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { Grid, Typography, Box, TextField, Stack, InputAdornment, IconButton } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Navigate, useNavigate } from 'react-router-dom';
import '../styles/global.css';
import Iconify from '../components/iconify/Iconify';
import Success from '../components/success/Success';
import { useSaveUserMutation, useGetStatesQuery, useGetCitiesQuery, useGetDistrictsQuery } from '../services/api';
import Error from '../components/error/Error';
// components
const Adminform = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({});
  const [saveUser, { error, isLoading, isSuccess }] = useSaveUserMutation();
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
  const { data: states, isLoading: isStatesLoading, isSuccess: isStatesSuccess, isError: isStatesError, error: statesError } = useGetStatesQuery();
  const { data: cities, isLoading: isCitiesLoading, isSuccess: isCitiesSuccess, isError: isCitiesError, error: citiesError } = useGetCitiesQuery();
  const { data: districts, isLoading: isdistrictsLoading, isSuccess: isdistrictsSuccess, isError: isdistrictsError, error: districtsError } = useGetDistrictsQuery();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [mineData, setMineData] = useState({});
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };

  const handleChange = (e, field) => {
    if (field === 'phone' && e.target.value.length !== 10) {
      return setMobileError('Mobile number should be 10 digits.');
    }
    setUserData({ ...userData, [field]: e.target.value, role: "admin" })
    setMineData({ ...mineData, [field]: e.target.value });

    return [setMobileError(false), setIsSaveDisabled(false)];
  }

  const handleSaveUser = async () => {
    saveUser(userData);
  }

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/user');
      }, 800);
    }
  }, [isSuccess])

  return (
    <>

      <h2>User</h2>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField id="input-with-sx" onChange={(e) => handleChange(e, 'name')} required label=" Full Name" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='form-input'
              name="password"
              onChange={(e) => handleChange(e, 'password')}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField  label="Email " onChange={(e) => handleChange(e, 'email')} type='email' required id='form-input' variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required label="Mobile Number" onChange={(e) => handleChange(e, 'phone')} type='number' inputProps={{ pattern: '[0-9]{10}' }} error={!!mobileError} helperText={mobileError} variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required label="Company name " onChange={(e) => handleChange(e, 'companyName')} variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>State*</InputLabel>
                <Select required onChange={(e) => handleChange(e, 'stateName')} id="input-with-sx" label="State" variant="outlined">
                  {states?.data.map((state, index) => (
                    <MenuItem key={index} value={state.stateName}>{state.stateName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField label="District  " onChange={(e) => handleChange(e, 'districtName')} variant="outlined" fullWidth /> */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>District*</InputLabel>
                <Select onChange={(e) => handleChange(e, 'districtName')} id="input-with-sx" label="District" variant="outlined">
                  {districts?.data.filter(o => o.stateName === mineData?.stateName)?.map((districts, index) => (
                    <MenuItem key={index} value={districts.districtName}>{districts.districtName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>City*</InputLabel>
                <Select onChange={(e) => handleChange(e, 'cityName')} id="input-with-sx" label="City" variant="outlined">
                  {cities?.data.filter(o => o.districtName === mineData?.districtName)?.map((cities, index) => (
                    <MenuItem key={index} value={cities.cityName}>{cities.cityName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required label="Profile Name " onChange={(e) => handleChange(e, 'profileName')} variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Status*</InputLabel>
              <Select id="input-with-sx" onChange={(e) => handleChange(e, 'status')} label="Status" variant="outlined" >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>


        <br />
        <Button disabled={isLoading || isSaveDisabled} onClick={handleSaveUser} variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
        {error && <Error error={error} />}
        {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}
      </form>

    </>
  );
};

export default Adminform;
