import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import { format } from 'date-fns';
import {
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  Grid,
  TableHead,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

// components1
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import './Clientvehicles.css';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import VendorPage from './VendorPage';
import Label from '../components/label';
import TablePage from './TablePage';
import { useGetMineQuery, useGetDailyVehiclesReportQuery } from '../services/api';
import Iconify from '../components/iconify';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [minename, setMinename] = useState('');
  const {data :mine } = useGetMineQuery();
  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const mineName = useSelector((state) => state.filter).mine;
  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    refetch: refetchVehicles,
  } = useGetDailyVehiclesReportQuery({ mine: mineName }, { refetchOnFocus: true });
  console.log(vehicles, 'vehicles');
  const { data: mines, isLoading: mineDataLoading, isSuccess: mineDataSuccess } = useGetMineQuery();
  const minePoints = mines?.data?.filter((obj) => obj.mineName === mineName)[0];
  // Set the initial date filters to today
  useEffect(() => {
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

    // Add 5 hours and 30 minutes
    startOfDay.setHours(startOfDay.getHours() + 5);
    startOfDay.setMinutes(startOfDay.getMinutes() + 30);
    endOfDay.setHours(endOfDay.getHours() + 5);
    endOfDay.setMinutes(endOfDay.getMinutes() + 30);

    setFrom(startOfDay.toISOString());
    setTo(endOfDay.toISOString());
}, []);

  const filteredVehicles = vehicles?.data?.filter((vehicle) => {
    const createdAtDate = new Date(vehicle.createdAt);
    return createdAtDate >= new Date(from) && createdAtDate < new Date(to);
  });

  const tableHeaders = [
    'From Date Time',
    'To Date Time',
    'Time in',
    'Mine Name',
    'Vehicle Number',
    'Vehicle Type',
    'Vehicle Model',
  ];
  const tableBody = filteredVehicles?.map((vehicle) => [
    from,
    to,
    vehicle?.createdAt,
    vehicle?.mine?.mineName,
    vehicle?.vehicleData?.vehicleNumber,
    vehicle?.vehicleData?.vehicleType,
    vehicle?.vehicleData?.vehicleModel,
  ]);

  const generateCS = () => {
    const content = minePoints?.points?.reduce(
      (acc, point, index) => {
        const titleRow = point.title.padEnd(acc.maxTitleLength, ' ');
        const fieldsRow = point.fields.map((field) => field.name).join(',');

        if (index === 0) {
          acc.titleRow = titleRow;
          acc.fieldsRow = fieldsRow;
          acc.maxTitleLength = titleRow.length;
        } else {
          acc.titleRow += `,${titleRow}`;
          acc.fieldsRow += `,${fieldsRow}`;
          acc.maxTitleLength = Math.max(acc.maxTitleLength, titleRow.length);
        }

        return acc;
      },
      { titleRow: '', fieldsRow: '', maxTitleLength: 0 }
    );

    const finalContent = `${content.titleRow}\n${content.fieldsRow}`;

    const blob = new Blob([finalContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'vehicle_data.csv';
    link.click();
  };

  const convertToCsv = (data) => {
    const csv = Papa.unparse(data, {
      header: true,
      skipEmptyLines: true,
    });
    return csv;
  };

  const DailyReportTable = () => {
    const headers = minePoints?.points?.map((point) => point);
    const [csvData, setCsvData] = useState([]);

    const handleExportCsv = () => {
      // Create an array with the headers and rows
      const data = [
        [' ', ...headers.map((header) => header.title)],
        [
          ' ',
          ...headers.map((header) =>
            header.fields.map((field) => (field.name === 'vehicle data' ? null : field.name)).join(',')
          ),
        ],
        ...vehicles?.data?.map((vehicle) => [
          ' ',
          ...vehicle.points.map((point) => point.fields.map((field) => field.value).join(',')),
        ]),
      ];

      // Update the state to trigger a re-render
      setCsvData(data);
    };
    const convertToPdf = () => {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const pdfDefinition = {
        content: [
          {
            table: {
              headerRows: 1,
              body: [
                [
                  '',
                  ...headers.flatMap((header) =>
                    header.fields.map((field) => (field.name === 'vehicle data' ? null : field.name))
                  ),
                ],
              ],
            },
          },
          ...vehicles?.data?.map((vehicle) => [
            [
              {
                table: {
                  headerRows: 1,
                  body: [
                    [
                      { text: '', fillColor: '#e0e0e0' },
                      ...vehicle?.points?.flatMap((point) => point.fields.map((field) => ({ text: field.value }))),
                    ],
                  ],
                },
              },
            ],
          ]),
        ],
      };

      pdfMake.createPdf(pdfDefinition).download('table.pdf');
    };

    const tableHeaders = [
      'Time in',
      'Token Number',
      // 'Mine Name',
      'Vehicle Number',
      'Vehicle Type',
      'Vehicle Model',
      'Length In Meter',
      'Length In Centimeter',
      'Breadth In Meter',
      'Breadth In Centimeter',
      'Height In Meter',
      'Height In Centimeter',
      'Length in Foot',
      'Length in Inch',
      'Breadth in Foot',
      'Breadth in Inch',
      'Height in Foot',
      'Height in Inch',
      'Volume in Cubic Meter',
      'Volume in Cubic Centimeter',
    ];

    return (
      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 8 }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 170 }}>{'Select'}</TableCell>
                <TableCell colSpan={tableHeaders.length}>Vehicle Data</TableCell>
                {headers?.map((header, i) => (
                  <TableCell key={i} style={{ minWidth: 170 }} colSpan={header.fields.length}>
                    {header.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Grid container>
                    <FormControlLabel control={<Checkbox />} />
                  </Grid>
                </TableCell>
                {tableHeaders.map((vehicle, vehicleIndex) => (
                  <TableCell>{vehicle}</TableCell>
                ))}
                {headers?.map((point) =>
                  point.fields.map((field) => (
                    <TableCell>{field.name === 'vehicle data' ? null : field.name}</TableCell>
                  ))
                )}
              </TableRow>
              {vehicles?.data
                .filter((vehicle) => vehicle?.vehicleData?.vehicleNum) // Filter out vehicles with falsy vehicleData
                .map((vehicle, vehicleIndex) => (
                  <tr key={vehicleIndex}>
                    <td>
                      <Grid container>
                        <FormControlLabel control={<Checkbox />} />
                      </Grid>
                    </td>
                    {tableHeaders.map((header) => (
                      <td key={header}>{vehicle?.vehicleData?.[header]}</td>
                    ))}
                    {vehicle?.points?.map((point, index) =>
                      point.fields.map((field, fieldIndex) => <td key={`${index}-${fieldIndex}`}>{field.value}</td>)
                    )}
                  </tr>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <button onClick={convertToPdf}>download pdf</button>
        <button onClick={handleExportCsv}>Export CSV</button>
        <a href={`data:text/csv;charset=utf-8,${encodeURIComponent(convertToCsv(csvData))}`} download="data.csv">
          Download CSV
        </a>
      </Paper>
    );
  };
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // State to store random colors for main headers
  const [headerColors, setHeaderColors] = useState([]);
  useEffect(() => {
    // Generate random colors for main headers only if headerColors is empty
    if (headerColors.length === 0) {
      const mainHeaderColors = tableHeaders.map(() => getRandomColor());
      setHeaderColors(mainHeaderColors);
    }
  }, [headerColors, tableHeaders]);
  const HtmlTableVehicles = () => {
    const headers = minePoints?.points?.map((point) => point);
    const dataContent = [
      'token',
      'vehicleNumber',
      'vehicleType',
      'vehicleModel',
      'lengthInMeter',
      'lengthInCentimeter',
      'breadthInMeter',
      'breadthInCentimeter',
      'heightInMeter',
      'heightInCentimeter',
      'lengthInFoot',
      'lengthInInch',
      'breadthInFoot',
      'breadthInInch',
      'heightInFoot',
      'heightInInch',
      'volumeInCubicMeter',
      'volumeInCubicFoot',
    ];
    const tableHeaders = [
      'Token Number',
      'Vehicle Number',
      'Vehicle Type',
      'Vehicle Model',
      'Length In Meter',
      'Length In Centimeter',
      'Breadth In Meter',
      'Breadth In Centimeter',
      'Height In Meter',
      'Height In Centimeter',
      'Length in Foot',
      'Length in Inch',
      'Breadth in Foot',
      'Breadth in Inch',
      'Height in Foot',
      'Height in Inch',
      'Volume in Cubic Meter',
      'Volume in Cubic Centimeter',
    ];
    function convertTableToCSV() {
      // Extracting headers from thead
      // eslint-disable-next-line
      const headers = Array.from(document.querySelectorAll('thead th')).map((th) => th.innerText + '');

      // Extracting data from tbody
      const rows = Array.from(document.querySelectorAll('tbody tr')).map((tr) => {
        return Array.from(tr.querySelectorAll('td')).map((td) => td.innerText);
      });

      // Combining headers and rows
      const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv' });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'table_data.csv';

      // Append the link to the document and trigger a click event to start the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);
    }

    const exportToExcel = async () => {
      const htmlTable = document.getElementById('myTable');
      const workbook = new ExcelJS.Workbook();
      workbook.calcProperties.fullCalcOnLoad = true;
      const sheet = workbook.addWorksheet('Sheet1', {
        views: [{ showGridLines: true }],
        pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7, orientation: 'landscape' },
      });
      const headerRows = [];
      const vehicleDataRow = ['', 'Vehicle Data'];
      vehicleDataRow.push(...Array.from({ length: dataContent.length }, () => ' '));
      headers?.forEach((header, i) => {
        if (i < 3) return;
        vehicleDataRow.push(header.title);
        vehicleDataRow.push(
          ...Array.from({ length: i === 0 ? header.fields.length - 2 : header.fields.length - 1 }, () => ' ')
        ); // Add colspan spaces for dynamic headers
        // vehicleDataRow.push(header.title);
      });
      headerRows.push(vehicleDataRow);
      sheet.mergeCells('A1:C2');

      // sheet.getCell('A1').value = 'DG Mines';
      // sheet.getCell('A1').fill = {
      //   type: 'pattern',
      //   pattern: 'darkTrellis',
      // };
      // sheet.getCell('A1').font = {
      //   bold: true,
      //   size: 40,
      // };
      // sheet.getCell('A1').alignment = {
      //   vertical: 'middle',
      //   horizontal: 'center',
      // };
      // Add image to worksheet

      // Add image to cell A1
      const imageId = workbook.addImage({
        buffer: await fetch('/android.png').then((response) => response.arrayBuffer()),
        extension: 'png',
      });

      sheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 200, height: 100 },
      });

      sheet.mergeCells('B4:S4');
      sheet.getCell('B4').value = 'Vehicle Data';
      sheet.getCell('B4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('B4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('T4:W4');
      sheet.getCell('T4').value = 'Entry Point';
      sheet.getCell('T4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'F6927D' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('T4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('X4:AB4');
      sheet.getCell('X4').value = 'Token Point';
      sheet.getCell('X4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'F6CA7D' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('X4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AC4:AG4');
      sheet.getCell('AC4').value = 'Napati Point';
      sheet.getCell('AC4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'A1937B' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('AC4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AH4:AI4');
      sheet.getCell('AH4').value = 'Naka Point 1';
      sheet.getCell('AH4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: '45C4B9' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('AH4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AJ4:AK4');
      sheet.getCell('AJ4').value = 'Naka Point 2';
      sheet.getCell('AJ4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'ADD1CE' },
        bgColor: { argb: 'ADD1CE' },
      };
      sheet.getCell('AJ4').font = {
        bold: true,
        size: 16,
      };
      sheet.mergeCells('AL4:AM4');
      sheet.getCell('AL4').value = 'Naka Point 3';
      sheet.getCell('AL4').fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'AEBBDE' },
        bgColor: { argb: 'FF0000FF' },
      };
      sheet.getCell('AL4').font = {
        bold: true,
        size: 16,
      };

      const tableData = Array.from(htmlTable.querySelectorAll('tbody tr')).map((row) =>
        Array.from(row.children).map((cell) => (cell.textContent === 'Check here to print' ? '' : cell.textContent))
      );
      tableData.forEach((row) => {
        sheet.addRow(row);
      });
      const buffer = await workbook.xlsx.writeBuffer();
      FileSaver.saveAs(new Blob([buffer]), 'exported_data.xlsx');
    };

    return (
      <div>
        <div className="scrollable-table-container">
          <table id="myTable">
            <thead>
              <tr>
                <th>Select</th>
                <th colSpan={dataContent.length} style={{ backgroundColor: getRandomColor() }}>
                  Vehicle Data
                </th>
                {headers?.length > 0 &&
                  headers?.map((header, index) => (
                    <th key={index} colSpan={header?.fields?.length} style={{ backgroundColor: headerColors[index] }}>
                      {header?.title}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Click here to print</td>
                {tableHeaders?.map((table, tableIndex) => (
                  <td key={tableIndex}>{table}</td>
                ))}
                {headers?.map((point, pointIndex) =>
                  point?.fields?.map((field, fieldIndex) => (
                    <td key={fieldIndex}>{field?.name === 'vehicle data' ? null : field?.name}</td>
                  ))
                )}
              </tr>
              {vehicles?.data?.map((vehicle, index) => (
                <tr key={index}>
                  <td>
                    <Grid container>
                      <FormControlLabel control={<Checkbox />} />
                    </Grid>
                  </td>
                  {dataContent.map((header, headerIndex) => (
                    <td key={headerIndex}>{vehicle?.vehicleData?.[header]}</td>
                  ))}
                  {vehicle?.points?.map((point, pointIndex) =>
                    point?.fields?.map((field, fieldIndex) => <td key={fieldIndex}>{field?.value}</td>)
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button onClick={exportToExcel}>Download CSV</button>
      </div>
    );
  };

  const generateCSV = () => {
    const fieldNamesArray = vehicles.data[0].points.map((point) => point.fields.map((field) => field.name));
    const points = minePoints?.points?.map((point) => point.title);
    const tableTitle = points;
    const tableHeaders = [
      'Time in',
      'Token Number',
      'Mine Name',
      'Vehicle Number',
      'Vehicle Type',
      'Vehicle Model',
      'Length In Meter',
      'Length In Centimeter',
      'Breadth In Meter',
      'Breadth In Centimeter',
      'Height In Meter',
      'Height In Centimeter',
      'Length in Foot',
      'Length in Inch',
      'Breadth in Foot',
      'Breadth in Inch',
      'Height in Foot',
      'Height in Inch',
      'Volume in Cubic Meter',
      'Volume in Cubic Centimeter',
      ...fieldNamesArray.flat(),
    ];

    const tableHeaders1 = ['From Date Time', 'To Date Time'];
    const csvContent = [
      tableTitle?.join(),
      tableHeaders1.join(','),
      [from, to].join(','),
      '',
      '',
      '',
      '',
      tableHeaders.join(','),
      ...filteredVehicles?.map((vehicle) => {
        const fieldValuesArray = vehicle.points.flatMap((point) => point.fields.map((field) => field.value));
        return [
          vehicle?.createdAt,
          vehicle?.vehicleData?.token,
          vehicle?.mine?.mineName,
          vehicle?.vehicleData?.vehicleNumber,
          vehicle?.vehicleData?.vehicleType,
          vehicle?.vehicleData?.vehicleModel,
          vehicle?.vehicleData?.lengthInMeter,
          vehicle?.vehicleData?.lengthInCentimeter,
          vehicle?.vehicleData?.breadthInMeter,
          vehicle?.vehicleData?.breadthInCentimeter,
          vehicle?.vehicleData?.heightInMeter,
          vehicle?.vehicleData?.heightInCentimeter,
          vehicle?.vehicleData?.lengthInFoot,
          vehicle?.vehicleData?.lengthInInch,
          vehicle?.vehicleData?.breadthInFoot,
          vehicle?.vehicleData?.breadthInInch,
          vehicle?.vehicleData?.heightInFoot,
          vehicle?.vehicleData?.heightInInch,
          vehicle?.vehicleData?.volumeInCubicMeter,
          vehicle?.vehicleData?.volumeInCubicFoot,
          ...fieldValuesArray,
        ].join(',');
      }),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'vehicle_data.csv';
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>Daily Report Vehicles </title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Daily Report Vehicles
        </h2>
      </div>

      <Container>
        <Box className="bg">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Daily Report Vehicles
          </Typography>
        </Box>
        <br />
        <FormControl fullWidth style={{ marginBottom: '2%' }}>
          <InputLabel id="demo-simple-select-label">Select Mine</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={minename}
            label="Select Mine"
            onChange={(e) => setMinename(e.target.value)}
          >
            {mine?.data.map((_mine, index) => (
              <MenuItem value={_mine.mineName}>{_mine.mineName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {/* <Typography variant="h4" gutterBottom />
          <input
            type="datetime-local"
            id="meeting-time"
            name="from"
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
          <input type="datetime-local" id="meeting-time" name="to" value={to} onChange={(e) => setTo(e.target.value)} /> */}
          <Button onClick={generateCSV} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Sales Report
          </Button>
        </Stack>
        <HtmlTableVehicles />
      </Container>
    </>
  );
}
