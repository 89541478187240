import React from 'react';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid'; // Import Grid from Material-UI
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';

// utils
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: theme.palette.mode === 'light' ? '#1a9f' : '#308fe8',
  },
}));


const BorderLinearProgressa = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: theme.palette.mode === 'light' ? '#03dffc' : '#308fe8',
  },
}));

const BorderLinearProgressb = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: theme.palette.mode === 'light' ? 'red' : '#308fe8',
  },
}));

const data = [
  { label: 'Group A', value: 900 },
  { label: 'Group B', value: 300 },
  { label: 'Group C', value: 300 },
  { label: 'Group D', value: 200 },
];

export default function SaleOrder() {
  return (
    <Box m={3} >
      <Grid container spacing={2}>
        <Grid item xs={3} md >
          <Typography >SaleOrder</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography />
        </Grid>
        <Grid item xs={5}>
          <span> show status  <Switch {...label} defaultChecked /></span>
        </Grid>
        <Grid item xs={6}>
          <Box >
            <Typography>Order &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ fontSize: '10px' }}>  $590,47</span>
              <BorderLinearProgress variant="determinate" value={50} />
            </Typography>
            <Typography>Stock  &nbsp;
              <BorderLinearProgressa variant='determinate' value={70} /></Typography>
            <Typography>Profit  &nbsp;
              <BorderLinearProgressb variant='determinate' value={70} /> </Typography>
            <Typography> Sale &nbsp;
              <BorderLinearProgressb variant='determinate' value={70} /></Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography >
            <PieChart
              series={[
                {
                  paddingAngle: 5,
                  innerRadius: 60,
                  outerRadius: 80,
                  data,
                },
              ]}
              margin={{ right: 4 }}
              width={200}
              height={200}
              legend={{ hidden: true }}
            />
          </Typography>
        </Grid>
      </Grid>

    </Box>
  );
}