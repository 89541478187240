import { Helmet } from 'react-helmet-async';
// @mui
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { faker } from '@faker-js/faker';

import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import './Form1.css';
import { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from '@mui/material/styles';

import {
  Grid,
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
} from '@mui/material';
import { AppOrderTimeline } from '../sections/@dashboard/app';
import PhotoUploadForm from './Camera';

// @mui

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
// components
const MuiAccordion = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };

  const [name, setName] = useState('');

  const [selectedCity, setSelectedCity] = useState('');

  const [destination, setDestination] = useState('');
  const [destinationa, setDestinationa] = useState('');
  const [client, setClient] = useState('');
  const [clienta, setClienta] = useState('');

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setSelectedCity(newCity);
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={8}>
          <Typography variant="h4">Forms</Typography>
          <Box m={2}>
            <Accordion expanded={expand === 'one'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'one')}>
              <AccordionSummary className="mine-acodi" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
                Vehicle Entry Point 
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  <Typography
                    variant="body2"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                  >
                    <TextField
                      id="input-with-sx name"
                      label=" Enter Mobile Number"
                      variant="outlined"
                      className="pati"
                      type="number"
                      style={{ width: '67ch' }}
                      value={name}
                      onChange={handleNameChange}
                    />

                    {name && (
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <Stack direction={'column'} spacing={3}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                              <TextField
                                id="input-with-sx name"
                                label=" Enter Vehicle Number."
                                variant="outlined"
                                style={{ width: '40ch' }}
                              />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                              <TextField
                                id="input-with-sx"
                                label="Enter Driver Name"
                                variant="outlined"
                                style={{ width: '40ch' }}
                              />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                              <TextField
                                id="input-with-sx"
                                label="Origin Location"
                                variant="outlined"
                                style={{ width: '40ch' }}
                              />
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction={'column'} spacing={3}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                              <TextField
                                id="input-with-sx"
                                label="Enter Vehicle Type"
                                variant="outlined"
                                style={{ width: '40ch' }}
                              />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                              <TextField
                                id="input-with-sx"
                                label="Enter Driver Mobile Number "
                                variant="outlined"
                                style={{ width: '40ch' }}
                              />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                              {/* <TextField
                            id="input-with-sx"
                            label="Alternate Mobile No Destination Type "
                            variant="outlined"
                            style={{ width: '40ch' }}
                          /> */}

                              {name && (
                                <FormControl style={{ width: '40ch' }}>
                                  <InputLabel>Destination Type </InputLabel>
                                  <Select
                                    labelId="age-label"
                                    id="age-select"
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                    label="Destination Type"
                                  >
                                    <MenuItem value="Drumoyaryd">Dumpyard</MenuItem>
                                    <MenuItem value="client">Client Location</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    {name && (
                      <Box>
                        {selectedCity === 'Drumoyaryd' && (
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Select Dumpyard</InputLabel>
                            <Select
                              labelId="drumoyaryd-label"
                              id="drumoyaryd-select"
                              value={destination}
                              onChange={(e) => setDestination(e.target.value)}
                              label="Drumoyaryd Dropdown"
                            >
                              <MenuItem value="Option 1">Option 1</MenuItem>
                              <MenuItem value="Option 2">Option 2</MenuItem>
                              <MenuItem value="Option 4">Option 4</MenuItem>
                              <MenuItem value="Option 3">Option 4</MenuItem>
                            </Select>
                          </FormControl>
                        )}

                        {selectedCity === 'Drumoyaryd' && (
                          <TextField
                            style={{ width: '40ch' }}
                            label="Select Route "
                            variant="outlined"
                            value={destinationa}
                            onChange={(e) => setDestinationa(e.target.value)}
                          />
                        )}

                        {selectedCity === 'client' && (
                          <TextField
                            style={{ width: '40ch' }}
                            label="Select Location"
                            variant="outlined"
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                          />
                        )}

                        {selectedCity === 'client' && (
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Route</InputLabel>
                            <Select
                              labelId="-label"
                              id="drumoyaryd-select"
                              value={clienta}
                              onChange={(e) => setClienta(e.target.value)}
                              label="Client Dropdown"
                            >
                              <MenuItem value="Option 1">Option 1</MenuItem>
                              <MenuItem value="Option 2">Option 2</MenuItem>
                              <MenuItem value="Option 4">Option 4</MenuItem>
                              <MenuItem value="Option 3">Option 4</MenuItem>
                            </Select>
                          </FormControl>
                        )}

                        <TextField
                          id="input-with-sx"
                          className="pati"
                          label=" Remark"
                          variant="outlined"
                          style={{ width: '67ch' }}
                        />
                      </Box>
                    )}

                    {name && (
                      <Button variant="contained" type="submit" endIcon={<SaveIcon />}>
                        Save
                      </Button>
                    )}
                  </Typography>
                </form>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expand === 'two'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'two')}>
              <AccordionSummary className="mine-acodi1" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                Token Issue Point {' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Token Type </InputLabel>
                            <Select id="input-with-sx" label="Token Type" variant="outlined">
                              <MenuItem value="option1">Token Type 1</MenuItem>
                              <MenuItem value="option2">Token Type 2</MenuItem>
                              <MenuItem value="option3">Token Type 3</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Quality of Sand </InputLabel>
                            <Select id="input-with-sx" label="Quality of Sand" variant="outlined">
                              <MenuItem value="option1">Quality Type 1</MenuItem>
                              <MenuItem value="option2">Quality Type 2</MenuItem>
                              <MenuItem value="option3">Quality Type 3</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            style={{ width: '40ch' }}
                            id="input-with-sx"
                            label="Loading Volume"
                            variant="outlined"
                            type="number"
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField style={{ width: '40ch' }} id="input-with-sx" label="Amount" variant="outlined" />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            style={{ width: '40ch' }}
                            id="input-with-sx"
                            label="Token Number"
                            variant="outlined"
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            style={{ width: '40ch' }}
                            id="input-with-sx"
                            label="Royalty Volume"
                            variant="outlined"
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Load Sand From Mine Point </InputLabel>
                            <Select id="input-with-sx" label="Load Sand From Mine Point " variant="outlined">
                              <MenuItem value="option1"> Mine 1</MenuItem>
                              <MenuItem value="option2">Mine 2</MenuItem>
                              <MenuItem value="option3">Mine 3</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField style={{ width: '40ch' }} label="Daban " variant="outlined" />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <TextField fullWidth id="input-with-sx" label="Remark" variant="outlined" />

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}

            <Accordion expanded={expand === 'three'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'three')}>
              <AccordionSummary className="mine-acodi3" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
                Khadan (Mine Point)
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Loaded Volume"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Contractor Type"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Machine Number </InputLabel>
                            <Select id="input-with-sx" label="Machine Number " variant="outlined">
                              <MenuItem value="option1">Machine Number 1</MenuItem>
                              <MenuItem value="option2">Machine Number 2</MenuItem>
                              <MenuItem value="option3">Machine Number 3</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Contractor Name"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <TextField id="input-with-sx" required label="Remark" variant="outlined" style={{ width: '67ch' }} />
                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}

            <Accordion expanded={expand === 'four'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'four')}>
              <AccordionSummary className="mine-acodi4" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                Napai ( Measurement Point) 
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label=" Measured Quantity"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <FormControl style={{ width: '40ch' }}>
                            <InputLabel>Action for Extra Amount </InputLabel>
                            <Select id="input-with-sx" label="Action for Extra Amount  " variant="outlined">
                              <MenuItem value="option1">Action for Extra Amount 1</MenuItem>
                              <MenuItem value="option2">Action for Extra Amount r 2</MenuItem>
                              <MenuItem value="option3">Action for Extra Amount 3</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <TextField id="input-with-sx" label=" Remark" variant="outlined" style={{ width: '67ch' }} />

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}

            <Accordion expanded={expand === 'five'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'five')}>
              <AccordionSummary className="mine-acodi5" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                  Royalty Issue Point
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Royalty Pass No"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <Button
                            component="label"
                            variant="outlined"
                            style={{ width: '40ch', padding: '20px' }}
                            startIcon={<UploadFileIcon />}
                          >
                            Upload file
                            <VisuallyHiddenInput type="file" />
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Out Gate Pass"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <Button component="label" variant="outlined" style={{ width: '40ch', padding: '15px' }}>
                            <PhotoUploadForm />
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <TextField fullWidth id="input-with-sx" label="Remark" variant="outlined" />
                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}

            <Accordion expanded={expand === 'six'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'six')}>
              <AccordionSummary className="mine-acodi6" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                  Naka (Checking Point 1 )
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  {/* <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Royalty Valid"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Mine Naka Remark"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid> */}

                  <TextField fullWidth id="input-with-sx" label="Royalty Valid" variant="outlined" />

                  <TextField fullWidth id="input-with-sx" label="Remark" variant="outlined" />

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}

            <Accordion expanded={expand === 'seven'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'seven')}>
              <AccordionSummary className="mine-acodi7" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                  Naka (Checking Point 2 )
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  {/* <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Royalty Valid"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Mine Naka Remark"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid> */}

                  <TextField fullWidth id="input-with-sx" label="Royalty Valid" variant="outlined" />

                  <TextField fullWidth id="input-with-sx" label="Remark" variant="outlined" />

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}
            <Accordion expanded={expand === 'eight'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'eight')}>
              <AccordionSummary className="mine-acodi8" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                  Naka (Checking Point 1 )
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  {/* <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Royalty Valid"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Mine Naka Remark"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid> */}

                  <TextField fullWidth id="input-with-sx" label="Royalty Valid" variant="outlined" />

                  <TextField fullWidth id="input-with-sx" label="Remark" variant="outlined" />
                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* asdfghjl;keufgroiujhoy */}
            <Accordion expanded={expand === 'nine'} onChange={(event, IsExpand) => handleExpand(IsExpand, 'nine')}>
              <AccordionSummary className="mine-acodi9" expandIcon={<ExpandMoreIcon color="secondary" />}>
                <Typography variant="h5" style={{ color: 'black' }}>
                  Dump Openings Stock
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Opening Date"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            required
                            label="Qunantity (CFT)"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction={'column'} spacing={3}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label=" Dumping Location"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                          <TextField
                            id="input-with-sx"
                            label="Qunantity (CMT)"
                            variant="outlined"
                            style={{ width: '40ch' }}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Button variant="contained" endIcon={<SaveIcon />}>
                    Save
                  </Button>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ display: 'flex', flexDirection: 'column', gap: '1vmax' }}>
          <Box className="QR">
            <img src="/assets/new img/QR.png" alt="" />
          </Box>
          <Box>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MuiAccordion;
