import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setMine, setState } from '../../../slices/filterSlice';
import { useGetMineQuery, useGetStatesQuery } from '../../../services/api';

export default function MineSelect() {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter);

  const { data: states } = useGetStatesQuery();

  const { data: mines } = useGetMineQuery();

  const filteredMines = mines?.data?.filter(obj => obj.state === filter.state);

  return (
    <Box>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
        <InputLabel id="demo-simple-select-label" >States</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter.state}
          label="States"
          onChange={(e) => dispatch(setState(e.target.value))}
        >
          {
            states?.data?.map((state, index) => (
              <MenuItem value={state.stateName} key={index}>{state.stateName}</MenuItem>
            ))
          }
        </Select>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Basic example"
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
        <InputLabel id="demo-simple-select-label" >Mine</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter.mine}
          label="Mine"
          onChange={(e) => dispatch(setMine(e.target.value))}
        >
          {
            filteredMines?.map((mine, index) => (
              <MenuItem value={mine.mineName} key={index}>{mine.mineName}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  )
};

