import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/system';
import Iconify from '../../components/iconify';
import TablePage from '../TablePage';
import { useGetDepartmentQuery, useGetDesignationQuery, useSaveDesignationMutation } from '../../services/api';
import Error from '../../components/error/Error';
import Success from '../../components/success/Success';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));
export default function UserPage() {
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    right: '10%',
    top: '5%',
  };

  const [open, setOpen] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {data: designations} = useGetDesignationQuery();
  const {data: departments} = useGetDepartmentQuery();
  const [department, setDepartment] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [parent, setParent] = useState(null);
  const [managementLevel, setManagementLevel] = useState(null);
  const [desc, setDesc] = useState(null);
  const [saveDesignation, {error, isLoading, isSuccess}] = useSaveDesignationMutation();

  console.log(designations);
  
  const handleDesignationSave = async () => {
    await saveDesignation({departmentName: department, description: desc, designationName: designation, parent, managementLevel});
    handleClose();
  }


  return (
    <>
      <Helmet>
        <title>Designation</title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / HR Department / Designation
        </h2>
      </div>

      <Container>
      {error && <Error error={error}/>}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={"Save successfully"}/>}
        <Box className="bg">
          <Helmet>
            <title> Designation </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Designation
          </Typography>

          <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>{designations?.data?.length}</Box>
                <br />
                <Box>Total Designation</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>{designations?.data?.length}</Box>
                <br />

                <Box>Active Designation</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>{designations?.data?.length}</Box>
                <br />
                <Box>Inactive Designation</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>{designations?.data?.length}</Box>
                <br />
                <Box>Today Designation</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Designation
          </Button>
        </Stack>

        <TablePage />
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Add designation
          </Typography>

          <form>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Department</InputLabel>
                  <Select onChange={(e) => setDepartment(e.target.value)} id="input-with-sx" label="Department Name " variant="outlined">
                    {departments?.data.map((department, index) => (
                        <MenuItem key={index} value={department.departmentName}>{department.departmentName}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField onChange={(e) => setDesignation(e.target.value)} id="input-with-sx" required label=" Designation Name" variant="outlined" fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Parent</InputLabel>
                  <Select onChange={(e) => setParent(e.target.value)} id="input-with-sx" label="Parent Name" variant="outlined">

                    {designations?.data.map((designation, index) => (
                        <MenuItem key={index} value={designation.designationName}>{designation.designationName}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
             
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Management Level</InputLabel>
                  <Select onChange={(e) => setManagementLevel(e.target.value)} id="input-with-sx" label="Management Level" variant="outlined">
                        <MenuItem value="High Level">High Level</MenuItem>
                        <MenuItem value="Mid Level">Mid Level</MenuItem>
                        <MenuItem value="Low Level">Low Level</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                  <textarea
                  onChange={(e) => setDesc(e.target.value)}
                    placeholder="Description"
                    name=""
                    id=""
                    cols="50"
                    rows="10"
                    style={{ outline: 'none', padding: '1vmax' }}
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <Button onClick={handleDesignationSave} variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
}
