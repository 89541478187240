import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SaveIcon from '@mui/icons-material/Save';
import { Link } from 'react-router-dom';
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Modal from '@mui/material/Modal';
import Select from 'react-select';
import { styled } from '@mui/system';
import Iconify from '../../components/iconify';
// import Tablepagerole from './Tablepagerole'
import EmployeeRoleTable from './EmployeeRoleTable';
import { useSaveEmployeeRoleMutation, useGetEmployeeRoleQuery } from '../../services/api';
import Error from '../../components/error/Error';
import Success from '../../components/success/Success';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '0px',
  textAlign: 'center',
}));
export default function UserPage() {

  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    right: '10%',
    top: '5%',
  };

  const [open, setOpen] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {data: employeeRoles} = useGetEmployeeRoleQuery();
  const [employeeRole, setEmployeeRole] = useState(null);
  const [desc, setDesc] = useState(null);

  const [saveEmployeeRole, { error, isSuccess, isLoading }] = useSaveEmployeeRoleMutation();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const handleEmployeeRoleSave = async () => {
    await saveEmployeeRole({ roleName: employeeRole, description: desc, roleList: selectedRoles });
    setSelectedRoles([]);
    handleClose();
  }

  const roleOptions = [
    { value: 'Dashboard', label: 'Dashboard' },
    { value: 'Reports', label: 'Reports' },
    {value: 'Vehicle Master Data', label: 'Vehicle Master Data'},
    { value: ' Active Districts', label: ' Active Districts' },
    { value: 'Mines', label: 'Mines' },
    { value: 'Equipments', label: 'Equipments' },
    { value: 'Vendors', label: 'Vendors' },
    { value: 'Dumpyards  ', label: 'Dumpyards  ' },
    { value: 'Target  ', label: 'Target  ' },
    { value: 'Volume  ', label: 'Volume  ' },
    { value: 'HR Department', label: 'HR Department' },
    { value: 'Employe Management', label: 'Employe Management' }
    // Add more options as needed
  ];

  const handleChange3 = (selectedValues) => {
    setSelectedRoles(selectedValues);
  };


  return (
    <>
      <Helmet>
        <title>Employee Role</title>
      </Helmet>
      <div style={{ fontSize: '10px', display: "flex", alignItems: "center", gap: ".5vmax", }}> <HomeIcon color="primary" /> <h2><Link to="/dashboard" style={{ textDecoration: "none", color: "black" }}>Dashboard</Link> / HR Department / Employee Role</h2></div>

      <Container>
        {error && <Error error={error} />}
        {isSuccess && <Success isSuccess={isSuccess} successMessage={"Save successfully"} />}
        <Box className="bg">
          <Helmet>
            <title> Employee Role </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Employee Role
          </Typography>

          <Grid container spacing={0} sx={{ flexGrow: 1 }} style={{ marginTop: '2vmax' }}>

            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>{employeeRoles?.data?.length}</Box>
                <br />
                <Box>Total Employee Role</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>{employeeRoles?.data?.length}</Box>
                <br />

                <Box>Active Employee Role</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>{employeeRoles?.data?.length}</Box>
                <br />
                <Box>Inactive Employee Role</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>{employeeRoles?.data?.length}</Box>
                <br />
                <Box>Today Employee Role</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />

          <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Employee Role
          </Button>
        </Stack>
        <EmployeeRoleTable />

      </Container>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Create Employee Role
          </Typography>

          <Typography
            id="keep-mounted-modal-description"
            sx={{ mt: 2 }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={0}>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label="Role Name"
                      onChange={(e) => setEmployeeRole(e.target.value)}
                      variant="outlined"
                      style={{ width: '100%' }}
                    />
                  </Box>



                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>

                    <FormControl fullWidth>
                      <Select
                        label="Select Role "
                        placeholder="Select Role"
                        variant="outlined"
                        isMulti
                        options={roleOptions}
                         onChange={handleChange3}
                         value={selectedRoles}
                        // multiple
                      >
                        <MenuItem value="option1">Department A</MenuItem>
                        <MenuItem value="option2">Department B</MenuItem>
                        {/* <MenuItem value="option3">Department C</MenuItem>
                        <MenuItem value="option1">Department E</MenuItem>
                        <MenuItem value="option21">Department F</MenuItem>
                        <MenuItem value="option31">Department G</MenuItem> */}
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <textarea
                      placeholder="Description"
                      name=""
                      onChange={(e) => setDesc(e.target.value)}
                      id=""
                      cols="50"
                      rows="10"
                      style={{ outline: 'none', padding: '1vmax' }}
                    />
                  </Box>
                </Stack>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <FormControl    style={{width:'40ch'}}>
                      <InputLabel id="demo-multiple-name-label">  Role List</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label=" Employee Role List" />}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Grid> */}
            </Grid>
            <Button onClick={handleEmployeeRoleSave} variant="contained" endIcon={<SaveIcon />}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
