import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),

  status: sample(['Operational', 'UnderMaintenance','Out of Service']),


  Manufacturer: sample([
    'Operators',
    'Managers',
    'Operators',
    'Managers',

  ]),
  Model: sample([
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
   
  ]),
  Serialnumber: sample([
    '12344 ',
    '234567',
    '56678',
    '654345',
    '7654543',
    '54344',
    '434455',
    '098765',
  ]),
  Puarchasedate: sample([
    '12-03-2018 ',
    '12-03-2018',
    '02-06-2016',
    '12-03-2018',
    '21-05-2021',
    '06-03-2017',
    '21-05-2015',
  ]),
  Machinetype: sample([
    'Excavator',
    'Conveyor Belt',
    'Truck',
    'Excavator',
    
  ]),
  Machinenumber: sample([
    'MP 04 LD 1459',
    'MH 42 BK 8456',
    'UP 15 SK 2849',
    'RJ 25 PL 7954',
    'MH 16 RK 3654',
    'CG 01 DO 9515',
    'HR 08 VI 0245',
    'PA 17 VE 3258',
    'TN 06 SP 4478',
    'MP 18 TB 5562',
    'UP 65 NO 8456',
  ]),
  Locationmobile: sample([
    'bhopal',
    'bhopal',
    'bhopal',
    'bhopal',
    'bhopal',
    'bhopal',
    'bhopal',
    'bhopal',
    
  ]),

  Maintenancehistory: sample([
    'mantain',
    'under main.',
    'mantain',
    'under main.',
    'mantain',
    'under main.',
    'mantain',
    'under main.',
  ]),




}));

export default users;
