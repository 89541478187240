import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import { useSavePointMutation } from '../services/api';
import Error from '../components/error/Error';
import Success from '../components/success/Success';

export default function DropdownExample() {
  const [forms, setForms] = useState([{ title: '', fields: [{ name: '', type: 'InputBox' }] }]);

  const addTextField = (formIndex) => {
    const updatedForms = [...forms];
    updatedForms[formIndex].fields.push({ name: '', type: 'InputBox' });
    setForms(updatedForms);
  };

  const removeTextField = (formIndex, fieldIndex) => {
    const updatedForms = [...forms];
    updatedForms[formIndex].fields.splice(fieldIndex, 1);
    setForms(updatedForms);
  };

  const addForm = () => {
    setForms([...forms, { title: '', fields: [{ name: '', type: 'InputBox' }] }]);
  };

  const [savePoints, {error, isSuccess, isLoading}] = useSavePointMutation();
  const navigate = useNavigate();
  const handleSavePoints = async () => {
    await savePoints(forms);
  };

  useEffect(() => {
    if(isSuccess){
      setTimeout(() => {
        navigate('/dashboard/Points', { replace: true })
      }, 800);
    }
  },[isSuccess])

  return (
    <div>
      {error && <Error error={error}/>}
      {isSuccess && <Success isSuccess={isSuccess} successMessage={"Saved successfully"}/>}
      <div className="dropdown-container">
        <h2>Add Points Name</h2>
        <Typography variant="h4" gutterBottom />
        <Button
          type="button"
          disabled={isLoading}
          onClick={handleSavePoints}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Save all Points
        </Button>
      </div>

      {forms.map((form, formIndex) => (
        <div key={formIndex}>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Point Name"
                  variant="outlined"
                  className="pati"
                  type="text"
                  style={{ width: '40ch', marginTop: '20px' }}
                  value={form.title}
                  onChange={(event) => {
                    const updatedForms = [...forms];
                    updatedForms[formIndex].title = event.target.value;
                    setForms(updatedForms);
                  }}
                />
              </Grid>
            </Grid>
            {form.fields.map((field, fieldIndex) => (
              <Grid container spacing={4} key={fieldIndex}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Add Field"
                    variant="outlined"
                    className="pati"
                    type="text"
                    style={{ width: '30ch', marginTop: '20px' }}
                    value={field.name}
                    onChange={(event) => {
                      const updatedForms = [...forms];
                      updatedForms[formIndex].fields[fieldIndex].name = event.target.value;
                      setForms(updatedForms);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl style={{ width: '30ch', marginTop: '20px' }}>
                    <InputLabel> Add Type</InputLabel>
                    <Select
                      id="input-with-sx"
                      label="Add Type"
                      variant="outlined"
                      value={field.type}
                      onChange={(event) => {
                        const updatedForms = [...forms];
                        updatedForms[formIndex].fields[fieldIndex].type = event.target.value;
                        setForms(updatedForms);
                      }}
                    >
                      <MenuItem value="Number">Number</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="InputBox">InputBox</MenuItem>
                      <MenuItem value="FileUpload">FileUpload</MenuItem>
                      <MenuItem value="Date">Date</MenuItem>
                      <MenuItem value="Time">Time</MenuItem>
                      {/* <MenuItem value="CheckBox">CheckBox</MenuItem>
                      <MenuItem value="Drop Down">Drop Down</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <Button
                      type="button"
                      style={{ marginTop: '10px' }}

                      onClick={() => removeTextField(formIndex, fieldIndex)}
                      variant="contained"
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ))}
          </form>
          
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} mt={-5}>
              <Typography variant="h4" gutterBottom />

              <Button
                type="button"
                style={{ marginRight: '150px' }}
                onClick={() => addTextField(formIndex)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              />
            </Stack>
        </div>
      ))}

      <Button type="button" onClick={addForm} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
        Add Form
      </Button>
    </div>
  );
}
