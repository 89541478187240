import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Menu, MenuItem, Divider, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
// components

import * as React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import Iconify from '../../components/iconify';

// sections
import { AppWidgetSummary } from '../../sections/@dashboard/app';
// import Sale from 'src/sections/@dashboard/app/SaleOrder';

//

// export default function AccountMenu() {

//

// ----------------------------------------------------------------------
export default function DashboardAppPage() {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Helmet>
        <title> Reports </title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Reports
        </h2>
      </div>
      <Container maxWidth="xl">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* <Typography variant="h4" sx={{ mb: 5 }}> */}
          <h2>Reports</h2>
          {/* </Typography> */}

          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Language">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                icon={'game-icons:mine-truck'}
              >
                <LanguageIcon />
                <Typography style={{ color: 'black' }}>
                  <b>Language</b>
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>Hindi</MenuItem>
            <MenuItem onClick={handleClose}>English</MenuItem>
            <Divider />
          </Menu>
        </div>

        <Grid container spacing={3} className="linka">
          <Grid item xs={12} sm={6} md={3}>
            <Link to="#">
              <AppWidgetSummary
                style={{ height: '11vmax' }}
                title="Report 1"
                color="error"
                icon={'fluent-mdl2:report-add'}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="#">
              <AppWidgetSummary
                style={{ height: '11vmax' }}
                title="Report 2"
                color="info"
                icon={'fluent-mdl2:report-add'}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="#">
              <AppWidgetSummary
                style={{ height: '11vmax' }}
                title="Report 3"
                color="warning"
                icon={'fluent-mdl2:report-add'}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="#">
              <AppWidgetSummary
                style={{ height: '11vmax' }}
                y
                title="Report 4"
                color="error"
                icon={'fluent-mdl2:report-add'}
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
