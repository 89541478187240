import { Helmet } from 'react-helmet-async';
// @mui
import { useEffect, useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { Grid, Typography, Box, TextField, Stack } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Navigate, useNavigate } from 'react-router-dom';
import Success from '../components/success/Success';
import Error from '../components/error/Error';
import Iconify from '../components/iconify';
import {
  useGetAreasQuery,
  useGetPointsQuery,
  useGetCitiesQuery,
  useGetStatesQuery,
  useGetMineTypeQuery,
  useSaveMineMutation,
  useGetSandTypeQuery,
} from '../services/api';
// components
const Mines = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState();
  const { data: points } = useGetPointsQuery({}, { refetchOnFocus: true });
  console.log(points, 'points');
  const {
    data: states,
    isLoading: isStatesLoading,
    isSuccess: isStatesSuccess,
    isError: isStatesError,
    error: statesError,
  } = useGetStatesQuery();
  const {
    data: cities,
    isLoading: isCitiesLoading,
    isSuccess: isCitiesSuccess,
    isError: isCitiesError,
    error: citiesError,
  } = useGetCitiesQuery();
  const {
    data: areas,
    isLoading: isAreasLoading,
    isSuccess: isAreasSuccess,
    isError: isAreasError,
    error: areasError,
  } = useGetAreasQuery();
  const {
    data: mineTypes,
    isLoading: isMineTypesLoading,
    isSuccess: isMineTypesSuccess,
    isError: isMineTypesError,
    error: mineTypesError,
  } = useGetMineTypeQuery();
  const {
    data: sandTypes,
    isLoading: isSandTypesLoading,
    isSuccess: isSandTypesSuccess,
    isError: isSandTypesError,
    error: sandTypesError,
  } = useGetSandTypeQuery();
  const [mineData, setMineData] = useState({});
  const [saveMine, { error, isLoading, isSuccess }] = useSaveMineMutation();
  // const [MiningArea, setMiningArea] = useState({});
  const [minePoints, setMinePoints] = useState([{ position: 0 }]);
  // console.log(sandTypes.data, 'sandTypes')
  // console.log(mineTypes.data, 'sandTypes')
  const handleChange = (e, field) => {
    setMineData({ ...mineData, [field]: e.target.value });
  };

  const handleSaveMine = async () => {
    await saveMine({ ...mineData, points: minePoints });
    console.log({ ...mineData, points: minePoints });
  };

  const addMinePoint = () => {
    setMinePoints((prev) => [...prev, { title: '', fields: [] }]);
  };

  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/mine');
      }, 800);
    }
  }, [isSuccess]);

  return (
    <>
      <h2>Add Mine</h2>
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'mineName')}
                  id="input-with-sx"
                  required
                  label="Mine Name"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>State </InputLabel>
                  <Select
                    onChange={(e) => handleChange(e, 'state')}
                    id="input-with-sx"
                    label="State"
                    variant="outlined"
                  >
                    {states?.data.map((state, index) => (
                      <MenuItem key={index} value={state.stateName}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>City </InputLabel>
                  <Select onChange={(e) => handleChange(e, 'city')} id="input-with-sx" label="City" variant="outlined">
                    {cities?.data
                      .filter((o) => o.stateName === mineData?.state)
                      ?.map((cities, index) => (
                        <MenuItem key={index} value={cities.cityName}>
                          {cities.cityName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>Area </InputLabel>
                  <Select onChange={(e) => handleChange(e, 'area')} id="input-with-sx" label="Area" variant="outlined">
                    {areas?.data
                      .filter((o) => o.cityName === mineData?.city)
                      ?.map((area, index) => (
                        <MenuItem key={index} value={area.area}>
                          {area.area}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'mineLicenseNumber')}
                  id="input-with-sx"
                  required
                  label="Mine license Number  "
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label=" Location  " variant="outlined" style={{ width: '40ch' }} />
              </Box> */}

              {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>

                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>Mine Type   </InputLabel>
                  <Select onChange={(e) => handleChange(e, 'mineType')} id="input-with-sx" label="Mine Type Name" variant="outlined">
                    {mineTypes?.data.map((mineTypes, index) => (
                      <MenuItem key={index} value={mineTypes.mineName}>{mineTypes.mineName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'miningArea.area')}
                  id="input-with-sx"
                  required
                  label="Minning area ( Sq. Kilometer) "
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'productionCapacity')}
                  id="input-with-sx"
                  required
                  label="Production Capacity (Cubic Meter) "
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>Mine Status </InputLabel>
                  <Select
                    onChange={(e) => handleChange(e, 'mineStatus')}
                    id="input-with-sx"
                    label="Mine Type"
                    variant="outlined"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Under Maintenance">Under Maintenance</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>

                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>Sand Type</InputLabel>
                  <Select onChange={(e) => handleChange(e, 'sandType')} id="input-with-sx" label="Sand Type" variant="outlined">
                  {sandTypes?.data?.map((sand, index) => (
                      <MenuItem key={index} value={sand.sandType}>{sand.sandType}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box> */}
              <Box sx={{ display: 'flex', gap: '.5vmax', flexDirection: 'column' }}>
                {minePoints.map((mPoint, index) => (
                  <FormControl style={{ width: '40ch', display: 'flex', flexDirection: 'column' }}>
                    <InputLabel>Set Mine Points </InputLabel>
                    <Select
                      onChange={(e) =>
                        setMinePoints((prev) => {
                          const newArr = [...prev];
                          newArr[index].title = e.target.value;
                          newArr[index].position = index;
                          newArr[index].fields = points.data?.filter((o) => o.title === e.target.value)[0].fields;
                          newArr[index].options = points.data?.filter((o) => o.title === e.target.value)[0].options
                            ? points.data?.filter((o) => o.title === e.target.value)[0].options
                            : null;
                          newArr[index].isRequired = points.data?.filter(
                            (o) => o.title === e.target.value
                          )[0].isRequired;
                          newArr[index].isNaka = points.data?.filter((o) => o.title === e.target.value)[0].isNaka;
                          newArr[index].isDiscountPointNaka = points.data?.filter(
                            (o) => o.title === e.target.value
                          )[0].isDiscountPointNaka;
                          newArr[index].remark = points.data?.filter((o) => o.title === e.target.value)[0].remark;
                          return prev;
                        })
                      }
                      id="input-with-sx"
                      label="Area"
                      variant="outlined"
                    >
                      {points?.data.map((point, pIndex) => (
                        <MenuItem key={pIndex} value={point.title}>
                          {point.title} {point.remark && <span style={{fontWeight:'bolder'}}>({point.remark})</span>}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={addMinePoint}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Add Next Point
                  </Button>
                </div>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Button disabled={isLoading} onClick={handleSaveMine} variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
        {error && <Error error={error} />}
        {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}
      </Typography>
    </>
  );
};

export default Mines;
