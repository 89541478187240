import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import {
  Card,
  Table,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import {
  useGetVehiclesMasterDataQuery,
  useUpdateVehicleMasterDataMutation,
} from '../services/api';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

const TABLE_HEADDEPRT = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Vehicle Number', label: 'Vehicle Number', alignRight: false },
  { id: 'Vehicle Type', label: 'Vehicle Type', alignRight: false },
  { id: 'Vehicle Model', label: 'Vehicle Model', alignRight: false },
  { id: 'Vehicle Height(M)', label: 'Vehicle Height(M)', alignRight: false },
  { id: 'Vehicle Width(M)', label: 'Vehicle Width(M)', alignRight: false },
  { id: 'Vehicle Length(M)', label: 'Vehicle Length(M)', alignRight: false },
  { id: 'Vehicle Height(F)', label: 'Vehicle Height(F)', alignRight: false },
  { id: 'Vehicle Width(F)', label: 'Vehicle Width(F)', alignRight: false },
  { id: 'Vehicle Length(F)', label: 'Vehicle Length(F)', alignRight: false },
  { id: 'Volume(F)', label: 'Volume(F)', alignRight: false },
  { id: 'Volume(M)', label: 'Volume(M)', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function VehicleMasterData() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [editOpen, setEditOpen] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [id, setId] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [heightInMeter, setHeightInMeter] = useState('');
  const [breadthInMeter, setBreadthInMeter] = useState('');
  const [lengthInMeter, setLengthInMeter] = useState('');
  const [heightInFoot, setHeightInFoot] = useState('');
  const [breadthInFoot, setBreadthInFoot] = useState('');
  const [lengthInFoot, setLengthInFoot] = useState('');
  const [volumeInCubicFoot, setVolumeInCubicFoot] = useState('');
  const [volumeInCubicMeter, setVolumeInCubicMeter] = useState('');
  const [lengthInInch, setLengthInInch] = useState('');
  const [breadthInInch, setBreadthInInch] = useState('');
  const [heightInInch, setHeightInInch] = useState('');
  const [lengthInCentimeter, setLengthInCentimeter] = useState('');
  const [breadthInCentimeter, setBreadthInCentimeter] = useState('');
  const [heightInCentimeter, setHeightInCentimeter] = useState('');
  const { data: vehicletypes, refetch } = useGetVehiclesMasterDataQuery({ page: page + 1, limit: rowsPerPage });
  const [updateVehicleMasterData, { isSuccess }] = useUpdateVehicleMasterDataMutation();
  const [totalVolume, setTotalVolume] = useState(null);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const [totalCost, setTotalCost] = useState(null);
  const [totalVolumemeter, setTotalVolumemeter] = useState(null);

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
    console.log(id);
  };

  const handleClose = () => {
    setEditOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vehicletypes?.data?.map((n) => n.vehicleNumber);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refetch();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    refetch();
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const emptyRows = Math.max(0, rowsPerPage - (vehicletypes?.data?.length || 0));

  const filteredVehicles = applySortFilter(vehicletypes?.data || [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredVehicles.length && !!filterName;

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenEdit = (id) => {
    const data = vehicletypes?.data?.find((item) => item._id === id);
    setId(id);
    setVehicleNumber(data?.vehicleNumber);
    setVehicleType(data?.vehicleType);
    setVehicleModel(data?.vehicleModel);
    setHeightInMeter(data?.heightInMeter);
    setBreadthInMeter(data?.breadthInMeter);
    setLengthInMeter(data?.lengthInMeter);
    setHeightInFoot(data?.heightInFoot);
    setBreadthInFoot(data?.breadthInFoot);
    setLengthInFoot(data?.lengthInFoot);
    setVolumeInCubicFoot(totalVolume);
    setVolumeInCubicMeter(totalVolumemeter);
    setLengthInInch(data?.lengthInInch);
    setBreadthInInch(data?.breadthInInch);
    setHeightInInch(data?.heightInInch);
    setLengthInCentimeter(data?.lengthInCentimeter);
    setBreadthInCentimeter(data?.breadthInCentimeter);
    setHeightInCentimeter(data?.heightInCentimeter);
    setOpenEdit(true);
  };

  const handleUpdateVehicleMasterData = async (id) => {
    await updateVehicleMasterData({
      id,
      dataToUpdate: {
        vehicleNumber,
        vehicleType,
        vehicleModel,
        heightInMeter,
        breadthInMeter,
        lengthInMeter,
        heightInFoot,
        breadthInFoot,
        lengthInFoot,
        volumeInCubicFoot,
        volumeInCubicMeter,
        lengthInInch,
        breadthInInch,
        heightInInch,
        lengthInCentimeter,
        breadthInCentimeter,
        heightInCentimeter,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      handleCloseEdit();
    }
  }, [isSuccess, refetch]);

  useEffect(() => {
    calculateTotalVolume();
  }, [lengthInFoot, breadthInFoot, heightInFoot, lengthInInch, breadthInInch, heightInInch]);

  useEffect(() => {
    calculateTotalVolumemeter();
  }, [lengthInMeter, breadthInMeter, heightInMeter, lengthInCentimeter, breadthInCentimeter, heightInCentimeter]);

  const calculateTotalVolume = () => {
    const lengthInFeet = parseFloat(lengthInFoot) + parseFloat(lengthInInch) / 12;
    const widthInFeet = parseFloat(breadthInFoot) + parseFloat(breadthInInch) / 12;
    const heightInFeet = parseFloat(heightInFoot) + parseFloat(heightInInch) / 12;

    if (!Number.isNaN(lengthInFeet) && !Number.isNaN(widthInFeet) && !Number.isNaN(heightInFeet)) {
      const calculatedTotalVolume = lengthInFeet * widthInFeet * heightInFeet;
      setTotalVolume(calculatedTotalVolume.toFixed(2));
      setVolumeInCubicFoot(calculatedTotalVolume.toFixed(2));

      if (!Number.isNaN(pricePerUnit)) {
        const calculatedTotalCost = calculatedTotalVolume * parseFloat(pricePerUnit);
        setTotalCost(calculatedTotalCost.toFixed(2));
      } else {
        setTotalCost(null);
      }
    } else {
      setTotalVolume(null);
      setTotalCost(null);
    }
  };

  const calculateTotalVolumemeter = () => {
    const lengthInMeters = parseFloat(lengthInMeter) + parseFloat(lengthInCentimeter) / 100;
    const widthInMeters = parseFloat(breadthInMeter) + parseFloat(breadthInCentimeter) / 100;
    const heightInMeters = parseFloat(heightInMeter) + parseFloat(heightInCentimeter) / 100;

    if (!Number.isNaN(lengthInMeters) && !Number.isNaN(widthInMeters) && !Number.isNaN(heightInMeters)) {
      const calculatedTotalVolumemeter = lengthInMeters * widthInMeters * heightInMeters;
      setTotalVolumemeter(calculatedTotalVolumemeter.toFixed(2));
      setVolumeInCubicMeter(calculatedTotalVolumemeter.toFixed(2));
    } else {
      setTotalVolumemeter(null);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vehicle Master Data</title>
      </Helmet>
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Are you sure you want to delete the Entry?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="contained" style={{ backgroundColor: 'red' }}>
              CANCEL
            </Button>
            <Button variant="contained">Yes</Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleCloseEdit} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit Vehicle Master Data
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={(e) => setVehicleNumber(e.target.value.toUpperCase())}
                  value={vehicleNumber}
                  id="input-with-sx"
                  required
                  label="Vehicle Number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  onChange={(e) => setVehicleType(e.target.value)}
                  value={vehicleType}
                  id="input-with-sx"
                  required
                  label="Vehicle Type"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={vehicleModel}
                  onChange={(e) => setVehicleModel(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Vehicle Model"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={lengthInMeter}
                  onChange={(e) => setLengthInMeter(e.target.value)}
                  id="input-with-sx"
                  label="Length In Meter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={lengthInCentimeter}
                  onChange={(e) => setLengthInCentimeter(e.target.value)}
                  id="input-with-sx"
                  label="Length In Centimeter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={breadthInMeter}
                  onChange={(e) => setBreadthInMeter(e.target.value)}
                  id="input-with-sx"
                  label="Breadth In Meter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={breadthInCentimeter}
                  onChange={(e) => setBreadthInCentimeter(e.target.value)}
                  id="input-with-sx"
                  label="Breadth In Centimeter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={heightInMeter}
                  onChange={(e) => setHeightInMeter(e.target.value)}
                  id="input-with-sx"
                  label="Height In Meter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={heightInCentimeter}
                  onChange={(e) => setHeightInCentimeter(e.target.value)}
                  id="input-with-sx"
                  label="Height In Centimeter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={lengthInFoot}
                  onChange={(e) => setLengthInFoot(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Length In Foot"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={lengthInInch}
                  onChange={(e) => setLengthInInch(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Length In Inch"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={breadthInFoot}
                  onChange={(e) => setBreadthInFoot(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Breadth In Foot"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={breadthInInch}
                  onChange={(e) => setBreadthInInch(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Breadth In Inch"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={heightInFoot}
                  onChange={(e) => setHeightInFoot(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Height In Foot"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={heightInInch}
                  onChange={(e) => setHeightInInch(e.target.value)}
                  id="input-with-sx"
                  required
                  label="Height In Inch"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  value={volumeInCubicFoot}
                  id="input-with-sx"
                  required
                  label="Volume In Cubic Foot"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  value={volumeInCubicMeter}
                  id="input-with-sx"
                  label="Volume In Cubic Meter"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Button onClick={() => handleUpdateVehicleMasterData(id)} variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Masters
          </Link>{' '}
          / Vehicle Master Data
        </h2>
      </div>
      <Container>
        <Box className="bg">
          <Helmet>
            <title> Vehicle Master Data </title>
          </Helmet>
          <Typography variant="h4" sx={{ mb: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            Vehicle Master Data
          </Typography>
        </Box>
        <br />

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={vehicletypes?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEADDEPRT}
                  rowCount={vehicletypes?.total || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredVehicles.map((row, index) => {
                    const {
                      id,
                      vehicleNumber,
                      vehicleType,
                      vehicleModel,
                      heightInMeter,
                      breadthInMeter,
                      lengthInMeter,
                      heightInFoot,
                      breadthInFoot,
                      lengthInFoot,
                      volumeInCubicFoot,
                      volumeInCubicMeter,
                    } = row;
                    const selectedUser = selected.indexOf(vehicleNumber) !== -1;
                    const rowNum = page * rowsPerPage + index + 1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, vehicleNumber)} />
                        </TableCell>
                        <TableCell style={{ width: '1rem', whiteSpace: 'nowrap' }}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Button variant="contained" onClick={() => handleOpenEdit(row?._id)}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: 'red' }}
                                onClick={() => handleOpenMenu(row?._id)}
                              >
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="left">{vehicleNumber}</TableCell>
                        <TableCell align="left">{vehicleType}</TableCell>
                        <TableCell align="left">{vehicleModel}</TableCell>
                        <TableCell align="left">{heightInMeter}</TableCell>
                        <TableCell align="left">{breadthInMeter}</TableCell>
                        <TableCell align="left">{lengthInMeter}</TableCell>
                        <TableCell align="left">{heightInFoot}</TableCell>
                        <TableCell align="left">{breadthInFoot}</TableCell>
                        <TableCell align="left">{lengthInFoot}</TableCell>
                        <TableCell align="left">{volumeInCubicFoot}</TableCell>
                        <TableCell align="left">{volumeInCubicMeter}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
