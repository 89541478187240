import { Navigate, Route, useRoutes, BrowserRouter, Routes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import VehicleType from './pages/VehicleType';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import MuiAccordion from './pages/Forms';
import Ajay from './pages/Patidar';
import VendorPage from './pages/VendorPage';
import MuiAccordion1 from './pages/Form1';
import MyForm from './pages/Ajay';
import MuiAccordion3 from './pages/User';
import MuiAccordion4 from './pages/Contractors';
import MuiAccordion6 from './pages/Mine';
import MuiAccordion5 from './pages/Equipments';
import Users from './pages/Users';
import Equipment1 from './pages/Equipment';
import Contractor from './pages/Contractor';
import Mines from './pages/Mines';
import Checkpoint from './pages/Checkpoint';
import PdfReport from './pages/AllVehicleReportPDF';
import State from './pages/Demographic/State';
import District from './pages/Demographic/District';
import City from './pages/Demographic/City';
import Area from './pages/Demographic/Area';
import Pincode from './pages/Demographic/Pincode';

import Districtss from './pages/Districtss';
import HumanResourceDashboard from './pages/HRDepartments/HumanResourceDashboard';

import AllEmployees from './pages/HRDepartments/AllEmployees';
import Designation from './pages/HRDepartments/Designation';
import EmployeeRole from './pages/HRDepartments/EmployeeRole';
import JobOpenings from './pages/HRDepartments/JobOpenings';
import AllResumes from './pages/HRDepartments/AllResumes';

import Department from './pages/Department';
import Vendoradd from './pages/Vendoradd';
import Clientvehicles from './pages/Clientvehicles';
import DailyReport from './pages/Dailyreport';
import Reports from './pages/Reports/Reports';
import Target from './pages/Target';
import Targetform from './pages/Targetform';
import Volume from './pages/Volume';
import Volumeform from './pages/Volumeform';
import Rate from './pages/Rate';
import Rateform from './pages/Rateform';
import PhotoUploadForm from './pages/Camera';
import Addemployeefrom from './pages/Addemployeeform';
import Adminform from './pages/Adminform';
import MyFormq from './pages/Vendorcre';
import MineTypes from './pages/Reports/Masters/Minetype';
import MineTypes1 from './pages/Reports/Masters/Minetype1';
import DropdownExample from './pages/Points';
import OtherVehicles from './pages/OtherVehicleReport';
import Point from './pages/Point';
import YourComponent from './pages/Newpage';
import Place from './pages/Place';
import Sand from './pages/Sandtype';
import CftCalculator from './pages/Elements';
import Editemployeefrom from './pages/Editemployeeform';
import Vehicles from './pages/Vehicles';
import Role from './pages/Roleassign';
import Attendence from './pages/Attendence';
import OtherVehiclesDailyReport from './pages/OtherVehicleDailyReport';
import VehicleMasterData from './pages/VehicleMasterData';
import DiscountedVehiclesReport from './pages/DiscountedVehiclesReport';
import OtherDistrictVehicleReport from './pages/OtherDisctrictVehicleReport';
import DiscountNakaPoint from './pages/AddDiscountNakaPoint';
import EditMines from './pages/EditMine';
import EmployeeActivity from './pages/EmployeeActivity';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'forms', element: <MuiAccordion /> },
        { path: 'forms1', element: <MuiAccordion1 /> },
        { path: 'Contractor', element: <Contractor /> },
        { path: 'user', element: <MuiAccordion3 /> },
        { path: 'users', element: <Users /> },
        { path: 'contractors', element: <MuiAccordion4 /> },
        { path: 'equipments', element: <MuiAccordion5 /> },
        { path: 'equipment', element: <Equipment1 /> },
        { path: 'formw', element: <MyForm /> },
        { path: 'vehicles', element: <Vehicles /> },
        { path: 'mine', element: <MuiAccordion6 /> },
        { path: 'patidar', element: <Ajay /> },
        { path: 'vendorPage', element: <VendorPage /> },
        { path: 'mines', element: <Mines /> },
        { path: 'checkpoint', element: <Checkpoint /> },
        { path: 'dailyreport', element: <DailyReport /> },
        { path: 'state', element: <State /> },
        { path: 'district', element: <District /> },
        { path: 'city', element: <City /> },
        { path: 'area', element: <Area /> },
        { path: 'pincode', element: <Pincode /> },
        { path: 'editmine', element: <EditMines /> },
        { path: 'othervehiclesdailyreport', element: <OtherVehiclesDailyReport />},
        { path: 'discountedvehiclesreport', element: <DiscountedVehiclesReport />},
        { path: 'otherdistrictvehiclereport', element: <OtherDistrictVehicleReport />},
        { path: 'districtss', element: <Districtss /> },
        { path: 'humanresourcedashboard', element: <HumanResourceDashboard /> },
        { path: 'othervehicleallreport', element: <OtherVehicles />},
        { path: 'allemployees', element: <AllEmployees /> },
        { path: 'designation', element: <Designation /> },
        { path: 'employeerole', element: <EmployeeRole /> },
        { path: 'jobopenings', element: <JobOpenings /> },
        { path: 'allresumes', element: <AllResumes /> },
        { path: 'vendoradd', element: <Vendoradd /> },
        { path: 'discountnakapoint', element: <DiscountNakaPoint />},
        { path: 'department', element: <Department /> },
        { path: 'clientvehicles', element: <Clientvehicles /> },
        { path: 'vehiclemasterdata', element: <VehicleMasterData /> },
        { path: 'reports', element: <Reports /> },
        { path: 'target', element: <Target /> },
        { path: 'targetform', element: <Targetform /> },
        { path: 'volume', element: <Volume /> },
        { path: 'volumeform', element: <Volumeform /> },
        { path: 'Rate', element: <Rate /> },
        { path: 'rateform', element: <Rateform /> },
        { path: 'cemra', element: <PhotoUploadForm /> },
        { path: 'employeefrom', element: < Addemployeefrom /> },
        { path: 'adminform', element: <Adminform /> },
        { path: 'vendor', element: < MyFormq /> },
        { path: 'Minetype', element: < MineTypes /> },
        { path: 'Minetype1', element: < MineTypes1 /> },
        { path: 'dropdownExample', element: <DropdownExample /> },
        { path: 'newpage', element: <YourComponent /> },
        { path: 'Points', element: <Point /> },
        { path: 'place', element: <Place /> },
        { path: 'Sand', element: <Sand /> },
        { path: 'CftCalculator', element: <CftCalculator /> },
        { path: 'Editemployeefrom', element: <Editemployeefrom /> },
        { path: 'vehicleType', element: <VehicleType /> },
        { path: 'role', element: < Role/> },
        { path: 'attendance', element: <Attendence /> },
        { path: 'pdfreport', element: <PdfReport /> },
        { path: 'employeeactivity', element: <EmployeeActivity />}
      ],
    },

    {
      path: 'login',
      element: <LoginPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
