import { Icon } from '@iconify/react';

const icon = (name) => <Icon icon={name} style={{ width: '1.8em', height: '1.8em' }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic:baseline-home'),
  },
  {
    title: 'Vehicle Master Data',
    icon: icon('ic:baseline-car-rental'),
    path: '/dashboard/vehiclemasterdata',
  },
  {
    title: 'Reports',
    icon: icon('game-icons:mine-truck'),
    submenus: [
      {
        title: 'All Reports',
        submenus: [
          {
            title: 'All Vehicles Report',
            path: '/dashboard/clientvehicles',
          },
          {
            title: "Employee Activity Report",
            path: '/dashboard/employeeactivity',
          },
          // {
          //   title: 'Other Mine All Vehicles Report',
          //   path: '/dashboard/othervehicleallreport',
          // },
          {
            title: 'Discounted Vehicles Report',
            path: '/dashboard/discountedvehiclesreport',
          },
          {
            title: 'Other District Vehicle Report',
            path: '/dashboard/otherdistrictvehiclereport',
          },
        ],
      },
      {
        title: 'Daily Reports',
        submenus: [
          {
            title: 'Daily Report',
            path: '/dashboard/dailyreport',
          },
          {
            title: 'Other Mine Daily Report',
            path: '/dashboard/othervehiclesdailyreport',
          },
        ],
      },
    ],
  },
  {
    title: 'Mines',
    path: '/dashboard/mine',
    icon: icon('game-icons:gold-mine'),
  },
  {
    title: 'Employe Management',
    icon: icon('clarity:employee-group-solid'),

    submenus: [
      {
        title: 'Employee Role',
        path: '/dashboard/role',
      },
      {
        title: 'Attendance',
        path: '/dashboard/attendance',
      },
    ],
  },
  {
    title: 'HR Department',
    icon: icon('grommet-icons:resources'),
    submenus: [
      {
        title: 'Human Resource Dashboard',
        path: '/dashboard/humanresourcedashboard',
      },
      {
        title: 'All Employees',
        path: '/dashboard/allemployees',
      },
      {
        title: 'Designation',
        path: '/dashboard/designation',
      },
      {
        title: 'Employee Role',
        path: '/dashboard/employeerole',
      },
    ],
  },
  {
    title: 'Admin Masters',
    icon: icon('arcticons:phone-master'),
    submenus: [
      {
        title: 'Mine Points',
        path: '/dashboard/Points',
        icon: icon('game-icons:gold-mine'),
      },
      {
        title: "Discount Naka Points",
        path: '/dashboard/discountnakapoint',
      }
    ],
  },
  {
    title: 'Super Admin Masters',
    icon: icon('arcticons:phone-master'),
    submenus: [
      {
        title: 'Admins',
        path: '/dashboard/user',
        icon: icon('bxs:user'),
      },
      {
        title: 'Demographic',
        path: '/dashboard/State',
      },
      {
        title: 'Department',
        path: '/dashboard/department',
      },
      {
        title: 'Vehicle Type',
        path: '/dashboard/vehicletype',
      }
    ],
  },
];

export default navConfig;