import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
// @mui
import { Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
// components
import HomeIcon from '@mui/icons-material/Home';
import { useGetAdminsQuery } from '../services/api';
import Iconify from '../components/iconify';
// sections
// mock
import TablePage3 from './Tablepage3';

export default function UserPage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { data: admins } = useGetAdminsQuery();
  useEffect(() => {
    console.log('triggered');
    if (user?.data?.role === 'admin') {
      navigate('/dashboard/app');
    }
  }, []);
  const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid ',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '0px',
    textAlign: 'center',
  }));
  console.log(admins);
  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        {' '}
        <HomeIcon color="primary" />{' '}
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>{' '}
          / Admin
        </h2>
      </div>

      <Container>
        <Box className="bg">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Admin
            {/* <div style={{ fontSize: '10px' }}>
              {' '}
              <HomeIcon color="primary" /> Dashboard/vendorPage
            </div> */}
          </Typography>
          <Grid container spacing={0} sx={{ flexGrow: 1 }}>
            {/* <Grid xs={12} xsOffset={0} md={0} mdOffset={4}>
              <Item style={{ textAlign: 'left', border: '0px solid  grey', color: 'black' }}>Vendors Summary</Item>
            </Grid> */}
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor1">
                <Box>{admins?.count}</Box>
                <br />
                <Box>Total Admins</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset="auto">
              <Item className="vendor2">
                <Box>{admins?.count}</Box>
                <br />

                <Box>Active Admins</Box>
              </Item>
            </Grid>
            <Grid xs={6} xsOffset={0} md={3} mdOffset={0}>
              <Item className="vendor3">
                <Box>{admins?.count}</Box>
                <br />
                <Box>Inactive Admins</Box>
              </Item>
            </Grid>
            <Grid xs={6} md={3} mdOffset={0}>
              <Item className="vendor4">
                <Box>{admins?.count}</Box>
                <br />
                <Box>Today Admins</Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          <Link to="/dashboard/Adminform" style={{ color: 'white', textDecoration: 'none' }}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Add New Admin
            </Button>
          </Link>
        </Stack>
        <TablePage3 />
      </Container>
    </>
  );
}
