import { useState } from 'react';
import { filter } from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Grid,
  Box,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
} from '@mui/material';
// components
import { useGetAdminsQuery, useGetStatesQuery, useGetCitiesQuery, useGetDistrictsQuery, useEditAdminMutation } from '../services/api';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import Success from '../components/success/Success';
import Error from '../components/error/Error';

// ----------------------------------------------------------------------

const TABLE_HEAD2 = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'number', label: 'S.NO', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'Full Name', label: 'Full Name', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'Mobile', label: 'Mobile', alignRight: false },
  { id: 'Company name', label: 'Company name', alignRight: false },
  { id: 'State', label: 'State', alignRight: false },
  { id: 'District', label: 'District', alignRight: false },
  { id: 'City', label: 'City', alignRight: false },
  { id: 'Profile Name', label: 'Profile Name', alignRight: false },
];

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const emailStyle = {
    textTransform: 'lowercase',
  };
  const { data: admins } = useGetAdminsQuery();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const { data: states } = useGetStatesQuery();
  const { data: districts } = useGetDistrictsQuery();
  const { data: cities } = useGetCitiesQuery();
  const [selected, setSelected] = useState([]);
  const [editOpen, setEditOpen] = useState(null);
  const [id, setId] = useState(null);
  const [orderBy, setOrderBy] = useState('name');
  const [userData, setUserData] = useState({});
  const [filterName, setFilterName] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [mineData, setMineData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editAdmin] = useEditAdminMutation();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = admins?.data?.map((n) => n.name) || [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - admins?.data?.length) : 0;

  const filteredUsers = applySortFilter(admins?.data || [], getComparator(order, orderBy), filterName);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});
  const isNotFound = !filteredUsers.length && !!filterName;

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  const handleOpenEdit = (id) => {
    const selectedType = admins?.data?.find((type) => type._id === id);
    console.log(selectedType);
    setEditedUserData(selectedType);
    setId(id);
    setIsEditOpen(true);
  };

  const handleSaveEdit = async (id) => {
    setIsLoading(true);
    await editAdmin({
      id,
      name: editedUserData?.name,
      email: editedUserData?.email,
      phone: editedUserData?.phone,
      companyName: editedUserData?.companyName,
      stateName: editedUserData?.stateName,
      districtName: editedUserData?.districtName,
      cityName: editedUserData?.cityName,
      profileName: editedUserData?.profileName,
      status: editedUserData?.status,
    });
    setIsLoading(false);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
      setIsEditOpen(false);
    }, 2000);
  };

  const handleClose = () => {
    setEditOpen(null);
  };

  const handleDeleteConfirmation = async () => {
    // Call the delete function here
    // await deleteVehicleType(id); // Assuming you have defined handleDeleteVehicleType function
    handleClose();
  };

  return (
    <>
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isEditOpen}
        onClose={handleEditClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleEditClose} icon={'carbon:close'} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit Admin Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="input-with-sx"
                value={editedUserData?.name || ''}
                onChange={(e) => setEditedUserData({ ...editedUserData, name: e.target.value })}
                required
                label=" Full Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="form-input"
                name="password"
                onChange={(e) => setEditedUserData({ ...editedUserData, password: e.target.value })}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email "
                value={editedUserData?.email || ''}
                onChange={(e) => setEditedUserData({ ...editedUserData, email: e.target.value })}
                type="email"
                required
                id="form-input"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Mobile Number"
                value={editedUserData?.phone || ''}
                onChange={(e) => setEditedUserData({ ...editedUserData, phone: e.target.value })}
                type="number"
                inputProps={{ pattern: '[0-9]{10}' }}
                error={!!mobileError}
                helperText={mobileError}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Company name "
                value={editedUserData?.companyName || ''}
                onChange={(e) => setEditedUserData({ ...editedUserData, companyName: e.target.value })}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>State*</InputLabel>
                  <Select
                    required
                    value={editedUserData?.stateName || ''}
                    onChange={(e) => setEditedUserData({ ...editedUserData, stateName: e.target.value })}
                    id="input-with-sx"
                    label="State"
                    variant="outlined"
                  >
                    {states?.data?.map((state, index) => (
                      <MenuItem key={index} value={state.stateName}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>District*</InputLabel>
                  <Select
                    value={editedUserData?.districtName || ''}
                    onChange={(e) => setEditedUserData({ ...editedUserData, districtName: e.target.value })}
                    id="input-with-sx"
                    label="District"
                    variant="outlined"
                  >
                    {districts?.data
                      ?.filter((o) => o.stateName === editedUserData?.stateName)
                      ?.map((district, index) => (
                        <MenuItem key={index} value={district.districtName}>
                          {district.districtName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>City*</InputLabel>
                  <Select
                    value={editedUserData?.cityName || ''}
                    onChange={(e) => setEditedUserData({ ...editedUserData, cityName: e.target.value })}
                    id="input-with-sx"
                    label="City"
                    variant="outlined"
                  >
                    {cities?.data
                      ?.filter((o) => o.districtName === editedUserData?.districtName)
                      ?.map((city, index) => (
                        <MenuItem key={index} value={city.cityName}>
                          {city.cityName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Profile Name "
                value={editedUserData?.profileName || ''}
                onChange={(e) => setEditedUserData({ ...editedUserData, profileName: e.target.value })}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel>Status*</InputLabel>
                <Select
                  value={editedUserData?.status || ''}
                  onChange={(e) => setEditedUserData({ ...editedUserData, status: e.target.value })}
                  id="input-with-sx"
                  label="Status"
                  variant="outlined"
                >
                  <MenuItem value="Active" defaultChecked>
                    Active
                  </MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <Button onClick={() => handleSaveEdit(id)} variant="contained" endIcon={<SaveIcon />}>
            Save
          </Button>
          {error && <Error error={error} />}
          {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}
        </Box>
      </Modal>
      <Card style={{ marginTop: '2vmax' }}>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer className="table-new">
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD2}
                rowCount={admins?.data?.length || 0}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const { id, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;
                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleOpenEdit(row?._id)}>
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <b>{rowNum}</b>
                      </TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">{row.name} </TableCell>
                      <TableCell style={emailStyle} align="left">
                        {row.email}
                      </TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.companyName}</TableCell>
                      <TableCell align="left">{row.stateName}</TableCell>
                      <TableCell align="left">{row.districtName}</TableCell>
                      <TableCell align="left">{row.cityName}</TableCell>
                      <TableCell align="left">{row.profileName}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 30 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
