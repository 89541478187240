import { filter } from 'lodash';
import React, { useState } from 'react';
import '../Form1.css';
import Modal from '@mui/material/Modal';

// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
// components
import SaveIcon from '@mui/icons-material/Save';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import EMPLOYLIST from '../../_mock/employ';
import {
  useGetEmployeeDataQuery,
  useEditEmployeeRoleMutation,
  useUpdateEmployeeDataMutation,
  useGetEmployeeRoleQuery,
  useDeleteEmployeeRoleMutation,
} from '../../services/api';
import Error from '../../components/error/Error';
import Success from '../../components/success/Success';

// ----------------------------------------------------------------------

const TABLE_HEAD3 = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'number', label: 'S.NO', alignRight: false },
  { id: 'RoleName', label: 'Role Name', alignRight: false },
  { id: 'Roles', label: 'Roles', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.roleName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function EmployeeRoleTable() {
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: 'absolute',
    right: '10%',
    top: '5%',
  };
  const { data: roles } = useGetEmployeeRoleQuery();
  const [id, setId] = useState(null);
  const [editModalOpen, setEditModalOpen] = React.useState(null);
  const [editOpen, setEditOpen] = React.useState(null);
  const [page, setPage] = useState(0);
  const [updateEmployeeRole] = useEditEmployeeRoleMutation();
  const [updateEmployeeData, { data, error, isLoading, isSuccess }] = useUpdateEmployeeDataMutation();
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('roleName');

  const [filterName, setFilterName] = useState('');
  const [employeeRole, setEmployeeRole] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [point, setPoint] = useState(null);
  const [desc, setDesc] = useState(null);
  const { data: employees } = useGetEmployeeDataQuery();
  const [deleteEmployeeRole] = useDeleteEmployeeRoleMutation();
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleEmployeeDataUpdate = async () => {
    await updateEmployeeData({ id, dataToUpdate: { point } });
    handleClose();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles?.data?.map((n) => n.roleName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - roles?.data?.length) : 0;

  const filteredUsers = applySortFilter(roles?.data || [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
    console.log(id);
  };
  const handleClose = () => {
    setEditOpen(null);
  };

  const handleDeleteConfirmation = async () => {
    await deleteEmployeeRole(id);
    handleClose();
  };
  const handleEditClose = () => {
    setEditModalOpen(null);
  };
  const roleOptions = [
    { value: 'Dashboard', label: 'Dashboard' },
    { value: 'Reports', label: 'Reports' },
    { value: 'Vehicle Master Data', label: 'Vehicle Master Data' },
    { value: 'Active Districts', label: 'Active Districts' },
    { value: 'Mines', label: 'Mines' },
    { value: 'Equipments', label: 'Equipments' },
    { value: 'Vendors', label: 'Vendors' },
    { value: 'Dumpyards', label: 'Dumpyards' },
    { value: 'Target', label: 'Target' },
    { value: 'Volume', label: 'Volume' },
    { value: 'HR Department', label: 'HR Department' },
    { value: 'Employe Management', label: 'Employe Management' },
    // Add more options as needed
  ];
  const handleChange3 = (selectedValues) => {
    setSelectedRoles(selectedValues);
  };
  const handleEditOpen = (id) => {
    console.log(id);
    const selectedRoles = roles?.data?.find((role) => role._id === id)?.roleList.map((item) => item.value);
    const roleName = roles?.data?.find((role) => role._id === id)?.roleName;
    const description = roles?.data?.find((role) => role._id === id)?.description;
    setEmployeeRole(roleName);
    setSelectedRoles(selectedRoles);
    setDesc(description);
    setId(id);
    setEditModalOpen(true);
  };
  const handleEditSave = async () => {
    // Check if the fields are not empty or null
    if (!employeeRole || !desc || !selectedRoles.length) {
      alert('Please fill all the fields before saving.');
      return; // Stop the function if any field is empty or null
    }

    // Prepare the data to send
    const dataToUpdate = {
      roleName: employeeRole,
      description: desc,
      roleList: selectedRoles.map((role) => ({
        value: role,
        label: roleOptions.find((option) => option.value === role)?.label || role,
      })),
    };

    try {
      // Call the mutation with validated data
      await updateEmployeeRole({ id, ...dataToUpdate });
      setSelectedRoles([]);
      handleEditClose();
      console.log('Role updated successfully!');
    } catch (error) {
      console.error('Failed to update role:', error);
    }
  };

  return (
    <>
      {error && <Error error={error} />}
      {isSuccess && <Success isSuccess={isSuccess} successMessage={'Saved successfully'} />}
      <Modal
        open={editModalOpen}
        onClose={handleEditClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleEditClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit Employee Role
          </Typography>

          <Typography
            id="keep-mounted-modal-description"
            sx={{ mt: 2 }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={0}>
                <Stack direction={'column'} spacing={3}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <TextField
                      id="input-with-sx"
                      required
                      label="Role Name"
                      value={employeeRole}
                      onChange={(e) => setEmployeeRole(e.target.value)}
                      variant="outlined"
                      style={{ width: '100%' }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={selectedRoles}
                        onChange={(event) => setSelectedRoles(event.target.value)}
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select Role</em>;
                          }
                          return selected.join(', ');
                        }}
                      >
                        {roleOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                    <textarea
                      placeholder="Description"
                      name=""
                      onChange={(e) => setDesc(e.target.value)}
                      id=""
                      value={desc}
                      cols="50"
                      rows="10"
                      style={{ outline: 'none', padding: '1vmax' }}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Button onClick={() => handleEditSave(id)} variant="contained" endIcon={<SaveIcon />}>
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Card style={{ marginTop: '2vmax' }}>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer className="table-newemp">
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD3}
                rowCount={roles?.data?.length || 0}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const { _id, roleName, roleList, description } = row;
                  const selectedUser = selected.indexOf(roleName) !== -1;

                  // Calculate the sequential number for each row
                  const rowNum = page * rowsPerPage + index + 1;
                  return (
                    <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, roleName)} />
                      </TableCell>

                      <TableCell>
                        <Button variant="contained" onClick={() => handleEditOpen(_id)}>
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: 'red' }}
                          onClick={() => handleOpenMenu(_id)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                          Delete
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <b>{rowNum}</b>
                      </TableCell>
                      <TableCell align="left">{roleName}</TableCell>
                      <TableCell align="left">{roleList.map((item) => `${item.value}`).join(', ')}</TableCell>
                      <TableCell align="left">{description}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 30 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={roles?.data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
