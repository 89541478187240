import { Helmet } from 'react-helmet-async';
// @mui
import { useState, useEffect } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { Grid, Typography, Box, TextField, Stack, InputAdornment, IconButton } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Navigate, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify/Iconify';
import { useSaveEmployeeDataMutation, useGetDepartmentQuery, useGetAreasQuery, useGetCitiesQuery, useGetStatesQuery, useGetMineQuery, useGetDesignationQuery, useGetEmployeeRoleQuery, useGetPointsQuery } from '../services/api';
import Success from '../components/success/Success';
import Error from '../components/error/Error';
// components
const Addemployeefrom = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState();
  const { data: departments } = useGetDepartmentQuery();
  const { data: designations } = useGetDesignationQuery();
  const { data: employeeRoles } = useGetEmployeeRoleQuery();
  const { data: points } = useGetPointsQuery();
  const [department, setDepartment] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [employeeRole, setEmployeeRole] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { data: states, isLoading: isStatesLoading, isSuccess: isStatesSuccess, isError: isStatesError, error: statesError } = useGetStatesQuery();
  const { data: cities, isLoading: isCitiesLoading, isSuccess: isCitiesSuccess, isError: isCitiesError, error: citiesError } = useGetCitiesQuery();
  const { data: areas, isLoading: isAreasLoading, isSuccess: isAreasSuccess, isError: isAreasError, error: areasError } = useGetAreasQuery();
  const { data: mines } = useGetMineQuery();
  const [mine, setMine] = useState(null);
  const [point, setPoint] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [saveEmployeeData, { error, isLoading, isSuccess }] = useSaveEmployeeDataMutation();
  const [employeeData, setEmployeeData] = useState({
    name: '',
    email: '',
    mobile: '',
    stateName: '',
    cityName: '',
    area: '',
    pincode: '',
    familyName: '',
    familyRelation: '',
    familyMobile: '',
    password: '',
    // profileLink: '',
    // adharLink: '',
    // panLink: '',
    // biodataLink: '',
  });
  // console.log(employeeData)
  const [uploadedFiles, setUploadedFiles] = useState({
    profileLink: null,
    aadharLink: null,
    panLink: null,
    bioDataLink: null,
  });
  const handleFileUpload = (e, field) => {
    const file = e.target.files[0];
    setUploadedFiles({ ...uploadedFiles, [field]: file });
  };
  // const [isChecked, setIsChecked] = useState(false);
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  const handleSaveEmployeeData = async () => {
    setIsSaving(true); // Start loading
    try{
    const formData = new FormData();
    formData.append('name', employeeData.name);
    formData.append('email', employeeData.email);
    formData.append('phone', employeeData.mobile);
    formData.append('stateName', employeeData.stateName);
    formData.append('cityName', employeeData.cityName);
    formData.append('area', employeeData.area);
    formData.append('pincode', employeeData.pincode);
    formData.append('familyName', employeeData.familyName);
    formData.append('familyRelation', employeeData.familyRelation);
    formData.append('familyMobile', employeeData.familyMobile);
    formData.append('password', employeeData.password);
    formData.append('department', department);
    formData.append('designation', designation);
    formData.append('role', employeeRole);
    formData.append('point', point);
    formData.append('profileLink', uploadedFiles.profileLink);
    formData.append('aadharLink', uploadedFiles.aadharLink);
    formData.append('panLink', uploadedFiles.panLink);
    formData.append('bioDataLink', uploadedFiles.bioDataLink);
    formData.append('mineName', mine);
    await saveEmployeeData(formData);}
    catch(error){
      console.log(error)
    } finally{
      setIsSaving(false);
    }
    // saveEmployeeData(employeeData); // Assuming saveEmployeeData function handles API call
  };
  const handleChange = (e, field) => {
    setEmployeeData({ ...employeeData, [field]: e.target.value });
  }
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/allemployees');
      }, 800);
    }
  }, [isSuccess])
  // const handleCheckboxChange = (event) => {
  //   const isChecked = event.target.checked;
  //   setFormData((...formData) => {
  //     if (isChecked) {
  //       If "Same Address as Above" is checked, copy current address to permanent address
  //       return {
  //         ...formData,
  //         sameAddress: true,
  //         permanentStateName: copiedAddress.stateName,
  //         permanentCityName: copiedAddress.cityName,
  //         permanentArea: copiedAddress.area,
  //         permanentPincode: copiedAddress.pincode,
  //       };
  //     }
  //       return {
  //         ...formData,
  //         sameAddress: isChecked,
  //         permanentStateName: '',
  //         permanentCityName: '',
  //         permanentArea: '',
  //         permanentPincode: '',
  //       };
  //     If unchecked, reset permanent address fields
  //     return {
  //       ...prevData,
  //       sameAddress: isChecked,
  //       permanentState: '',
  //       permanentCity: '',
  //       permanentArea: '',
  //       permanentPincode: '',
  //     };
  //   });
  // };
  // const [copiedAddress, setCopiedAddress] = useState({
  //   stateName: '',
  //   cityName: '',
  //   area: '',
  //   pincode: '',
  // });
  // const updateCopiedAddress = () => {
  //   setCopiedAddress({
  //     stateName: formData.stateName,
  //     cityName: formData.cityName,
  //     area: formData.area,
  //     pincode: formData.pincode,
  //   });
  // };
  const [filteredPoints, setFilteredPoints] = useState([]);

  const handleMineChange = (selectedMine) => {
    const selectedMineData = mines?.data.find((mine) => mine.mineName === selectedMine);
    if (selectedMineData) {
      setFilteredPoints(selectedMineData.points);
    } else {
      setFilteredPoints([]);
    }
    setMine(selectedMine); // If you want to store the selected mine separately
  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


  return (
    <>
      <h2> Add Employees</h2>

      <form>
        <Grid container spacing={2}>


          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'name')} label="Full Name" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'email')} id='form-input' label="Email" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'mobile')} label="Mobile" variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'password')} id='form-input' label="Password" variant="outlined" type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField label="Current Address  " variant="outlined" fullWidth /> */}
          </Grid>

          <Grid item xs={12} >
            {/* <TextField label="Current Address  " variant="outlined" fullWidth /> */}
            <h3>Address </h3>
          </Grid>


          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>State   </InputLabel>
              <Select onChange={(e) => handleChange(e, 'stateName')} id="input-with-sx" label="State" variant="outlined">
                {states?.data.map((state, index) => (
                  <MenuItem key={index} value={state.stateName}>{state.stateName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>City   </InputLabel>
              <Select onChange={(e) => handleChange(e, 'cityName')} id="input-with-sx" label="City" variant="outlined">
                {cities?.data.filter(o => o?.stateName?.toLowerCase() === employeeData?.stateName.toLowerCase())?.map((cities, index) => (
                  <MenuItem key={index} value={cities.cityName}>{cities.cityName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Area  </InputLabel>
              <Select onChange={(e) => handleChange(e, 'area')} id="input-with-sx" label="Area" variant="outlined">
                {areas?.data.filter(o => o.cityName?.toLowerCase() === employeeData?.cityName.toLowerCase())?.map((area, index) => (
                  <MenuItem key={index} value={area.area}>{area.area}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'pincode')} label="Pincode " variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={12}>
            {/* <TextField label="Pincode " variant="outlined" fullWidth /> */}
            <h3>family References </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'familyName')} label="name" variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'familyRelation')} label="Relation" variant="outlined" fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField onChange={(e) => handleChange(e, 'familyMobile')} label="Mobile" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <></>
          </Grid>

          <Grid item xs={12} sm={6}>
            <span > upload Profile &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button onChange={(e) => handleFileUpload(e, 'profileLink')} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </span>
          </Grid>

          <Grid item xs={12} sm={6}>

            <span >upload Aadhar &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button onChange={(e) => handleFileUpload(e, 'aadharLink')} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </span>
          </Grid>

          <Grid item xs={12} sm={6}>
            <span > upload pan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button onChange={(e) => handleFileUpload(e, 'panLink')} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </span>

          </Grid>

          <Grid item xs={12} sm={6}>
            {/* <TextField label="upload Biodata " variant="outlined" fullWidth /> */}
            <span > upload Biodata &nbsp;&nbsp;&nbsp;&nbsp;
              <Button onChange={(e) => handleFileUpload(e, 'bioDataLink')} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </span>

          </Grid>


          <Grid item xs={12}>
            <h3>Assign Role And Designation  </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel> Department</InputLabel>
              <Select onChange={(e) => setDepartment(e.target.value)} id="input-with-sx" label="Department" variant="outlined">
                {departments?.data.map((department, index) => (
                  <MenuItem key={index} value={department.departmentName}>{department.departmentName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Designation</InputLabel>
              <Select onChange={(e) => setDesignation(e.target.value)} id="input-with-sx" label="Designation" variant="outlined">
                {designations?.data.map((designation, index) => (
                  <MenuItem key={index} value={designation.designationName}>{designation.designationName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Role</InputLabel>
              <Select onChange={(e) => setEmployeeRole(e.target.value)} id="input-with-sx" label="Role" variant="outlined">
                {employeeRoles?.data.map((empRole, index) => (
                  <MenuItem key={index} value={empRole.roleName}>{empRole.roleName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h3>Assign Mine</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Mine</InputLabel>
              <Select onChange={(e) => handleMineChange(e.target.value)} id="input-with-sx" label="Mine" variant="outlined">
                {mines?.data.map((mine, index) => (
                  <MenuItem key={index} value={mine.mineName}>
                    {mine.mineName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h3>Assign Mine Point </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Mine Point</InputLabel>
              <Select onChange={(e) => setPoint(e.target.value)} id="input-with-sx" label="Mine Point" variant="outlined">
                {filteredPoints.map((point, index) => (
                  <MenuItem key={index} value={point.title}>
                    {point.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


        </Grid>

        <br />
        <br />
        <Button onClick={handleSaveEmployeeData} variant="contained" endIcon={isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
  disabled={isSaving}>
          Save
        </Button>
        {error && <Error error={error} />}
        {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}

      </form>


    </>
  );
};

export default Addemployeefrom;
