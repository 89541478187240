import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography, Box, TextField, Stack } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// components
const Contractor = () => {
  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  return (
    <>
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <h2>Contractors</h2>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Contractors"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="Cluster Name"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <MailOutlineIcon />

                <TextField id="input-with-sx" required label="Email" variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  required
                  label="District Name"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" required label="Address" variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" type="" label="Enabled" variant="outlined" style={{ width: '40ch' }} />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label=" Mobile" variant="outlined" style={{ width: '40ch' }} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Contractor Type" variant="outlined" style={{ width: '40ch' }} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="State Name" variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField id="input-with-sx" label="Location" variant="outlined" style={{ width: '40ch' }} />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  id="input-with-sx"
                  type="number"
                  label="Pin code"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Button variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
      </Typography>
    </>
  );
};

export default Contractor;
