import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HomeIcon from '@mui/icons-material/Home';
import Iconify from '../components/iconify';
import { useGetEmployeeActivityReportPDFQuery, useGetEmployeeActivityReportExcelQuery } from '../services/api';

export default function EmployeeActivity() {
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleDownloadPDF = async () => {
    setLoadingPDF(true);
    try {
      const response = await fetch('https://backend.dgmining.in/api/v1/report/employeeActivityReport/pdf', {
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.toString());
      document.body.appendChild(link);
      link.click();
      setLoadingPDF(false);
    } catch (error) {
      console.error('Error downloading PDF report:', error);
      setLoadingPDF(false);
    }
  };
  

  const handleDownloadExcel = async () => {
    setLoadingExcel(true);
    try {
      const response = await fetch('https://backend.dgmining.in/api/v1/report/employeeActivityReport/excel', {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const filenameHeader = response.headers.get('content-disposition');
      const filename = filenameHeader.split('filename=')[1].replace(/"/g, '');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.toString());
      document.body.appendChild(link);
      link.click();
      setLoadingExcel(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoadingExcel(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Employee Activity Report</title>
      </Helmet>
      <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center', gap: '.5vmax' }}>
        <HomeIcon color="primary" />
        <h2>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Dashboard
          </Link>
          {' / Employee Activity'}
        </h2>
      </div>

      <Container>
        <Box className="bg">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Employee Activity Report
          </Typography>
        </Box>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <LoadingButton
            onClick={handleDownloadPDF}
            loading={loadingPDF}
            variant="contained"
            startIcon={<Iconify icon="carbon:report" />}
          >
            Download Employee Activity Report (PDF)
          </LoadingButton>
          <LoadingButton
            onClick={handleDownloadExcel}
            loading={loadingExcel}
            variant="contained"
            startIcon={<Iconify icon="carbon:report" />}
          >
            Download Employee Activity Report (Excel)
          </LoadingButton>
        </Stack>
      </Container>
    </>
  );
}
