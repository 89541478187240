import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import './Form1.css';

// @mui
import {
  Card,
  Table,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Modal,
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
// components
import { useGetDesignationQuery, useDeleteDesignationMutation, useEditDesignationMutation, useGetDepartmentQuery } from '../services/api';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import Success from '../components/success/Success';
import Error from '../components/error/Error';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'number', label: 'S.NO', alignRight: false },
  { id: 'Action', label: 'Action  ', alignRight: false },
  { id: 'Designation Name', label: 'Designation Name', alignRight: false },
  { id: 'Department', label: 'Department', alignRight: false },
  { id: 'Parent', label: 'Parent', alignRight: false },
  { id: 'Management Level', label: 'Management Level', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const { data: departments } = useGetDepartmentQuery();
  const { data: designations } = useGetDesignationQuery();
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editOpen, setEditOpen] = useState(null);
  const [id, setId] = useState(null);
  const [department, setDepartment] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [parent, setParent] = useState(null);
  const [managementLevel, setManagementLevel] = useState(null);
  const [desc, setDesc] = useState(null);
  const [updateDesignation, { error, isLoading, isSuccess }] = useEditDesignationMutation();
  const [open, setOpen] = useState(false);
  console.log(designations);
  
  const handleOpenEdit = (id) => {
    const data = designations?.data.find((item) => item._id === id);
    setDepartment(data?.departmentName);
    setDesignation(data?.designationName);
    setParent(data?.parent);
    setManagementLevel(data?.managementLevel);
    setDesc(data?.description);
    setId(id);
    setOpen(true);
  }
  const handleCloseEdit = () => setOpen(false);
  
  const handleDesignationSave = async (id) => {
    await updateDesignation({ id, departmentName: department, description: desc, designationName: designation, parent, managementLevel });
    handleCloseEdit();
  }

  const [deleteDesignation, { isSuccess: isDeleteSuccess, isError: isDeleteError, reset }] = useDeleteDesignationMutation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenMenu = (id) => {
    setId(id);
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditOpen(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteDesignation(id).unwrap();
      handleClose();
    } catch (error) {
      console.log('Failed To Delete:', error);
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('Designation Deleted successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // Clear success message after 5 seconds
    }

    if (isDeleteError) {
      setErrorMessage('Failed to delete. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000); // Clear error message after 5 seconds
    }

    // Reset mutation status to handle subsequent operations
    return () => reset();
  }, [isDeleteSuccess, isDeleteError, reset]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = designations?.data?.map((n) => n.designationName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (designations?.data?.length || 0)) : 0;

  const filteredUsers = applySortFilter(designations?.data, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      {successMessage && <Success successMessage={successMessage} />}
      {errorMessage && <Error error={errorMessage} />}
      <Modal
        open={open}
        onClose={handleCloseEdit}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleCloseEdit} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Edit Designation
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Department</InputLabel>
                  <Select value={department} onChange={(e) => setDepartment(e.target.value)} id="input-with-sx" label="Department Name " variant="outlined">
                    {departments?.data?.map((department, index) => (
                        <MenuItem key={index} value={department.departmentName}>{department.departmentName}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField value={designation} onChange={(e) => setDesignation(e.target.value)} id="input-with-sx" required label=" Designation Name" variant="outlined" fullWidth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Parent</InputLabel>
                  <Select value={parent} onChange={(e) => setParent(e.target.value)} id="input-with-sx" label="Parent Name" variant="outlined">
                    {designations?.data?.map((designation, index) => (
                        <MenuItem key={index} value={designation.designationName}>{designation.designationName}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
             
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-name-label">Select Management Level</InputLabel>
                  <Select value={managementLevel} onChange={(e) => setManagementLevel(e.target.value)} id="input-with-sx" label="Management Level" variant="outlined">
                        <MenuItem value="High Level">High Level</MenuItem>
                        <MenuItem value="Mid Level">Mid Level</MenuItem>
                        <MenuItem value="Low Level">Low Level</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                  <textarea
                    onChange={(e) => setDesc(e.target.value)}
                    placeholder="Description"
                    name=""
                    id=""
                    cols="50"
                    rows="10"
                    value={desc}
                    style={{ outline: 'none', padding: '1vmax' }}
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <Button onClick={() => handleDesignationSave(id)} variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Card style={{ marginTop: '2vmax' }}>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={designations?.data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Scrollbar>
          <TableContainer className="table-new">
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={designations?.data?.length || 0}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const { id, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;
                  const rowNum = page * rowsPerPage + index + 1;
                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                      </TableCell>
                      <TableCell align="left">
                        <b>{rowNum}</b>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleOpenEdit(row?._id)}>
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                          Edit
                        </Button>
                        <Button sx={{ color: 'error.main' }} onClick={() => handleOpenMenu(row._id)}>
                          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                          Delete
                        </Button>
                      </TableCell>
                      <TableCell align="left">{row?.designationName}</TableCell>
                      <TableCell align="left">{row?.departmentName} </TableCell>
                      <TableCell align="left">{row?.parent} </TableCell>
                      <TableCell align="left">{row?.managementLevel} </TableCell>
                      <TableCell align="left">{row?.description}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 30 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
