import { Helmet } from 'react-helmet-async';
// @mui
import { useState, useEffect } from 'react';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { Grid, Typography, Box, TextField, Stack, InputAdornment, IconButton  } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Navigate, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify/Iconify';
import { useSaveEmployeeDataMutation, useGetDepartmentQuery, useGetMineQuery, useGetDesignationQuery, useGetEmployeeRoleQuery, useGetPointsQuery } from '../services/api';
import Success from '../components/success/Success';
import Error from '../components/error/Error';
// components
const Editemployeefrom = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState();
  const { data: departments } = useGetDepartmentQuery();
  const { data: designations } = useGetDesignationQuery();
  const { data: employeeRoles } = useGetEmployeeRoleQuery();
  const { data: points } = useGetPointsQuery();
  const [department, setDepartment] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [employeeRole, setEmployeeRole] = useState(null);
  const { data: mines } = useGetMineQuery();
  const [mine, setMine] = useState(null);
  console.log(mines, 'mines')
  const [point, setPoint] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [saveEmployeeData, { error, isLoading, isSuccess }] = useSaveEmployeeDataMutation();
  const [employeeData, setEmployeeData] = useState({
    name: '',
    email: '',
    mobile: '',
    stateName: '',
    cityName: '',
    area: '',
    pincode: '',
    familyName: '',
    familyRelation: '',
    familyMobile: '',
    password: '',
    // profileLink: '',
    // adharLink: '',
    // panLink: '',
    // biodataLink: '',
  });
  // console.log(employeeData)
  const [uploadedFiles, setUploadedFiles] = useState({
    profileLink: null,
    aadharLink: null,
    panLink: null,
    bioDataLink: null,
  });
  const handleFileUpload = (e, field) => {
    const file = e.target.files[0];
    setUploadedFiles({ ...uploadedFiles, [field]: file });
  };
  // const [isChecked, setIsChecked] = useState(false);
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };
  const handleSaveEmployeeData = async () => {
    const formData = new FormData();
    formData.append('name', employeeData.name);
    formData.append('email', employeeData.email);
    formData.append('phone', employeeData.mobile);
    formData.append('stateName', employeeData.stateName);
    formData.append('cityName', employeeData.cityName);
    formData.append('area', employeeData.area);
    formData.append('pincode', employeeData.pincode);
    formData.append('familyName', employeeData.familyName);
    formData.append('familyRelation', employeeData.familyRelation);
    formData.append('familyMobile', employeeData.familyMobile);
    formData.append('password', employeeData.password);
    formData.append('department', department);
    formData.append('designation', designation);
    formData.append('role', employeeRole);
    formData.append('point', point);
    formData.append('profileLink', uploadedFiles.profileLink);
    formData.append('aadharLink', uploadedFiles.aadharLink);
    formData.append('panLink', uploadedFiles.panLink);
    formData.append('bioDataLink', uploadedFiles.bioDataLink);
    formData.append('mineName', mine);
    await saveEmployeeData(formData);
      // saveEmployeeData(employeeData); // Assuming saveEmployeeData function handles API call
  };
  const handleChange = (e, field) => {
    setEmployeeData({ ...employeeData, [field]: e.target.value });
  }
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/allemployees');
      }, 800);
    }
  }, [isSuccess])

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


  return (
    <>
      <br />
      <br />
      
      <h2> Edit Employees</h2>

      <form>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            {/* <TextField label="Pincode " variant="outlined" fullWidth /> */}
            <h3>Assign Role And Designation  </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel> Department</InputLabel>
              <Select onChange={(e) => setDepartment(e.target.value)} id="input-with-sx" label="Department" variant="outlined">
                {departments?.data.map((department, index) => (
                  <MenuItem key={index} value={department.departmentName}>{department.departmentName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Designation</InputLabel>
              <Select onChange={(e) => setDesignation(e.target.value)} id="input-with-sx" label="Designation" variant="outlined">
                {designations?.data.map((designation, index) => (
                  <MenuItem key={index} value={designation.designationName}>{designation.designationName}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Role</InputLabel>
              <Select onChange={(e) => setEmployeeRole(e.target.value)} id="input-with-sx" label="Role" variant="outlined">
                {employeeRoles?.data.map((empRole, index) => (
                  <MenuItem key={index} value={empRole.roleName}>{empRole.roleName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h3>Assign Mine Point </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Mine Point</InputLabel>
              <Select onChange={(e) => setPoint(e.target.value)} id="input-with-sx" label="Role" variant="outlined">
                {points?.data.map((point, index) => (
                  <MenuItem key={index} value={point.title}>{point.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {/* <TextField label="Pincode " variant="outlined" fullWidth /> */}
            <h3>Assign Mine  </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel> Mine</InputLabel>
              <Select onChange={(e) => setMine(e.target.value)} id="input-with-sx" label="Role" variant="outlined">
                {mines?.data.map((mine, index) => (
                  <MenuItem key={index} value={mine.mineName}>{mine.mineName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <br />
        <br />
        <Button onClick={handleSaveEmployeeData} variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
        {error && <Error error={error} />}
        {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}

      </form>


    </>
  );
};

export default Editemployeefrom;
