import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const Minetype12 = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),

  status: sample(['active', 'banned']),


  Area: sample([
    'Operators',
    'Managers',
    'Operators',
    'Managers',

  ]),
  AJAY: sample([
    'Lorem, ',
    'voluptatibus ',
    'aliquid ',
    'Magnam',
    'voluptatum',
    'amet ',
    'voluptatum ',
    'Magnam  ',
    'laborum ',
    'Magnam ',
    'voluptatum ',
  ]),
  Minename: sample([
    'Indore ',
    'Bhopal',
    'Mandideep',
    'Sehore',
    'Khategaon',
    'Mumbai',
    'Dehli',
    'Noida',
  ]),

  Description: sample([
    'Indore ',
    'Bhopal',
    'Mandideep',
    'Sehore',
    'Khategaon',
    'Mumbai',
    'Dehli',
    'Noida',
  ]),
  State: sample([
    'Narmadapuram ',
    'Bhopal',
    'Mandideep',
    'Sehore',
    'Khategaon',
    'Mumbai',
    'Dehli',
    'Noida',
  ]),
  license: sample([
    'SUV-2676',
    'QWE-455',
    ' RTE-323',
    'DER-7654',
    'SUV-2676',
    'QWE-455',
    ' RTE-323',
    'DER-7654',
  ]),
  Mine: sample([
    'Mine 1',
    'Mine 2',
    'Mine 3',
    'Mine 3',
    'Mine 5',
    'Mine 6',
    'Mine 7',
    'Mine 8',

  ]),

  Minetype: sample([
    'Mine type 1',
    'Mine type 2',
    'Mine type 3',
    'Mine type 3',
    'Mine type 5',
    'Mine type 6',
    'Mine type 7',
    'Mine type 8',

  ]),

  Minningarea: sample([
    '10',
    '20',
    '10',
    '20', 
    '20',
    '10',
    '20',
    '10',
    '20',

  ]),
  capacity: sample([
    '1000',
    '2000',
    '3000',
    '1500',
    '2000',
    '1000',
    '2000',
    '3000',
    '1500',
    '2000',

  ]),


}));

export default Minetype12;