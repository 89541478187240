import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { Grid, Typography, Box, TextField, Stack } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Success from '../components/success/Success';
import Error from '../components/error/Error';
import Iconify from '../components/iconify';
import {
  useGetAreasQuery,
  useGetPointsQuery,
  useGetCitiesQuery,
  useGetStatesQuery,
  useGetMineTypeQuery,
  useUpdateMineDataMutation,
  useGetSandTypeQuery,
  useGetMineByIdMutation,
} from '../services/api';

const EditMines = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mineId = query.get('id'); // Retrieve the mine ID from the query parameters
  console.log(mineId, 'mineId');
  const { data: points } = useGetPointsQuery({}, { refetchOnFocus: true });
  const { data: states } = useGetStatesQuery();
  const { data: cities } = useGetCitiesQuery();
  const { data: areas } = useGetAreasQuery();
  const { data: mineTypes } = useGetMineTypeQuery();
  const { data: sandTypes } = useGetSandTypeQuery();
  const [mineData, setMineData] = useState({});
  const [minePoints, setMinePoints] = useState([{ position: 0 }]);
  const [getMineById] = useGetMineByIdMutation();
  const [saveEditMine, { error, isLoading, isSuccess }] = useUpdateMineDataMutation();
  console.log(mineData, 'mineData');

  useEffect(() => {
    async function fetchMineData() {
      const result = await getMineById(mineId).unwrap();
      setMineData(result);
      setMinePoints(result.points || [{ position: 0 }]);
    }
    if (mineId) {
      fetchMineData();
    }
  }, [mineId, getMineById]);

  const handleChange = (e, field) => {
    if (field.includes('.')) {
      const fields = field.split('.');
      setMineData((prevData) => ({
        ...prevData,
        [fields[0]]: {
          ...prevData[fields[0]],
          [fields[1]]: e.target.value,
        },
      }));
    } else {
      setMineData({ ...mineData, [field]: e.target.value });
    }
  };
  console.log(mineId, 'mineId')
  const handleSaveEditMine = async (mineId) => {
    await saveEditMine({mineId, ...mineData, points: minePoints });
    console.log({ ...mineData, points: minePoints });
  };

  const addMinePoint = () => {
    setMinePoints((prev) => [...prev, { title: '', fields: [] }]);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(minePoints);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setMinePoints(items);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/dashboard/mine');
      }, 800);
    }
  }, [isSuccess, navigate]);

  return (
    <>
      <h2>Edit Mine</h2>
      <Typography
        variant="body2"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1.5vmax' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'mineName')}
                  value={mineData?.data?.mineName || ''}
                  id="input-with-sx"
                  required
                  label="Mine Name"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>State </InputLabel>
                  <Select
                    onChange={(e) => handleChange(e, 'state')}
                    value={mineData?.data?.state || ''}
                    id="input-with-sx"
                    label="State"
                    variant="outlined"
                  >
                    {states?.data.map((state, index) => (
                      <MenuItem key={index} value={state.stateName}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>City </InputLabel>
                  <Select
                    onChange={(e) => handleChange(e, 'city')}
                    value={mineData?.data?.city || ''}
                    id="input-with-sx"
                    label="City"
                    variant="outlined"
                  >
                    {cities?.data
                      .filter((o) => o.stateName === mineData?.data?.state)
                      ?.map((city, index) => (
                        <MenuItem key={index} value={city.cityName}>
                          {city.cityName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>Area </InputLabel>
                  <Select
                    onChange={(e) => handleChange(e, 'area')}
                    value={mineData?.data?.area || ''}
                    id="input-with-sx"
                    label="Area"
                    variant="outlined"
                  >
                    {areas?.data
                      .filter((o) => o.cityName === mineData?.data?.city)
                      ?.map((area, index) => (
                        <MenuItem key={index} value={area.area}>
                          {area.area}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'mineLicenseNumber')}
                  value={mineData?.data?.mineLicenseNumber || ''}
                  id="input-with-sx"
                  required
                  label="Mine license Number"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={'column'} spacing={3}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'miningArea.area')}
                  value={mineData?.data?.miningArea?.area || ''}
                  id="input-with-sx"
                  required
                  label="Minning area (Sq. Kilometer)"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <TextField
                  onChange={(e) => handleChange(e, 'productionCapacity')}
                  value={mineData?.data?.productionCapacity || ''}
                  id="input-with-sx"
                  required
                  label="Production Capacity (Cubic Meter)"
                  variant="outlined"
                  style={{ width: '40ch' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '.5vmax' }}>
                <FormControl style={{ width: '40ch' }}>
                  <InputLabel>Mine Status </InputLabel>
                  <Select
                    onChange={(e) => handleChange(e, 'mineStatus')}
                    value={mineData?.data?.mineStatus || ''}
                    id="input-with-sx"
                    label="Mine Type"
                    variant="outlined"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Under Maintenance">Under Maintenance</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <DragDropContext onDragEnd={handleOnDragEnd} >
                <Droppable droppableId="minePoints">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {minePoints.map((mPoint, index) => (
                        <Draggable key={index} draggableId={`mPoint-${index}`} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <FormControl disabled style={{ width: '40ch', display: 'flex', flexDirection: 'column' }}>
                                <InputLabel>Set Mine Points </InputLabel>
                                <Select
                                  onChange={(e) =>
                                    setMinePoints((prev) => {
                                      const newArr = [...prev];
                                      newArr[index].title = e.target.value;
                                      newArr[index].position = index;
                                      newArr[index].fields = points.data?.filter(
                                        (o) => o.title === e.target.value
                                      )[0].fields;
                                      newArr[index].options = points.data?.filter(
                                        (o) => o.title === e.target.value
                                      )[0].options
                                        ? points.data?.filter((o) => o.title === e.target.value)[0].options
                                        : null;
                                      newArr[index].isRequired = points.data?.filter(
                                        (o) => o.title === e.target.value
                                      )[0].isRequired;
                                      newArr[index].isNaka = points.data?.filter((o) => o.title === e.target.value)[0]
                                        .isNaka;
                                      newArr[index].isDiscountPointNaka = points.data?.filter(
                                        (o) => o.title === e.target.value
                                      )[0].isDiscountPointNaka;
                                      newArr[index].remark = points.data?.filter((o) => o.title === e.target.value)[0]
                                        .remark;
                                      return newArr;
                                    })
                                  }
                                  id="input-with-sx"
                                  label="Area"
                                  variant="outlined"
                                  value={mPoint.title || ''}
                                >
                                  {points?.data.map((point, pIndex) => (
                                    <MenuItem key={pIndex} value={point.title}>
                                      {point.title}{' '}
                                      {point.remark && (
                                        <span style={{ fontWeight: 'bolder' }}>({point.remark})</span>
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={addMinePoint}
                  disabled
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Add Next Point
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>

        <Button disabled={isLoading} onClick={()=> handleSaveEditMine(mineId)} variant="contained" endIcon={<SaveIcon />}>
          Save
        </Button>
        {error && <Error error={error} />}
        {isSuccess && <Success successMessage={'Saved Successfully'} isSuccess={isSuccess} />}
      </Typography>
    </>
  );
};

export default EditMines;
