import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const Employrole = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),

  status: sample(['active', 'banned']),


  rolename: sample([
    'Operators',
    'Managers',
    'Operators',
    'Managers',

  ]),
  discription: sample([
    'Lorem, ',
    'voluptatibus ',
    'aliquid ',
    'Magnam',
    'voluptatum',
   
  ]),


  select: sample([
    'Indore ',
    'Bhopal',
    'Mandideep',
    'Sehore',
    'Khategaon',
    'Mumbai',
    'Dehli',
    'Noida',
  ]),



}));

export default Employrole;
