// @mui
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  // .isRequired
  color: PropTypes.string,
  icon: PropTypes.string,
  ajayy: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  to,
  total,
  toa,
  tow,
  on,
  pen,
  icon,
  color = 'primary',
  sx,
  ...other
}) {
  return (
    <Card
      className="qwer"
      sx={{
        py: 3,
        boxShadow: 5,

        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color],
        ...sx,
      }}
      {...other}
    >
      <StyledIcon>
        <Iconify icon={icon} width={90} height={24} />
      </StyledIcon>
      <Typography variant="subtitle" fontSize={20} fontWeight="bold" className="qd">
        {title}
      </Typography>
      <div>
        <div className="card">
          <span>{total}</span>
          <br />
          <span>{to}</span>
          <span className="">{tow}</span>
          <br />
          <span className="">{toa}</span>
          <span className="">{on}</span>
          <br />
          <span className="">{pen}</span>
        </div>
      </div>
    </Card>
  );
}
