import './Forms.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import './Vehicles.css';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import './Form1.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, useRef } from 'react';
import { Text } from 'react-thermal-printer';
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Autocomplete,
  Modal,
} from '@mui/material';
import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  useGetMineQuery,
  useGetVehiclesQuery,
  useLazyGetVehicleQuery,
  useSaveVehicleMutation,
  useUpdateVehicleDataMutation,
  useSaveOtherVehicleDataMutation,
  useSavevehicleMasterDataMutation,
  useSaveDiscountedVehicleMutation,
  useGetDiscountNakaPointQuery,
} from '../services/api';
import Iconify from '../components/iconify';
import VehicleTable from './VehicleTable';
import Error from '../components/error/Error';
import Success from '../components/success/Success';
import TimeLineModal from './TimeLineModal';

const Vehicles = () => {
  const [feet, setFeet] = useState('');
  const [inches, setInches] = useState('');
  const [widthFeet, setWidthFeet] = useState('');
  const [widthInches, setWidthInches] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [totalVolume, setTotalVolume] = useState(null);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const [totalCost, setTotalCost] = useState(null);
  const [lengthMeters, setLengthMeters] = useState('');
  const [lengthCentimeters, setLengthCentimeters] = useState('');
  const [widthMeters, setWidthMeters] = useState('');
  const [widthCentimeters, setWidthCentimeters] = useState('');
  const [heightMeters, setHeightMeters] = useState('');
  const [heightCentimeters, setHeightCentimeters] = useState('');
  const [tokenNum, setTokenNum] = useState('');
  const [totalVolumemeter, setTotalVolumemeter] = useState(null);
  const [isVehicleTimelineOpen, setIsVehicleTimelineOpen] = useState(false);
  const [vehNumber, setVehNumber] = useState('');
  const [vehType, setVehType] = useState('');
  const [vehModel, setVehModel] = useState('');
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(null);
  const [points, setPoints] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Number(process.env.REACT_APP_PAGE_LIMIT));
  const [open, setOpen] = useState(false);
  const [otherVehicleData, setOtherVehicleData] = useState({});
  const [saveOtherVehicleData, { isLoading }] = useSaveOtherVehicleDataMutation();
  const [savevehicleMasterData, { isLoading: isVehicleMasterDataLoading }] = useSavevehicleMasterDataMutation();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isAddingVehicle, setIsAddingVehicle] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const prevSelectedVehicleIndex = useRef(null);
  const handleVehicleTimelineOpen = () => {
    setIsVehicleTimelineOpen(!isVehicleTimelineOpen);
  };

  const { data: discountNakaPointData } = useGetDiscountNakaPointQuery();
  console.log('selectedVehicleIndex', selectedVehicleIndex);

  const calculateTotalVolumemeter = () => {
    const lengthInMeters = parseFloat(lengthMeters) + parseFloat(lengthCentimeters) / 100;
    const widthInMeters = parseFloat(widthMeters) + parseFloat(widthCentimeters) / 100;
    const heightInMeters = parseFloat(heightMeters) + parseFloat(heightCentimeters) / 100;

    if (!Number.isNaN(lengthInMeters) && !Number.isNaN(widthInMeters) && !Number.isNaN(heightInMeters)) {
      const calculatedTotalVolumemeter = lengthInMeters * widthInMeters * heightInMeters;
      setTotalVolumemeter(calculatedTotalVolumemeter.toFixed(2));
    } else {
      setTotalVolumemeter(null);
    }
  };
// console.log(points, 'points')
  const [isDiscountedOpen, setIsDiscountedOpen] = useState(false);

  const [Vehicletype, setVehicletype] = useState([
    { label: 'Type-4', value: '4-Tyre' },
    { label: 'Type-6', value: '6-Tyre' },
    { label: 'Type-6-200', value: '6-Tyre-200' },
    { label: 'Type-6-225', value: '6-Tyre-225' },
    { label: 'Type-6-400', value: '6-Tyre-400' },
    { label: 'Type-8', value: '8-Tyre' },
    { label: 'Type-10', value: '10-Tyre' },
    { label: 'Type-12', value: '12-Tyre' },
    {label: 'Type-14', value: '14-Tyre' },
    { label: 'Tractor', value: 'Tractor' },
  ]);

  const handleCalculateButtonClick = () => {
    calculateTotalVolume();
    calculateTotalVolumemeter();
  };

  const calculateTotalVolume = () => {
    const lengthInFeet = parseFloat(feet) + parseFloat(inches) / 12;
    const widthInFeet = parseFloat(widthFeet) + parseFloat(widthInches) / 12;
    const heightInFeet = parseFloat(heightFeet) + parseFloat(heightInches) / 12;

    if (!Number.isNaN(lengthInFeet) && !Number.isNaN(widthInFeet) && !Number.isNaN(heightInFeet)) {
      const calculatedTotalVolume = lengthInFeet * widthInFeet * heightInFeet;
      setTotalVolume(calculatedTotalVolume.toFixed(2));

      if (!Number.isNaN(pricePerUnit)) {
        const calculatedTotalCost = calculatedTotalVolume * parseFloat(pricePerUnit);
        setTotalCost(calculatedTotalCost.toFixed(2));
      } else {
        setTotalCost(null);
      }
    } else {
      setTotalVolume(null);
      setTotalCost(null);
    }
  };

  const [sandType, setSandType] = useState(null);

  const sandTypes = [
    { label: 'Type 1', value: 'type1' },
    { label: 'Type 2', value: 'type2' },
    { label: 'Type 3', value: 'type3' },
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '80vh',
    overflow: 'scroll',
  };

  const handleSandTypeChange = (event, newValue) => {
    setSandType(newValue);
  };

  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };

  const employee = useSelector((state) => state.user);
  const { data: mines, isLoading: mineDataLoading, isSuccess: mineDataSuccess } = useGetMineQuery();
  const [addVehicle, { data: vehicleAddData, isLoading: isVehicleAddLoading, isSuccess: isVehicleAddSuccess }] =
    useSaveVehicleMutation();
  const minePoints = mines?.data?.filter((obj) => obj.mineName === employee?.data?.mineName)[0];
  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    refetch: refetchVehicles,
  } = useGetVehiclesQuery({ page, limit: rowsPerPage }, { refetchOnFocus: true });
  const [getVehicle, { data: vehicle, isLoading: isVehicleDataLoading }] = useLazyGetVehicleQuery();
  const [updateVehicle, { data: da, error, isSuccess }] = useUpdateVehicleDataMutation();
// console.log(minePoints?.points, 'minePoints.points')
  const addVehicleAsync = async () => {
    setIsAddingVehicle(true);

    // Skip the check for the first time
    if (!firstTime && vehicles && vehicles.data && vehicles?.data?.length > 0) {
      // Sort vehicles based on a timestamp field in descending order
      const sortedVehicles = [...vehicles.data].sort((a, b) => {
        // Assuming 'createdAt' is the timestamp field
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      // The first element is now the most recently added vehicle
      const lastVehicleEntry = sortedVehicles[0];

      // Check if the vehicle number of the last entry is empty, undefined, or null
      // Detailed check for vehicle number
      if (
        lastVehicleEntry === undefined ||
        lastVehicleEntry?.vehicleData?.vehicleNumber === undefined ||
        lastVehicleEntry?.vehicleData?.vehicleNumber === null ||
        lastVehicleEntry?.vehicleNumber?.trim() === ''
      ) {
        alert('Previous vehicle number is empty. Cannot add new vehicle.');
        setIsAddingVehicle(false);
        return;
      }
    }

    const newPoints = minePoints?.points;
    if (
      firstTime ||
      (newPoints && (newPoints.length > 0 || newPoints.some((point) => point.fields && point.fields.length > 0)))
    ) {
      await addVehicle({ mine: minePoints, points: newPoints });
      await refetchVehicles();
      setSelectedVehicleIndex(vehicles?.data?.length - 1);
      setFirstTime(false);
    } else {
      console.log('Cannot add vehicle with empty points');
    }

    setIsAddingVehicle(false);
  };

  // console.log(minePoints, 'minePoints')
  const handleSaveVehicleData = async () => {
    const tokenData = vehicle?.data?.token;
    const vehicleData = {
      token: tokenData || tokenNum,
      vehicleNumber: vehNumber || vehicle?.data?.vehicleData?.vehicleNumber,
      vehicleType: vehicle?.data?.vehicleData?.vehicleType || vehType,
      vehicleModel: vehModel || vehicle?.data?.vehicleData?.vehicleModel,
      lengthInMeter: lengthMeters || vehicle?.data?.vehicleData?.lengthInMeter,
      lengthInCentimeter: lengthCentimeters || vehicle?.data?.vehicleData?.lengthInCentimeter,
      breadthInMeter: widthMeters || vehicle?.data?.vehicleData?.breadthInMeter,
      breadthInCentimeter: widthCentimeters || vehicle?.data?.vehicleData?.breadthInCentimeter,
      heightInMeter: heightMeters || vehicle?.data?.vehicleData?.heightInMeter,
      heightInCentimeter: heightCentimeters || vehicle?.data?.vehicleData?.heightInCentimeter,
      lengthInFoot: feet || vehicle?.data?.vehicleData?.lengthInFoot,
      lengthInInch: inches || vehicle?.data?.vehicleData?.lengthInInch,
      breadthInFoot: widthFeet || vehicle?.data?.vehicleData?.breadthInFoot,
      breadthInInch: widthInches || vehicle?.data?.vehicleData?.breadthInInch,
      heightInFoot: heightFeet || vehicle?.data?.vehicleData?.heightInFoot,
      heightInInch: heightInches || vehicle?.data?.vehicleData?.heightInInch,
      volumeInCubicFoot: totalVolume || vehicle?.data?.vehicleData?.volumeInCubicFoot,
      volumeInCubicMeter: totalVolumemeter || vehicle?.data?.vehicleData?.volumeInCubicMeter,
    };
    await updateVehicle({ id: selectedVehicleIndex, dataToUpdate: { vehicleData } });
  };
  const handleSaveVehicleMasterData = async () => {
    const vehicleMasterData = {
      vehicleNumber: vehNumber || vehicle?.data?.vehicleData?.vehicleNumber,
      vehicleType: vehicle?.data?.vehicleData?.vehicleType || vehType,
      vehicleModel: vehModel || vehicle?.data?.vehicleData?.vehicleModel,
      lengthInMeter: lengthMeters || vehicle?.data?.vehicleData?.lengthInMeter,
      lengthInCentimeter: lengthCentimeters || vehicle?.data?.vehicleData?.lengthInCentimeter,
      breadthInMeter: widthMeters || vehicle?.data?.vehicleData?.breadthInMeter,
      breadthInCentimeter: widthCentimeters || vehicle?.data?.vehicleData?.breadthInCentimeter,
      heightInMeter: heightMeters || vehicle?.data?.vehicleData?.heightInMeter,
      heightInCentimeter: heightCentimeters || vehicle?.data?.vehicleData?.heightInCentimeter,
      lengthInFoot: feet || vehicle?.data?.vehicleData?.lengthInFoot,
      lengthInInch: inches || vehicle?.data?.vehicleData?.lengthInInch,
      breadthInFoot: widthFeet || vehicle?.data?.vehicleData?.breadthInFoot,
      breadthInInch: widthInches || vehicle?.data?.vehicleData?.breadthInInch,
      heightInFoot: heightFeet || vehicle?.data?.vehicleData?.heightInFoot,
      heightInInch: heightInches || vehicle?.data?.vehicleData?.heightInInch,
      volumeInCubicFoot: totalVolume || vehicle?.data?.vehicleData?.volumeInCubicFoot,
      volumeInCubicMeter: totalVolumemeter || vehicle?.data?.vehicleData?.volumeInCubicMeter,
    };
    console.log(vehicleMasterData, 'vehicleMasterData');
    await savevehicleMasterData({ vehicleMasterData });
  };
  const handleSaveVehicle = async (_id) => {
    let isPreviousPointIncomplete = false;
    if (points !== undefined && points !== null) {
      // Find the index of the point with the given _id
      const currentIndex = points.findIndex((point) => point?._id === _id);
      // Check if there is a previous point and if its status is not 'completed'
      if (
        currentIndex > 0 &&
        points[currentIndex - 1].status !== 'completed' &&
        points[currentIndex - 1].isRequired === true
      ) {
        isPreviousPointIncomplete = true;
      }
    }
    if (isPreviousPointIncomplete) {
      alert('Please complete the previous point first / कृपया पहले पिछला बिंदु पूरा करें');
      return;
    }
    console.log(points,"points")
    await updateVehicle({ id: selectedVehicleIndex, dataToUpdate: { points } });
    await handleSaveVehicleData();
    await refetchVehicles();
    await getVehicle({ id: selectedVehicleIndex });
  };

  const user = useSelector((state) => state.user);

  const handleFieldValue = (e, fieldName, pIndex, fIndex) => {
    setPoints((prev) => {
      const arr = JSON.parse(JSON.stringify(prev));
      arr[pIndex].updatedBy = user?.data;
      arr[pIndex].createdAt = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })).toISOString();
      arr[pIndex].status = 'completed';
      arr[pIndex].token = user?.data.mineName + user?.data.point + new Date().getTime();
      arr[pIndex].fields[fIndex] = { ...arr[pIndex].fields[fIndex], value: e.target.value.trim(), updatedBy: user?.data };
      return arr;
    });
  };

  const handleOtherVehicleDataChange = (e, field) => {
    setOtherVehicleData({ ...otherVehicleData, [field]: e.target.value.trim() });
  };

  const [saveDiscountedVehicle, { data: discountedVehicleData }] = useSaveDiscountedVehicleMutation();
  const [discountedVehicle, setDiscountedVehicle] = useState({ serialNumber: '', naka: [] });

  const handleDiscountedVehicle = async (e, field) => {
    if (field === 'serialNumber') {
      await setDiscountedVehicle({ ...discountedVehicle, [field]: e.target.value.trim() });
    } else {
      await setDiscountedVehicle({ ...discountedVehicle, naka: [e.target.value.trim()] });
    }

    // console.log('logging vehicle data', vehicle.data.token);
    await saveDiscountedVehicle({...vehicle?.data?.vehicleData, serialNumber: discountedVehicle.serialNumber, nakaRequired: discountedVehicle.naka, token: vehicle?.data?.token });
  }


  // console.log('-----------', discountedVehicleData);
  // console.log(user.data._id, 'user');
  // console.log(vehicle, 'vehicle');
  const handleSave = async () => {
    const requestData = {
      ...otherVehicleData,
      token: vehicle?.data?.token,
      createdBy: user?.data?._id,
      mineName: user?.data?.mineName,
    };
    try {
      const response = await saveOtherVehicleData(requestData).unwrap();
      setSaveSuccess(true);
      setSaveError(null);
      handleClose();
      // Reset the form or perform other actions as necessary
    } catch (error) {
      setSaveError(error.data ? error.data : 'An error occurred');
      setSaveSuccess(false);
    }
  };
  const handleSaveDiscountedVehicle = async () => {
    const requestData = {
      ...discountedVehicle,
      token: vehicle?.data?.token,
      createdBy: user?.data?._id,
      vehicleNumber: vehicle?.data?.vehicleData?.vehicleNumber,
      vehicleType: vehicle?.data?.vehicleData?.vehicleType,
      vehicleModel: vehicle?.data?.vehicleData?.vehicleModel,
      lengthInMeter: vehicle?.data?.vehicleData?.lengthInMeter,
      lengthInCentimeter: vehicle?.data?.vehicleData?.lengthInCentimeter,
      breadthInMeter: vehicle?.data?.vehicleData?.breadthInMeter,
      breadthInCentimeter: vehicle?.data?.vehicleData?.breadthInCentimeter,
      heightInMeter: vehicle?.data?.vehicleData?.heightInMeter,
      heightInCentimeter: vehicle?.data?.vehicleData?.heightInCentimeter,
      lengthInFoot: vehicle?.data?.vehicleData?.lengthInFoot,
      lengthInInch: vehicle?.data?.vehicleData?.lengthInInch,
      breadthInFoot: vehicle?.data?.vehicleData?.breadthInFoot,
      breadthInInch: vehicle?.data?.vehicleData?.breadthInInch,
      heightInFoot: vehicle?.data?.vehicleData?.heightInFoot,
      heightInInch: vehicle?.data?.vehicleData?.heightInInch,
      volumeInCubicFoot: vehicle?.data?.vehicleData?.volumeInCubicFoot,
      volumeInCubicMeter: vehicle?.data?.vehicleData?.volumeInCubicMeter,
    };
    try {
      const response = await saveDiscountedVehicle(requestData).unwrap();
      setSaveSuccess(true);
      setSaveError(null);
      handleClose();
      setIsDiscountedOpen(false);
      // Reset the form or perform other actions as necessary
    } catch (error) {
      setSaveError(error.data ? error.data : 'An error occurred');
      setSaveSuccess(false);
    }
  };
  const generatePDF = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    function getCurrentTime() {
      const now = new Date();
      const day = now.getDate();
      const month = now.getMonth() + 1; // Months are zero-indexed
      const year = now.getFullYear();
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;
      return `${formattedDay}-${formattedMonth}-${year}`;
    }

    const documentDefinition = {
      pageSize: {
        width: 3.35 * 72, // 85mm width in points
        height: 8 * 72, // Example height, adjust as needed
      },
      pageMargins: [10, 5, 10, 0], // Top, Right, Bottom, Left margins
      content: [
        {
          text: `${vehicle?.data?.mine.mineName || ''}`,
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        {
          text: [
            {
              text: 'Date:',
              bold: true,
              alignment: 'left',
            },
            {
              text: `${getCurrentTime()}\n`,
              alignment: 'right',
            },
            {
              text: 'Time',
              bold: true,
              alignment: 'left',
            },
            {
              text: `${new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata' })}\n`,
              alignment: 'right',
            },
            { text: 'Token:', bold: true, alignment: 'left' },
            { text: `${vehicle?.data?.token}\n`, alignment: 'right' },
            { text: 'Vehicle Number:', bold: true, alignment: 'left' },
            { text: `${vehicle?.data?.vehicleData?.vehicleNumber}\n`, alignment: 'right' },
            { text: 'Cubic Foot:', bold: true, alignment: 'left' },
            { text: `${vehicle?.data?.vehicleData?.volumeInCubicFoot || ''}\n`, alignment: 'right' },
            { text: 'Cubic Meter:', bold: true, alignment: 'left' },
            { text: `${vehicle?.data?.vehicleData?.volumeInCubicMeter || ''}\n`, alignment: 'right' },
            // ... Add more fields as necessary ...
          ].flat(),
          fontSize: 12,
        },
        {
          text: vehicle?.data?.points
            ?.map((point, pIndex) => {
              return point?.fields
                ?.map((field, fIndex) => {
                  return field?.printable
                    ? [
                        { text: `${field?.name}:`, bold: true, alignment: 'left' },
                        { text: `${field?.value || ''}\n`, alignment: 'right' },
                      ]
                    : [];
                })
                .flat();
            })
            .flat(),
        },
        // Spacer for "Issued By" section
        {
          text: '',
          margin: [0, 20, 0, 0], // Adjust this margin as needed
        },

        // "Issued By" section
        {
          columns: [
            { width: '*', text: '' },
            {
              width: 'auto',
              text: `Issued By: ${user?.data?.name || 'N/A'}`, // Replace 'user?.data?.name' with the correct path to the user's name
              bold: true,
              alignment: 'left',
            },
          ],
          columnGap: 10,
        },
        // Spacer for signature
        {
          text: '',
          margin: [0, 30, 0, 0], // Adjust this margin to create space for the signature
        },
        // Signature field
        {
          columns: [
            { width: '*', text: '' },
            { width: 'auto', text: 'Signature', bold: true, alignment: 'left' },
          ],
          columnGap: 10,
        },
      ],
    };

    pdfMake.createPdf(documentDefinition).print({ autoPrint: false });
  };

  useEffect(() => {
    if (selectedVehicleIndex && selectedVehicleIndex !== prevSelectedVehicleIndex.current) {
      // Make the API call
      getVehicle({ id: selectedVehicleIndex });

      // Update the previous value to prevent unnecessary calls
      prevSelectedVehicleIndex.current = selectedVehicleIndex;
    }
    setPoints([]);
    setVehType('');
    setVehModel('');
    setVehNumber('');
    setTokenNum(vehicle?.data?.token || '');
    setVehNumber(vehicle?.data?.vehicleData?.vehicleNumber || '');
    setVehType(vehicle?.data?.vehicleData?.vehicleType || '');
    setVehModel(vehicle?.data?.vehicleData?.vehicleModel || '');
    setLengthMeters(vehicle?.data?.vehicleData?.lengthInMeter || '');
    setLengthCentimeters(vehicle?.data?.vehicleData?.lengthInCentimeter || '');
    setWidthMeters(vehicle?.data?.vehicleData?.breadthInMeter || '');
    setWidthCentimeters(vehicle?.data?.vehicleData?.breadthInCentimeter || '');
    setHeightMeters(vehicle?.data?.vehicleData?.heightInMeter || '');
    setHeightCentimeters(vehicle?.data?.vehicleData?.heightInCentimeter || '');
    setFeet(vehicle?.data?.vehicleData?.lengthInFoot || '');
    setInches(vehicle?.data?.vehicleData?.lengthInInch || '');
    setWidthFeet(vehicle?.data?.vehicleData?.breadthInFoot || '');
    setWidthInches(vehicle?.data?.vehicleData?.breadthInInch || '');
    setHeightFeet(vehicle?.data?.vehicleData?.heightInFoot || '');
    setHeightInches(vehicle?.data?.vehicleData?.heightInInch || '');
    setTotalVolume(vehicle?.data?.vehicleData?.volumeInCubicFoot || '');
    setTotalVolumemeter(vehicle?.data?.vehicleData?.volumeInCubicMeter || '');
  }, [vehicle, selectedVehicleIndex]);

  useEffect(() => {
    setPoints(vehicle?.data?.points);
  }, [vehicle]);
  // console.log('points', points);

  const checkForEntryPoint = () => {
    if (employee?.data?.role === 'admin' || employee?.data?.role === 'superAdmin') {
      return false;
    }
    if (
      employee?.data?.point?.length !== 0 &&
      employee?.data?.point[0] === minePoints?.points[0]?.title &&
      minePoints?.points[0]?.position === 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      {error && <Error error={error} />}
      {isSuccess && <Success isSuccess={isSuccess} successMessage={'Save successfully'} />}
      {saveError && <Error error={saveError} />}
      {saveSuccess && <Success isSuccess={saveSuccess} successMessage={'Other Vehicle Data saved successfully'} />}
      <Button
        color="warning"
        onClick={addVehicleAsync}
        style={{ marginTop: '25px', marginBottom: '5vh', marginLeft: '2ch' }}
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
        // disabled={!employee?.data?.point?.includes("Entry Point")}
        disabled={checkForEntryPoint()}
      >
        {isAddingVehicle ? <CircularProgress size={24} /> : 'Add Vehicle'}
      </Button>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={10}>
          <Typography variant="h4">Client Vehicle Points</Typography>
          <Box m={2}>
            {isVehicleDataLoading ? (
              <div>Loading...</div>
            ) : (
              vehicle?.data?.points?.map((point, pIndex) => (
                <Accordion
                  key={pIndex}
                  expanded={expand === point}
                  onChange={(event, IsExpand) => handleExpand(IsExpand, point)}
                >
                  <AccordionSummary className="mine-acodi" expandIcon={<ExpandMoreIcon color="secondary" />}>
                    <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
                      {point.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {point?.fields?.map((field, fIndex) => (
                      <div style={{ width: '100%' }} key={fIndex}>
                        {field.type === 'Email' ? (
                          field.value ? (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextField disabled label={field.name} type="email" />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                    onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                    disabled={!employee?.data?.point?.includes(point.title)}
                                    type="email"
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ marginTop: '1vh' }}
                                  value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                  onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label={field.name}
                                  type="email"
                                />
                              </Grid>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {field.type === 'Dropdown' ? (
                          <Grid container spacing={2}>
                            <Grid xs={4}>
                              <FormControl style={{ width: '100%', marginTop: '20px' }}>
                                <InputLabel>{field.name}</InputLabel>
                                <Select
                                  value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                  onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  id="input-with-sx"
                                  label="Add Type"
                                  variant="outlined"
                                >
                                  {field?.options?.map((option, index) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        {field.type === 'cft' ? (
                          <div style={{ width: '700px', border: '2px solid black', padding: '5px' }}>
                            <Typography variant="h4">CFT Calculator</Typography>
                            <h5>Length</h5>

                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Length (feet)"
                                  type="number"
                                  value={feet}
                                  onChange={(e) => setFeet(e.target.value.trim())}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Length (inches)"
                                  type="number"
                                  value={inches}
                                  onChange={(e) => setInches(e.target.value.trim())}
                                />
                              </Grid>
                            </Grid>
                            <h5>Width</h5>

                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Width (feet)"
                                  type="number"
                                  value={widthFeet}
                                  onChange={(e) => setWidthFeet(e.target.value.trim())}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Width (inches)"
                                  type="number"
                                  value={widthInches}
                                  onChange={(e) => setWidthInches(e.target.value.trim())}
                                />
                              </Grid>
                            </Grid>
                            <h5>Height</h5>

                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Height (feet)"
                                  type="number"
                                  value={heightFeet}
                                  onChange={(e) => setHeightFeet(e.target.value.trim())}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Height (inches)"
                                  type="number"
                                  value={heightInches}
                                  onChange={(e) => setHeightInches(e.target.value.trim())}
                                />
                              </Grid>
                            </Grid>
                            <br />

                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <FormControl fullWidth>
                                  <InputLabel id="sand-type-label" />
                                  <Autocomplete
                                    disabled={!employee?.data?.point?.includes(point.title)}
                                    options={sandTypes}
                                    getOptionLabel={(option) => option.label}
                                    value={sandType}
                                    onChange={handleSandTypeChange}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Sand Type" variant="outlined" fullWidth />
                                    )}
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xs={4}>
                                <TextField
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label="Rate per Unit"
                                  type="number"
                                  value={pricePerUnit}
                                  onChange={(e) => setPricePerUnit(e.target.value.trim())}
                                />
                              </Grid>
                            </Grid>
                            <div>
                              <br />

                              <Button
                                disabled={!employee?.data?.point?.includes(point.title)}
                                variant="contained"
                                onClick={calculateTotalVolume}
                              >
                                Calculate Total Volume
                              </Button>
                            </div>
                            <br />
                            {totalVolume !== null && (
                              <div>
                                <Typography variant="h6">Total Volume:</Typography>
                                <Typography>{totalVolume} cubic units</Typography>
                              </div>
                            )}

                            {totalCost !== null && (
                              <div>
                                <Typography variant="h6">Total Cost:</Typography>
                                <Typography>${totalCost}</Typography>
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {field.type === 'Number' ? (
                          field.value ? (
                            <>
                              <Grid container spacing={2} alignItems={'center'}>
                                <Grid item xs={6}>
                                  <Text type="text">{field.name}:</Text>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                    onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                    disabled={!employee?.data?.point?.includes(point.title)}
                                    type="number"
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              {' '}
                              <Grid item xs={12}>
                                <TextField
                                  style={{ marginTop: '1vh' }}
                                  value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                  onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label={field.name}
                                  type="number"
                                />
                              </Grid>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {field.type === 'InputBox' ? (
                          field.value ? (
                            <>
                              <Grid container spacing={2} alignItems={'center'}>
                                <Grid item xs={6}>
                                  <Text style={{ width: '100%' }} type="text">
                                    {field.name}:
                                  </Text>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    style={{ width: '100%' }}
                                    value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                    onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                    disabled={!employee?.data?.point?.includes(point.title)}
                                    type="text"
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  style={{ marginTop: '1vh' }}
                                  value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                  onChange={(e) => handleFieldValue(e, field.name, pIndex, fIndex)}
                                  disabled={!employee?.data?.point?.includes(point.title)}
                                  label={field.name}
                                  type="text"
                                />
                              </Grid>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {field.type === 'Discounted Vehicle Dropdown' ? (
                          <div>
                            <Button
                              variant="contained"
                              disabled={!employee?.data?.point?.includes(point.title)}
                              style={{ float: 'right', marginRight: '2vw' }}
                              onClick={() => setIsDiscountedOpen(true)}
                            >
                              Add Discounted Vehicle
                            </Button>
                            <Modal
                              open={isDiscountedOpen}
                              onClose={() => setIsDiscountedOpen(false)}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                  Add Details
                                </Typography>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      label="Serial Number"
                                      name="serialNumber"
                                      onChange={(e) => handleDiscountedVehicle(e, 'serialNumber')}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControl style={{ width: '100%', marginTop: '20px' }}>
                                      <InputLabel>{'Naka'}</InputLabel>
                                      <Select
                                        value={points?.[pIndex]?.fields[fIndex].value || field.value}
                                        onChange={(e) => handleDiscountedVehicle(e)}
                                        disabled={!employee?.data?.point?.includes(point.title)}
                                        id="input-with-sx"
                                        label="Add Type"
                                        variant="outlined"
                                      >
                                         {points
                                          ?.filter((point) => point?.isDiscountPointNaka)
                                          .map((filteredPoint) => (
                                            <MenuItem key={filteredPoint._id} value={filteredPoint.title}>
                                              {filteredPoint.title} <span style={{fontWeight:"bolder"}}> {filteredPoint?.remark}</span>
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <Button variant="contained" onClick={handleSaveDiscountedVehicle}>
                                        Save
                                      </Button>
                                    </Grid>
                                </Grid>
                              </Box>
                            </Modal>
                          </div>
                        ) : (
                          <></>
                        )}

                        {field.type === 'Vehicle Data' ? (
                          <>
                            <div>
                              <Typography variant="h4" style={{ textAlign: 'center' }}>
                                Vehicle Data{' '}
                              </Typography>

                              <Grid container spacing={2}>
                                {!vehNumber && vehicle?.data?.vehicleData?.vehicleNumber ? (
                                  <>
                                    {' '}
                                    <Grid item xs={12}>
                                      <Text>गाडी नम्बर:</Text>
                                      <TextField
                                        style={{ width: '100%' }}
                                        value={vehicle?.data?.vehicleData?.vehicleNumber}
                                        onChange={(e) => setVehNumber(e.target.value?.toUpperCase().trim())}
                                        type="Text"
                                        fullWidth
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <Grid item xs={12}>
                                      <Text>गाडी नम्बर:</Text>
                                      <TextField
                                        style={{ width: '100%' }}
                                        value={vehNumber}
                                        onChange={(e) => setVehNumber(e.target.value?.toUpperCase().trim())}
                                        type="Text"
                                        fullWidth
                                      />
                                    </Grid>
                                  </>
                                )}

                                {!vehType && vehicle?.data?.vehicleData?.vehicleType ? (
                                  <>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        <Text>गाडी का प्रकार:</Text>
                                        <Select
                                          value={vehicle?.data?.vehicleData?.vehicleType}
                                          onChange={(e) => setVehType(e.target.value.trim())}
                                          id="input-with-sx"
                                          variant="outlined"
                                        >
                                          {Vehicletype.map((type, index) => (
                                            <MenuItem key={index} value={type.value}>
                                              {type.value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        <Text>गाडी का प्रकार:</Text>
                                        <Select
                                          value={vehType}
                                          onChange={(e) => setVehType(e.target.value.trim())}
                                          id="input-with-sx"
                                          variant="outlined"
                                        >
                                          {Vehicletype.map((type, index) => (
                                            <MenuItem key={index} value={type.value}>
                                              {type.value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </>
                                )}
                                {!vehModel && vehicle?.data?.vehicleData?.vehicleModel ? (
                                  <>
                                    <Grid item xs={6}>
                                      <Text>गाडी मोडल:</Text>
                                      <TextField
                                        value={vehicle?.data?.vehicleData?.vehicleModel}
                                        onChange={(e) => setVehModel(e.target.value.trim())}
                                        type="Text"
                                        fullWidth
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={6}>
                                      <Text>गाडी मोडल:</Text>
                                      <TextField
                                        value={vehModel}
                                        onChange={(e) => setVehModel(e.target.value.trim())}
                                        type="Text"
                                        fullWidth
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>

                              <h5>गाडी की लम्बाई मीटर में डालें</h5>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Text>लम्बाई (मीटर):</Text>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={lengthMeters}
                                    onChange={(e) => setLengthMeters(e.target.value.trim())}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Text>लम्बाई (से.मीटर):</Text>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={lengthCentimeters}
                                    onChange={(e) => setLengthCentimeters(e.target.value.trim())}
                                  />
                                </Grid>
                              </Grid>
                              <h5>गाडी की चोडाई मीटर में डालें</h5>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Text>चोडाई (मीटर):</Text>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={widthMeters}
                                    onChange={(e) => setWidthMeters(e.target.value.trim())}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Text>चोडाई (से.मीटर):</Text>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={widthCentimeters}
                                    onChange={(e) => setWidthCentimeters(e.target.value.trim())}
                                  />
                                </Grid>
                              </Grid>
                              <h5>ग़ाडी की उचाई मीटर में डालें</h5>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Text>उचाई (मीटर):</Text>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={heightMeters}
                                    onChange={(e) => setHeightMeters(e.target.value.trim())}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Text>उचाई (से.मीटर):</Text>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={heightCentimeters}
                                    onChange={(e) => setHeightCentimeters(e.target.value.trim())}
                                  />
                                </Grid>
                              </Grid>
                              <br />

                              <hr />

                              <h5>गाडी की लम्बाई फुट में डालें</h5>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Text>लम्बाई (फुट):</Text>
                                  <TextField
                                    type="number"
                                    value={feet}
                                    onChange={(e) => setFeet(e.target.value.trim())}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Text>लम्बाई (इंच):</Text>
                                  <TextField
                                    type="number"
                                    value={inches}
                                    onChange={(e) => setInches(e.target.value.trim())}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>

                              <h5>गाडी की चोडाई फुट में डालें</h5>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Text>चोडाई (फुट):</Text>
                                  <TextField
                                    style={{ width: '100%' }}
                                    type="number"
                                    value={widthFeet}
                                    onChange={(e) => setWidthFeet(e.target.value.trim())}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Text>चोडाई (इंच):</Text>
                                  <TextField
                                    type="number"
                                    value={widthInches}
                                    onChange={(e) => setWidthInches(e.target.value.trim())}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>

                              <h5>ग़ाडी की उचाई फुट में डालें</h5>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Text>उचाई (फुट):</Text>
                                  <TextField
                                    type="number"
                                    value={heightFeet}
                                    onChange={(e) => setHeightFeet(e.target.value.trim())}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Text>उचाई (इंच):</Text>
                                  <TextField
                                    type="number"
                                    value={heightInches}
                                    onChange={(e) => setHeightInches(e.target.value.trim())}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <br />
                              <br />

                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Button variant="contained" onClick={handleCalculateButtonClick}>
                                    कैलकुलेट करें
                                  </Button>
                                </Grid>
                              </Grid>

                              <br />

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <div>
                                    <Typography variant="h6">ग़ाडी की मात्रा :</Typography>
                                    <Typography>{totalVolume} घन फुट मे</Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div>
                                    <Typography variant="h6">ग़ाडी की मात्रा</Typography>
                                    <Typography>{totalVolumemeter} घन मीटर</Typography>
                                  </div>
                                </Grid>
                              </Grid>
                              <br />
                              <br />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {field.type === 'Other Vehicle Entry' ? (
                          <>
                            <div>
                              <Button
                                variant="contained"
                                disabled={!employee?.data?.point?.includes(point.title)}
                                style={{ float: 'right', marginRight: '2vw' }}
                                onClick={handleOpen}
                              >
                                Add Other District Vehicle
                              </Button>
                              {/* <Button
                                variant="contained"
                                disabled={!employee?.data?.point?.includes(point.title)}
                                style={{ float: 'right', marginRight: '2vw' }}
                                onClick={handleOpen}
                              >
                                Add Tractor Entry
                              </Button> */}
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add Other Vehicle Details
                                  </Typography>
                                  <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Vehicle Number"
                                        name="vehicleNumber"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'vehicleNumber')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Vehicle Type"
                                        name="vehicleType"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'vehicleType')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Vehicle Model"
                                        name="vehicleModel"
                                        type="number"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'vehicleModel')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Driver Name"
                                        name="driverName"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'driverName')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Driver Number"
                                        name="driverNumber"
                                        type="number"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'driverNumber')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Source Location"
                                        name="sourceLocation"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'sourceLocation')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Destination Location"
                                        name="destinationLocation"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'destinationLocation')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Loading Location"
                                        name="loadingLocation"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'loadingLocation')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Total Volume"
                                        name="totalVolume"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'totalVolume')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Loaded Volume"
                                        name="loadedVolume"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'loadedVolume')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Royalty Number"
                                        name="royaltyNumber"
                                        onChange={(e) => handleOtherVehicleDataChange(e, 'royaltyNumber')}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Button variant="contained" onClick={handleSave}>
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Modal>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                    {
                      <Button
                        disabled={!employee?.data?.point?.includes(point.title)}
                        onClick={() => {
                          handleSaveVehicle(point._id);
                          handleSaveVehicleMasterData();
                        }}
                        variant="contained"
                        style={{ marginTop: '3ch' }}
                        endIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    }
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </Box>
        </Grid>
      </Grid>
      <Button
        onClick={generatePDF}
        color="warning"
        variant="contained"
        style={{ marginTop: '3ch' }}
        endIcon={<SaveIcon />}
        disabled={!employee?.data?.point?.includes('Token Point')}
      >
        Print Token Receipt
      </Button>
      {console.log(vehicles, 'vehicles')}
      <VehicleTable
        rows={vehicles?.data}
        setVehicleIndex={setSelectedVehicleIndex}
        handleVehicleTimelineOpen={handleVehicleTimelineOpen}
        isVehicleTimelineOpen={isVehicleTimelineOpen}
        vehiclesLoading={vehiclesLoading}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <TimeLineModal
        open={isVehicleTimelineOpen}
        handleClose={handleVehicleTimelineOpen}
        vehicle={vehicles?.data?.find((vehicle) => vehicle?._id === selectedVehicleIndex)}
      />
    </>
  );
};

export default Vehicles;
