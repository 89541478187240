import { filter } from 'lodash';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';
import {
  Card,
  Table,
  Paper,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { useGetEmployeeDataQuery, useGetPointsQuery, useUpdateEmployeeDataMutation, useDeleteEmployeeMutation } from '../../services/api';
import Error from '../../components/error/Error';
import Success from '../../components/success/Success';

const TABLE_HEAD3 = [
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'number', label: 'S.NO', alignRight: false },
  { id: 'Name', label: 'Name', alignRight: false },
  { id: 'Mine', label: 'Mine', alignRight: false },
  { id: 'Point', label: 'Point', alignRight: false },
  { id: 'Department', label: 'Department', alignRight: false },
  { id: 'Designation', label: 'Designation', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  right: '10%',
  top: '5%',
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const { data: points = [] } = useGetPointsQuery();
  const { data: employees = [] } = useGetEmployeeDataQuery();
  const [id, setId] = useState(null);
  const [editOpen, setEditOpen] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [updateEmployeeData, { error, isSuccess }] = useUpdateEmployeeDataMutation();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [point, setPoint] = useState(null);

  const handleOpenMenu = (id) => {
    setPoint(null);
    setId(id);
    setEditOpen(true);
  };

  const handleEmployeeDataUpdate = async () => {
    await updateEmployeeData({ id, dataToUpdate: { point } });
    handleClose();
  };

  const handleClose = () => {
    setEditOpen(null);
    setPoint(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = employees?.data?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employees?.data?.length) : 0;

  const filteredUsers = applySortFilter(employees?.data || [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleOpenDeleteMenu = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(null);
  };

  const handleDeleteConfirmation = async () => {
    await deleteEmployee(id);
    handleDeleteClose();
  };

  return (
    <>
      {error && <Error error={error} />}
      {isSuccess && <Success isSuccess={isSuccess} successMessage={'Saved successfully'} />}
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <Iconify m={style2} onClick={handleDeleteClose} icon={'carbon:close'} style={{ cursor: 'pointer' }} />
          <Typography id="keep-mounted-modal-title" variant="h4" component="h1" style={{ marginTop: 10 }}>
            Are you sure you want to delete this Entry?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 30,
            }}
          >
            <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleDeleteClose}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Card style={{ marginTop: '2vmax' }}>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={employees?.data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Scrollbar>
          <TableContainer className="table-newemp">
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD3}
                rowCount={employees?.data?.length || 0}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const { id, name } = row;
                  const selectedUser = selected.indexOf(name) !== -1;
                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleOpenMenu(row._id)}>
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: 'red' }}
                          onClick={() => handleOpenDeleteMenu(row?._id)}
                        >
                          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                          Delete
                        </Button>
                        <Modal
                          open={editOpen}
                          onClose={handleClose}
                          aria-labelledby="keep-mounted-modal-title"
                          aria-describedby="keep-mounted-modal-description"
                        >
                          <Box sx={style1}>
                            <Iconify
                              m={style2}
                              onClick={handleClose}
                              icon={'carbon:close'}
                              style={{ cursor: 'pointer' }}
                            />
                            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                              Assign Mine Point
                            </Typography>
                            <Grid item xs={8}>
                              <FormControl style={{ width: '100%' }}>
                                <InputLabel id="demo-multiple-name-label">Select Point</InputLabel>
                                <Select
                                  onChange={(e) => setPoint(e.target.value)}
                                  id="input-with-sx"
                                  label="Point Name"
                                  variant="outlined"
                                >
                                  {points?.data?.map((point, index) => (
                                    <MenuItem key={index} value={point.title}>
                                      {point.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Button
                              onClick={() => handleEmployeeDataUpdate(row._id)}
                              variant="contained"
                              endIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                          </Box>
                        </Modal>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: 'white' }}>
                        <b>{rowNum}</b>
                      </TableCell>
                      <TableCell align="left">{row.name} </TableCell>
                      <TableCell align="left">{row.mineName} </TableCell>
                      <TableCell align="left">
                        {Array.isArray(row.point) && row.point.length > 0 ? row.point.join(', ') : 'N/A'}
                      </TableCell>
                      <TableCell align="left">{row.department === 'undefined' ? 'N/A' : row.department} </TableCell>
                      <TableCell align="left">{row.designation === 'undefined' ? 'N/A' : row.designation} </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 30 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
