import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
import '../../../styles/global.css';
import { useLoginMutation } from '../../../services/api';
import { setUser } from '../../../slices/userSlice';
import Error from '../../../components/error/Error';

export default function LoginForm() {
  const navigate = useNavigate();
  const [creds, setCreds] = useState({ phone: '', password: ''});
  const [showPassword, setShowPassword] = useState(false);
  const [login, {data, error, isLoading, isSuccess}] = useLoginMutation();
  const dispatch = useDispatch();
  
  const handleClick = () => {
    login(creds);
  };

  useEffect(() => {
    if(isSuccess){
      dispatch(setUser(data.data));
    }
  }, [isSuccess])

  return (
    <form>
      <Stack spacing={3}>
        <TextField type='number' id='phone-input' name="phone" label="Mobile number" autoComplete="phone" onChange={(e) => setCreds({...creds, phone: e.target.value})}/>

        <TextField
          id='password-input'
          name="password"
          label="Password"
          onChange={(e) => setCreds({...creds, password: e.target.value})}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete="password"
        />
      </Stack>

     <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        {error && <Error error={error}/>}
      </Stack>

      <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </form>
  );
}
