import { Helmet } from 'react-helmet-async';
// @mui
import './Forms.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { faker } from '@faker-js/faker';

import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import './Form1.css';
import { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from '@mui/material/styles';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import {
  Grid,
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
  Autocomplete,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { AppOrderTimeline } from '../sections/@dashboard/app';
import PhotoUploadForm from './Camera';
import { useGetMineQuery, useGetPointsQuery, useGetVehiclesQuery, useSaveVehicleMutation, useUpdateMineDataMutation, useUpdateVehicleDataMutation } from '../services/api';
import Iconify from '../components/iconify';

// @mui

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow next"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
    >
      {<ArrowForwardIosIcon />}
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow prev"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
    >
      {<ArrowBackIosNewIcon />}
    </div>
  );
};

// components
const MuiAccordion = () => {
  const [activeBox, setActiveBox] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };



  const handleKeyPress = (index, event) => {
    // Handle keyboard events, e.g., Enter key
    if (event.key === 'Enter') {
      handleBoxClick(index);
    }
  };

  const [feet, setFeet] = useState('');
  const [inches, setInches] = useState('');
  const [widthFeet, setWidthFeet] = useState('');
  const [widthInches, setWidthInches] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [totalVolume, setTotalVolume] = useState(null);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const [totalCost, setTotalCost] = useState(null);

  const [fieldType, setFieldType] = useState('Number');
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [newOption, setNewOption] = useState('');

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setFieldType(selectedType);

    // Reset checkbox options and new option when the type changes
    setCheckboxOptions([]);
    setNewOption('');
  };

  const handleCheckboxChange1 = (option) => {
    const updatedOptions = checkboxOptions.includes(option)
      ? checkboxOptions.filter((item) => item !== option)
      : [...checkboxOptions, option];

    setCheckboxOptions(updatedOptions);
  };

  const handleAddOption = () => {
    const trimmedOption = newOption.trim();
    if (trimmedOption && !checkboxOptions.includes(trimmedOption)) {
      setCheckboxOptions([...checkboxOptions, trimmedOption]);
      setNewOption('');
    }
  };


  // drop Down  start

  const [dropdownTypea, setDropdownType] = useState('Dropdown');
  const [dropdownOptionsa, setDropdownOptionsa] = useState([]);
  const [newOptiona, setNewOptiona] = useState('');

  const handleTypeChangea = (event) => {
    const selectedTypea = event.target.value;
    setDropdownType(selectedTypea);

    // Reset dropdown options and new option when the type changes
    setDropdownOptionsa([]);
    setNewOptiona('');
  };

  const handleDropdownChanges = (option) => {
    // Implement your logic for handling dropdown option selection
    console.log(`Selected option: ${option}`);
  };

  const handleAddOptions = () => {
    const trimmedOption = newOptiona.trim();
    if (trimmedOption && !dropdownOptionsa.includes(trimmedOption)) {
      setDropdownOptionsa([...dropdownOptionsa, trimmedOption]);
      setNewOptiona('');
    }
  };







  const calculateTotalVolume = () => {
    const lengthInFeet = parseFloat(feet) + parseFloat(inches) / 12;
    const widthInFeet = parseFloat(widthFeet) + parseFloat(widthInches) / 12;
    const heightInFeet = parseFloat(heightFeet) + parseFloat(heightInches) / 12;

    if (!Number.isNaN(lengthInFeet) && !Number.isNaN(widthInFeet) && !Number.isNaN(heightInFeet)) {
      const calculatedTotalVolume = lengthInFeet * widthInFeet * heightInFeet;
      setTotalVolume(calculatedTotalVolume.toFixed(2));

      if (!Number.isNaN(pricePerUnit)) {
        const calculatedTotalCost = calculatedTotalVolume * parseFloat(pricePerUnit);
        setTotalCost(calculatedTotalCost.toFixed(2));
      } else {
        setTotalCost(null);
      }
    } else {
      setTotalVolume(null);
      setTotalCost(null);
    }
  };

  const [sandType, setSandType] = useState(null); // Use null for the controlled component

  const sandTypes = [
    { label: 'Type 1', value: 'type1' },
    { label: 'Type 2', value: 'type2' },
    { label: 'Type 3', value: 'type3' },
    // Add more sand types as needed
  ];

  const handleSandTypeChange = (event, newValue) => {
    setSandType(newValue);
  };

  const [subscribe, setSubscribe] = useState(false);

  const handleCheckboxChange = (event) => {
    setSubscribe(event.target.checked);
  };

  const [subscriptionType, setSubscriptionType] = useState('free');

  const handleRadioChange = (event) => {
    setSubscriptionType(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    // Handle file upload logic
    if (selectedFile) {
      console.log('Uploading file:', selectedFile);
      // You can use APIs like FormData to send the file to the server
    } else {
      console.error('No file selected.');
    }
  };

  const [expand, setExpand] = useState();
  const handleExpand = (IsExpand, value) => {
    setExpand(IsExpand ? value : false);
  };

  const [name, setName] = useState('');

  const [selectedCity, setSelectedCity] = useState('');

  const [destination, setDestination] = useState('');
  const [destinationa, setDestinationa] = useState('');
  const [client, setClient] = useState('');
  const [clienta, setClienta] = useState('');
  const filter = useSelector((state) => state.filter);

  const employee = useSelector((state) => state.user);
  const { data: mines, isLoading: mineDataLoading, isSuccess: mineDataSuccess } = useGetMineQuery();
  const [addVehicle, { data: vehicleAddData, isLoading: isVehicleAddLoading, isSuccess: isVehicleAddSuccess }] = useSaveVehicleMutation();
  const minePoints = mines?.data?.filter((obj) => obj.mineName === employee?.data?.mineName)[0];
  const { data: vehicles, isSuccess: vehsuc, error: ve, refetch: refetchVehicles } = useGetVehiclesQuery({ mine: minePoints?.mineName }, { refetchOnFocus: true });

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setSelectedCity(newCity);
  };

  const [updateMine, { error, isSuccess, isLoading, data }] = useUpdateMineDataMutation();
  const [updateVehicle, { data: da }] = useUpdateVehicleDataMutation();
  const [id, setId] = useState(null);
  const filteredMines = mines?.data?.filter((obj) => obj.mineName === employee?.data?.mineName)[0];
  const { data: vehicle, refetch, isLoading: isVehicleDataLoading } = useGetMineQuery({ id });

  const handleBoxClick = (index, id) => {
    setId(id);
    setActiveBox(index);
  };

  const [points, setPoints] = useState([]);

  const handleSaveVehicle = async () => {
    await updateVehicle({ id, dataToUpdate: { points } });
  }

  const addVehicleAsync = async () => {
    await addVehicle({ mine: filteredMines, points: minePoints.points });
  }

  const handleFieldValue = (e, fieldName, pIndex, fIndex) => {
    setPoints(prev => {
      const arr = JSON.parse(JSON.stringify(prev));
      arr[pIndex].fields[fIndex] = { ...arr[pIndex].fields[fIndex], value: e.target.value };
      return arr;
    })
  }

  useEffect(() => {
    if (isVehicleAddSuccess) {
      refetchVehicles();
    }
  }, [isVehicleAddSuccess])

  useEffect(() => {
    setPoints(vehicle?.data?.points)
  }, [vehicle])

  useEffect(() => {
    refetch();
  }, [id]);

  console.log(id);


  if (isVehicleAddLoading) return (
    <div>
      Adding vehicle...
    </div>
  )

  return (
    <>
      <div className="slider-container">
        <Slider {...settings} slidesToShow={vehicles?.count}>
          {vehicles?.data?.map((vehicle, vehicleIndex) => (
            <div
              className={`boxx ${activeBox === vehicleIndex ? 'active' : ''}`}
              onClick={() => handleBoxClick(vehicleIndex, vehicle._id)}
              onKeyPress={(event) => handleKeyPress(vehicleIndex, event)}
              role="button"
              tabIndex={0}
            >
              <div
                className="box-top"
                style={{
                  backgroundColor: '#4286f472',
                  color: 'black',
                  border: activeBox === vehicleIndex ? '3px solid red' : '2px solid transparent',
                }}
              >
                <h3>{'vehicle'}{' '}{vehicleIndex + 1}</h3>
              </div>
            </div>
          ))}
        </Slider>
        <Button color='warning' style={{ marginTop: '25px', marginBottom: '15vh', marginLeft: '2ch' }} onClick={addVehicleAsync} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
          Add
        </Button>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={10}>
          <Typography variant="h4">Client Vehicle Points</Typography>

          <Box m={2}>
            {isVehicleDataLoading ? <div>Loading...</div> :
              vehicle?.data?.points?.map((point, pIndex) => (
                <Accordion expanded={expand === point} onChange={(event, IsExpand) => handleExpand(IsExpand, point)}>
                  <AccordionSummary className="mine-acodi" expandIcon={<ExpandMoreIcon color="secondary" />}>
                    <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
                      {point.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      point?.fields?.map((field, fIndex) => (
                        <div>
                          {
                            field.type === 'Email' ? <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField value={points?.[pIndex]?.fields[fIndex].value || field.value} onChange={e => handleFieldValue(e, field.name, pIndex, fIndex)} disabled={employee?.data?.point !== point.title} label={field.name} type="email" />
                              </Grid>
                            </Grid> : <></>
                          }
                          {
                            field.type === 'Dropdown' ?
                              <Grid container spacing={2}>
                                <Grid style={{ width: '400px' }}>
                                  <FormControl style={{ width: '100%', marginTop: '20px' }}>
                                    <InputLabel>{field.name}</InputLabel>
                                    <Select value={points?.[pIndex]?.fields[fIndex].value || field.value} onChange={e => handleFieldValue(e, field.name, pIndex, fIndex)} disabled={employee?.data?.point !== point.title} id="input-with-sx" label="Add Type" variant="outlined">
                                      {field?.options?.map((option, index) => <MenuItem value={option}>{option}</MenuItem>)}
                                      {/* Additional options */}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid> : <></>
                          }
                          {
                            field.type === 'cft' ?
                              <div style={{ width: '700px', border: '2px solid black', padding: '5px' }}>
                                <Typography variant="h4">CFT Calculator</Typography>
                                <h5>Length</h5>

                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField disabled={employee?.data?.point !== point.title} label="Length (feet)" type="number" value={feet} onChange={(e) => setFeet(e.target.value)} />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField disabled={employee?.data?.point !== point.title} label="Length (inches)" type="number" value={inches} onChange={(e) => setInches(e.target.value)} />
                                  </Grid>
                                </Grid>
                                <h5>Width</h5>

                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={employee?.data?.point !== point.title}
                                      label="Width (feet)"
                                      type="number"
                                      value={widthFeet}
                                      onChange={(e) => setWidthFeet(e.target.value)}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={employee?.data?.point !== point.title}
                                      label="Width (inches)"
                                      type="number"
                                      value={widthInches}
                                      onChange={(e) => setWidthInches(e.target.value)}
                                    />
                                  </Grid>
                                </Grid>
                                <h5>Height</h5>

                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={employee?.data?.point !== point.title}
                                      label="Height (feet)"
                                      type="number"
                                      value={heightFeet}
                                      onChange={(e) => setHeightFeet(e.target.value)}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={employee?.data?.point !== point.title}
                                      label="Height (inches)"
                                      type="number"
                                      value={heightInches}
                                      onChange={(e) => setHeightInches(e.target.value)}
                                    />
                                  </Grid>
                                </Grid>
                                <br />

                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <FormControl fullWidth>
                                      <InputLabel id="sand-type-label" />
                                      <Autocomplete
                                        disabled={employee?.data?.point !== point.title}
                                        options={sandTypes}
                                        getOptionLabel={(option) => option.label}
                                        value={sandType}
                                        onChange={handleSandTypeChange}
                                        renderInput={(params) => <TextField {...params} label="Sand Type" variant="outlined" fullWidth />}
                                      />
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={employee?.data?.point !== point.title}
                                      label="Rate per Unit"
                                      type="number"
                                      value={pricePerUnit}
                                      onChange={(e) => setPricePerUnit(e.target.value)}
                                    />
                                  </Grid>
                                </Grid>
                                <div>
                                  <br />

                                  <Button disabled={employee?.data?.point !== point.title} variant="contained" onClick={calculateTotalVolume}>
                                    Calculate Total Volume
                                  </Button>
                                </div>
                                <br />
                                {totalVolume !== null && (
                                  <div>
                                    <Typography variant="h6">Total Volume:</Typography>
                                    <Typography>{totalVolume} cubic units</Typography>
                                  </div>
                                )}

                                {totalCost !== null && (
                                  <div>
                                    <Typography variant="h6">Total Cost:</Typography>
                                    <Typography>${totalCost}</Typography>
                                  </div>
                                )}



                              </div> : <></>
                          }
                          {
                            field.type === 'Number' ? <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField value={points?.[pIndex]?.fields[fIndex].value || field.value} onChange={e => handleFieldValue(e, field.name, pIndex, fIndex)} disabled={employee?.data?.point !== point.title} label={field.name} type="number" />
                              </Grid>
                            </Grid> : <></>
                          }
                          {
                            field.type === 'InputBox' ? <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField value={points?.[pIndex]?.fields[fIndex].value} onChange={e => handleFieldValue(e, field.name, pIndex, fIndex)} disabled={employee?.data?.point !== point.title} label={field.name} type="text" />
                              </Grid>
                            </Grid> : <></>
                          }
                        </div>
                      ))
                    }{<Button onClick={handleSaveVehicle} variant="contained" style={{ marginTop: '3ch' }} endIcon={<SaveIcon />}>
                      Save
                    </Button>}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={6} lg={4} style={{ display: 'flex', flexDirection: 'column', gap: '1vmax' }}>
          <Box className="QR">
            <img src="/assets/new img/QR.png" alt="" />
          </Box>
          <Box>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Box>
        </Grid> */}
      </Grid>
    </>
  );

};

export default MuiAccordion;
